<template>
  <div class="card wrapper">
    <div class="icon clickable-bigicon" @click="$emit('clickIcon')">
      <i class="fa-brands fa-twitter" />
    </div>
    <div v-if="props.isLoading">
      <hollow-dots-spinner
        :animation-duration="1000"
        color="var(--clr-service-twitter)"
      />
    </div>
    <div v-else class="metrics">
      <div class="one-metric">
        <span class="value">{{ SINumberFormatter(props.tweetsCount) }}</span>
        Tweets
      </div>
      <div class="one-metric">
        <span class="value">{{ SINumberFormatter(props.reweetsCount) }}</span>
        Retweets
      </div>
      <div class="one-metric">
        <span class="value">{{
          SINumberFormatter(props.impressionsCount)
        }}</span>
        Impressions
      </div>
    </div>
  </div>
</template>

<script setup>
import { HollowDotsSpinner } from "epic-spinners";

import { SINumberFormatter } from "@/utils/helper.utils";

const props = defineProps({
  isLoading: { type: Boolean, required: true },
  tweetsCount: { type: Number, default: 0 },
  reweetsCount: { type: Number, default: 0 },
  impressionsCount: { type: Number, default: 0 },
});

defineEmits(["clickIcon"]);
</script>

<style scoped lang="scss">
.wrapper {
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  --context-color: var(--clr-service-twitter);

  .clickable-bigicon {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 65px;
  height: 65px;
  border-radius: 50%;

  font-size: 2.5rem;
  background-color: var(--context-color);
  color: white;
}

.metrics {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.one-metric {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex-grow: 1;

  font-size: 1.3rem;

  &:not(:last-child) {
    border-right: 1px solid var(--context-color);
  }

  .value {
    color: var(--context-color);
    font-size: 1.8rem;
  }
}
</style>
