<template>
  <template v-if="tweetsStore.items.length">
    <!-- Cards -->
    <div v-if="tweetsStore.items" class="grid-2-cols">
      <TweetsCard
        v-for="tweet in tweetsStore.items"
        :key="tweet._id"
        :tweet="tweet"
      />
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="tweetsStore.item_count"
      :key="`${tweetsStore.item_count}${tweetsStore.selectedFilters.from}${tweetsStore.selectedFilters.size}`"
      :total-items="tweetsStore.item_count"
      :current-page="tweetsStore.selectedFilters.from"
      :items-limit="tweetsStore.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <template v-if="!tweetsStore.loading && tweetsStore.item_count === 0">
    <div>
      <NoData />
    </div>
  </template>
</template>

<script setup>
import TweetsCard from "@/components/twitter/molecules/TweetsCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { useTweetsStore } from "@/stores/tweets";

const tweetsStore = useTweetsStore();

const changeCurrentPage = async (from) => {
  tweetsStore.selectedFilters.from = from;
  await tweetsStore.fetchTweetsList();
};
</script>
