<template>
  <CollapseSection v-for="eventDay in eventsByDays" :key="eventDay.date">
    <template #title>{{
      capitalizeFirstLetter(dayjs(eventDay.date).format("dddd DD MMMM YYYY"))
    }}</template>
    <template #content>
      <div class="section-wrapper">
        <EventCard
          v-for="event in eventDay.events"
          :key="event._id"
          :event="event"
          :is-selected="isEventSelected(event._id)"
          @select-event="selectEvent"
          @unselect-event="unselectEvent"
        />
      </div>
    </template>
  </CollapseSection>
  <NoData v-if="!store.loading && store.itemCount === 0" />
</template>

<script setup>
import dayjs from "dayjs";
import NoData from "@/components/reusable/atoms/NoData.vue";
import CollapseSection from "../atoms/CollapseSection.vue";
import EventCard from "../organisms/EventCard.vue";
import { computed } from "vue";
import { useAgendaStore } from "@/stores/agenda";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const store = useAgendaStore();

function getDates(startDate, endDate) {
  const dates = [];
  let currentDate = dayjs(startDate).startOf("day");
  const endDay = dayjs(endDate).startOf("day");

  if (currentDate.diff(endDay)) {
    while (currentDate.diff(endDay)) {
      dates.push(currentDate.format());
      currentDate = dayjs(currentDate).add(1, "day");
    }
  } else {
    dates.push(currentDate.format());
  }

  return dates;
}

const eventsDaysList = computed(() => {
  let days = [];
  let eventDays = [];
  store.items.forEach((event) => {
    eventDays = getDates(event.start, event.end);
    days = [...days, ...eventDays];
  });

  // keep only unique results and sort them
  days = days.filter((value, index, d) => d.indexOf(value) === index).sort();

  return days;
});

const eventsByDays = computed(() => {
  let sortedEvents = [];
  let start;
  let end;
  eventsDaysList.value.forEach((day) => {
    const eventsArray = [];
    // check each event an add it to the list if it takes place on the date
    store.items.forEach((event) => {
      start = dayjs(event.start).startOf("day");
      end = dayjs(event.end).endOf("day");
      if (dayjs(day).isBetween(start, end, null, [])) {
        eventsArray.push(event);
      }
    });
    // for each day with events, create an object with the date and the list of events on that date
    sortedEvents = [
      ...sortedEvents,
      {
        date: day,
        events: eventsArray,
      },
    ];
  });

  return sortedEvents;
});

const isEventSelected = (id) => Object.keys(store.selectedEvents).includes(id);

const selectEvent = (evt) => {
  store.selectedEvents = { ...store.selectedEvents, [evt._id]: evt };
};

const unselectEvent = (evt) => {
  delete store.selectedEvents[evt._id];
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

@container (width < 875px) {
  .section-wrapper {
    display: flex;
    flex-direction: column;
  }
}
</style>
