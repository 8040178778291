<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>
      <FollawedRadioButtons
        all-match-route="/twitter-stakeholders"
        follawed-route="/twitter-stakeholders/follawed"
      />
    </div>

    <div class="params-row">
      <RadioButtons
        :options="displayOptions"
        :active-option="currentUiView"
        @select-option="currentUiView = $event"
      />
    </div>

    <div class="params-row">
      <TotalMatched :item-count="store.itemCount" />

      <div class="page-settings">
        <SortBy
          :items="sortersNames"
          :selected-sort-item="currentSortLabel"
          @change-sort="handleSortChange"
        />

        <SelectItemsLimit
          :items-limit="selectedFilters.size"
          @change-limit="changeLimit"
        >
          Auteurs
        </SelectItemsLimit>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import {
  useTwitterStakeholdersStore,
  UI_VIEWS,
} from "@/stores/twitter-stakeholders";

const store = useTwitterStakeholdersStore();

// All filters in wanted display order with their labels
const filtersNames = {
  group: "Groupe",
  major_stakeholder: "Grande Partie Prenante",
  stakeholder: "Partie Prenante",
  level: "Niveau",
  circo_name: "Localité",
};

const sortersNames = {
  number_of_tweets: "Nombre de tweets",
  number_of_impressions: "Nombre d'impressions",
};

const { currentUiView, selectedFilters } = storeToRefs(store);

const displayOptions = [
  {
    label: "Classement",
    value: UI_VIEWS.RANKING,
  },
  {
    label: "Cartographie Politique",
    value: UI_VIEWS.POLITICAL_MAP,
  },
  {
    label: "Cartographie Sociétale",
    value: UI_VIEWS.SOCIETAL_MAP,
  },
];
const currentSortField = ref(
  selectedFilters.value?.sort_field ?? sortersNames.number_of_tweets
);

const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentSortField.value = selectedSortField;
      selectedFilters.value.sort_field = currentSortField;
      fetchTwitterStakeholdersList();
    }
  }
};

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchTwitterStakeholdersList();
};

const showScrollTop = computed(() => selectedFilters.value.size > 20);

const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] || [];
  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, event.item],
  };
  fetchTwitterStakeholdersList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] || [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }
  fetchTwitterStakeholdersList();
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  return arr;
});

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchTwitterStakeholdersList();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchTwitterStakeholdersList();
};

const fetchTwitterStakeholdersList = async () => {
  selectedFilters.value.from = 0;
  await store.fetchTwitterStakeholdersList();
};
</script>

<style lang="scss" scoped>
.select-multiple-wrapper {
  margin-left: 10px;
}

.sort-and-limit {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>
