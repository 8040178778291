import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import router from "@/router";
import { httpRequest } from "@/utils/request.utils";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import {
  getInstitutionsNames,
  reverseInstitutionsNames,
} from "@/utils/institutions.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialAmendmentsFilters = {
  size: 20,
  from: 0,
  sort: { created_at: "desc" },
  get_filters: true,
};

// Defining store
export const useAmendmentsStore = defineStore("amendments", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);
  const loadingExcel = ref(false);

  // Stats
  const isStatsLoading = ref(false);
  const amendmentsCount = ref(null);
  const legidocCount = ref(null);
  const actionTypesCount = ref({});

  // Listing
  const items = ref([]);
  const item_count = ref(null);

  // Zoom
  const zoom = ref(null);
  const previousAmendmentId = ref(null);
  const nextAmendmentId = ref(null);

  // filters
  const filters = ref(null);
  const selectedFilters = ref({ ...initialAmendmentsFilters });
  const isFollawedActive = ref(false);
  const isAuthorFilterLoading = ref(false);
  const authors = ref([]);

  const createPayloadBase = () => {
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    if (payload.bypass_time_range) {
      // removes the time filters from sharedFiltersAsPayload
      delete payload.start;
      delete payload.end;
      delete payload.bypass_time_range;
    }

    // Convert back the "institution name" filter if it exists
    if (payload.institution) {
      payload.institution = payload.institution.map((institution) =>
        reverseInstitutionsNames(institution)
      );
    }

    //* If there is author filters selected map them to only their ids
    if (payload.author?.length > 0) {
      payload.author = payload.author.map((author) => author._id);
    }

    //* Adapt the sorter format to the backend
    delete payload.sort;
    payload.sort_field = Object.keys(selectedFilters.value.sort)[0];
    payload.sort_order = Object.values(selectedFilters.value.sort)[0];

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload._id = userStore.follawed[FOLLAWED_FEATURES.AMENDMENTS];
    }

    return payload;
  };

  const fetchAmendmentsStats = async () => {
    isStatsLoading.value = true;
    const payload = { ...createPayloadBase() };

    try {
      const response = await httpRequest("post", `/amendments-stats`, payload);
      if (response.data) {
        amendmentsCount.value = response.data.amendment_count;
        legidocCount.value = response.data.legi_doc_count;
        actionTypesCount.value = response.data.action_type_count;
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      isStatsLoading.value = false;
    }
  };

  const fetchAmendmentsList = async () => {
    loading.value = true;

    const payload = { ...createPayloadBase() };

    try {
      const response = await httpRequest("post", "/amendments", payload);

      if (response.data) {
        // items.value = response.data.items;
        items.value = response.data.items.map((q) => ({
          ...q,
          // instution formatting
          institution: getInstitutionsNames(q.institution),
        }));
        item_count.value = response.data.item_count;
        filters.value = response.data.filters;
        filters.value.institution =
          filters.value.institution.map(getInstitutionsNames);
      }
    } catch (err) {
      items.value = [];
      item_count.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const downloadExcel = async () => {
    loadingExcel.value = true;

    const payload = { ...createPayloadBase() };

    try {
      const response = await httpRequest(
        "post",
        "/amendments-export",
        payload,
        "blob"
      );

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response.data);
      link.download = "amendments.xlsx";
      link.click();
    } finally {
      loadingExcel.value = false;
    }
  };

  const fetchOneAmendment = async (amendmentId) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", `/amendments-zoom`, {
        _id: [amendmentId],
        highlight: true,
        ...sharedFiltersAsPayload.value,
      });

      if (!response.data.items?.length) {
        return null;
      }

      //* set the zoom and get the previous and next ones ID if all went well
      zoom.value = response.data.items[0];

      //institution formatting
      zoom.value.institution = getInstitutionsNames(zoom.value.institution);
      const current = items.value.indexOf(
        items.value.find((item) => item._id === zoom.value._id)
      );
      previousAmendmentId.value = items.value[current - 1]?._id;
      nextAmendmentId.value = items.value[current + 1]?._id;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    fetchAmendmentsStats();
    fetchAmendmentsList();
  };

  const getAuthorFilters = async (name) => {
    isAuthorFilterLoading.value = true;

    try {
      const response = await httpRequest("post", "/amendments/search_authors", {
        name: name,
      });
      authors.value = response.data.personalities;
    } catch (err) {
      handleNetworkError(err);
    }
    isAuthorFilterLoading.value = false;
  };

  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  const resetFilters = () => {
    selectedFilters.value = { ...initialAmendmentsFilters };
  };

  const resetStore = () => {
    items.value = [];
    selectedFilters.value = { ...initialAmendmentsFilters };
    filters.value = null;
    loading.value = false;

    item_count.value = null;
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        if (router.currentRoute.value.name === "Amendements") {
          fetchAmendmentsStats();
          fetchAmendmentsList();
        }

        if (router.currentRoute.value.name === "Zoom amendements") {
          fetchOneAmendment(router.currentRoute.value.params.id);
        }
      }
    }
  );

  return {
    loading,
    loadingExcel,
    isStatsLoading,
    amendmentsCount,
    legidocCount,
    actionTypesCount,
    items,
    item_count,
    zoom,
    previousAmendmentId,
    nextAmendmentId,
    filters,
    selectedFilters,
    isFollawedActive,
    hasActiveFilters,
    isAuthorFilterLoading,
    authors,
    fetchAmendmentsStats,
    fetchAmendmentsList,
    downloadExcel,
    fetchOneAmendment,
    toggleFollawed,
    getAuthorFilters,
    resetFilters,
    resetStore,
  };
});
