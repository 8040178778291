<template>
  <div class="card" :class="{ overview: isOverview }">
    <!---------------------------------------- COL 1 ---------------------------------------->
    <CardProfilePicture :picture-url="tweet.author.photo" />

    <!---------------------------------------- COL 2 ---------------------------------------->
    <div class="main">
      <div class="author">
        <span :class="tooltipActivable ? 'name name-hover' : 'name'">
          {{ tweet.author.name }}
          <PrmAndAuthorTooltip
            v-if="tooltipActivable"
            class="tooltip"
            :to-prm="
              tweet.author.is_prm ? `/prm-national/${tweet.author._id}` : ''
            "
            :author="tweet.author"
          />
        </span>
        <span v-if="tweet.author?.twitter_information" class="handle">
          <a :href="authorTwitterUrl" target="_blank"
            >@{{ tweet.author.twitter_information.handle_twitter }}</a
          >
        </span>
        <GroupTag
          v-if="tweet.author.group"
          :group-color="tweet.author.group.color"
        >
          {{ tweet.author.group.acronym }}
        </GroupTag>
      </div>
      <div class="content typo-body-regular">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="tweetText"></p>
        <SourceLink
          v-for="url in tweetLinks"
          :key="url"
          :href="url"
          target="_blank"
        >
          {{ url }}
        </SourceLink>
      </div>

      <div class="timestamps-wrapper">
        <p>
          Tweet posté le :<span class="timestamp">{{
            getDayTimeFormat(tweet.created_at)
          }}</span
          ><SourceLink :href="sourceTweetUrl"></SourceLink>
        </p>
        <p v-if="tweet.last_update">
          Métriques mises à jour le :<span class="timestamp">{{
            getDayTimeFormat(tweet.last_update)
          }}</span>
        </p>
      </div>
    </div>

    <!---------------------------------------- COL 3 ---------------------------------------->
    <div v-if="!tweet.is_retweet" class="metrics">
      <div class="retweets">
        <span class="number">{{
          SINumberFormatter(tweet.number_of_retweets)
        }}</span>
        <span>Retweets</span>
      </div>
      <CommentsAndLikes
        :comments="SINumberFormatter(tweet.comment)"
        :likes="SINumberFormatter(tweet.like)"
        :impressions="SINumberFormatter(tweet.impression)"
      />
    </div>
    <div v-else class="is-retweet">
      <i class="fa-solid fa-retweet fa-xl"></i>
    </div>
  </div>
</template>

<script setup>
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import CommentsAndLikes from "@/components/twitter/atoms/CommentsAndLikes.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { computed } from "vue";
import { getDayTimeFormat } from "@/utils/formatDate";
import PrmAndAuthorTooltip from "../atoms/PrmAndAuthorTooltip.vue";

const props = defineProps({
  tweet: {
    type: Object,
    required: true,
  },
  isOverview: {
    type: Boolean,
    default: false,
  },
  tooltipActivable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const authorTwitterUrl = computed(
  () =>
    `https://twitter.com/${props.tweet.author.twitter_information.handle_twitter}`
);
const sourceTweetUrl = computed(
  () =>
    `https://twitter.com/${props.tweet.author.twitter_information?.handle_twitter || "foobar"}/status/${props.tweet.id_twitter}`
);

const linksRegex = /(https:\/\/t.co\/[a-zA-Z0-9]{10})/g;

const tweetLinks = computed(() => props.tweet.text.match(linksRegex));
const tweetText = computed(() => props.tweet.text.replace(linksRegex, ""));
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  color: black;

  &.overview {
    background-color: var(--clr-n2);
    box-shadow: none;
  }
}

.main {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  flex-grow: 1;

  .author {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > *:not(:last-child) {
      margin-right: 10px;
    }

    span {
      position: relative;
    }

    .name {
      color: black;
      font-weight: bold;
      text-transform: capitalize;

      .tooltip {
        display: none;
      }
    }

    .name-hover:hover {
      cursor: pointer;

      .tooltip {
        display: flex;
      }
    }

    .handle:hover {
      text-decoration: underline;
    }
  }

  .content {
    margin: 10px 0;

    p {
      display: inline;
      line-height: 22px;
    }

    a {
      margin-left: 5px;
    }
  }

  .timestamps-wrapper {
    margin-top: auto;

    p {
      margin: 0;
      color: var(--clr-s7);

      &:first-child {
        margin-bottom: 5px;
      }
    }

    .timestamp {
      margin: 0 5px;
      color: var(--clr-n6);
    }
  }
}

.metrics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .retweets {
    display: flex;
    flex-direction: column;

    .number {
      text-align: center;
      font-size: 1.688em;
    }
  }
}

.is-retweet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 15px;
  text-align: center;
  color: var(--clr-service-twitter);
}
</style>
