<template>
  <div class="product-tour-overlay" />
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { configProductTour } from "@/config/product-tour";

const router = useRouter();
const emits = defineEmits(["productTourComplete"]);

onMounted(async () => {
  await router.push("/");
  const productTour = configProductTour(router);
  productTour.start();

  productTour.on("complete", () => {
    emits("productTourComplete");
  });
});
</script>

<style lang="scss">
.product-tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  fill: rgb(80, 80, 80, 0.3);
  opacity: 1;
}

.product-tour__subtitle {
  font-weight: var(--fw-semibold);
  text-align: center;
  padding: 0 6px;
}

.product-tour-content-text {
  font-size: var(--size-body);
  color: var(--clr-n8);
  line-height: 24px;
  &--center {
    text-align: center;
  }
}

.product-tour-content-list {
  padding-left: 32px;
  margin: 0 0 16px 0;
  li::marker {
    font-size: 0.75rem;
  }
}

.shepherd-theme-custom {
  background: var(--clr-n1);
  display: flex;
  min-width: 580px;
  padding: 1rem 2rem;

  &[data-popper-placement="left"] {
    margin-left: -16px;
  }

  &[data-popper-placement="right"] {
    margin-left: 16px;
  }

  &[data-popper-placement="bottom"] {
    margin-top: 16px;
  }

  .shepherd-header,
  .shepherd-arrow:before {
    background: var(--clr-n1) !important;
  }

  .shepherd-header {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }

  .shepherd-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: var(--clr-n1);
  }

  .shepherd-title {
    font-size: var(--size-h6);
    font-weight: var(--fw-semibold);
    color: var(--clr-n8);
    text-align: center;
  }

  .shepherd-text {
    font-family: var(--font-primary);
    font-size: var(--size-body);
    color: var(--clr-n8);
    line-height: 24px;
    padding: 0;
  }

  .shepherd-footer {
    display: flex;
    align-items: center;
    padding: 0;
    font-family: var(--font-primary);

    .shepherd-progress {
      font-size: var(--size-small);
      color: var(--clr-n7);
      text-transform: capitalize;
      line-height: 18px;
      flex: 1;
    }

    .shepherd-button:not(.shepherd-button-cancel) {
      border-radius: 20px;
      margin-left: 8px;
      margin-right: 0;
    }

    .shepherd-button-primary {
      color: #fff;
      background-color: var(--clr-p5);

      &:hover {
        background-color: var(--clr-p4);
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
      }

      &:active {
        background-color: var(--clr-p5);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) inset;
      }
    }

    .shepherd-button-secondary {
      background-color: var(--clr-p1);
      color: var(--clr-n8);

      &:hover {
        background-color: var(--clr-p2);
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);
      }

      &:active {
        background-color: var(--clr-p5);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) inset;
      }
    }
  }

  .shepherd-button-cancel {
    color: var(--clr-n8);
    border-radius: 50%;
    height: 25px;
    width: 25px;

    &:hover {
      color: var(--clr-n8);
      background-color: var(--clr-p1);
    }
  }

  .shepherd-button-cancel .fa-solid {
    line-height: 1rem;
  }
}

.shepherd-button-cancel {
  background: none;
  border: none;
  font-size: 1.2em;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
