import { TIME_INTERVALS } from "@/stores/user";
import dayjs from "dayjs";

export function getStartDateFromPreset(preset) {
  switch (preset) {
    case TIME_INTERVALS.LAST_24H:
      return dayjs().subtract(1, "day").format("YYYY-MM-DD");
    case TIME_INTERVALS.LAST_WEEK:
      return dayjs().subtract(1, "week").format("YYYY-MM-DD");
    case TIME_INTERVALS.LAST_MONTH:
      return dayjs().subtract(1, "month").format("YYYY-MM-DD");
    case TIME_INTERVALS.LAST_YEAR:
      return dayjs().subtract(1, "year").format("YYYY-MM-DD");
    case TIME_INTERVALS.LEGI_15:
      return dayjs("2017-06-27T00:00:00+02:00").format("YYYY-MM-DD");
    case TIME_INTERVALS.LEGI_16:
      return dayjs("2022-06-28T00:00:00+02:00").format("YYYY-MM-DD");
    case TIME_INTERVALS.LEGI_17:
      return dayjs("2024-07-18T00:00:00+02:00").format("YYYY-MM-DD");
  }

  return dayjs().format("YYYY-MM-DD");
}

export function getEndDateFromPreset(preset) {
  switch (preset) {
    case TIME_INTERVALS.LEGI_15:
      return dayjs("2022-06-21T00:00:00+02:00").format("YYYY-MM-DD");
    case TIME_INTERVALS.LEGI_16:
      return dayjs("2024-06-10T00:00:00+02:00").format("YYYY-MM-DD");
  }

  return undefined;
}
