<template>
  <div class="card ranking-wrapper height-400">
    <div class="rank-header">
      <div class="header-title">Parties prenantes</div>
      <div class="header-tools">
        <DownloadButton @click="downloadAsExcel" />
        <RadioButtons
          :options="stakeholderCategory"
          :active-option="activeCategory"
          @select-option="setActiveCategory"
        />
      </div>
    </div>
    <div v-if="props.isLoading">
      <div class="loading-wrapper">
        <hollow-dots-spinner
          :animation-duration="1000"
          :color="'var(--clr-p6)'"
        />
      </div>
    </div>

    <!-- Pie Chart component-->
    <div
      v-else-if="activeCategory === 'authors' || activeCategory === 'tweets'"
    >
      <PieChart
        v-if="
          pieChartData.labels.length > 0 && pieChartData.datasets.length > 0
        "
        :chart-data="pieChartData"
        :options="pieChartOptions"
      />
      <div v-else>
        <div class="no-data-wrapper">
          <NoData />
        </div>
      </div>
    </div>

    <div v-else>
      <ul
        v-if="Object.keys(props.items[activeCategory]).length > 0"
        class="item-list"
      >
        <li
          v-for="item in itemsDescendingOrder"
          :key="item[0]"
          class="item-entry"
        >
          <span>{{ item[0] }}</span>
          <span>{{ item[1] }} mention{{ item[1] > 0 ? "s" : null }}</span>
        </li>
      </ul>
      <div v-else>
        <div class="no-data-wrapper">
          <NoData />
        </div>
      </div>

      <!-- Chart -->
      <div class="ranking-footer">
        <PaginationElement
          v-if="
            !props.isLoading &&
            activeCategory === 'classement' &&
            Object.keys(props.items[activeCategory]).length > ITEMS_PER_PAGE
          "
          :total-items="Object.keys(items[activeCategory]).length"
          :items-limit="ITEMS_PER_PAGE"
          :current-page="currentPage"
          @change-current-page="changeCurrentPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { PieChart } from "vue-chart-3";
import xlsx from "json-as-xlsx";
import { HollowDotsSpinner } from "epic-spinners";

import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import DownloadButton from "@/components/reusable/atoms/DownloadButton.vue";
import { useTwitterStatsStore } from "@/stores/twitter-stats";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const store = useTwitterStatsStore();

const totalItemsCount = computed(() => {
  return Object.values(props.items[activeCategory.value]).reduce(
    (total, count) => total + count,
    0
  );
});

const ITEMS_PER_PAGE = 10;

const currentPage = ref(0);
const itemsDescendingOrder = computed(() =>
  Object.entries(props.items[activeCategory.value])
    .sort(([, val1], [, val2]) => val2 - val1)
    .slice(
      ITEMS_PER_PAGE * currentPage.value,
      ITEMS_PER_PAGE * currentPage.value + ITEMS_PER_PAGE
    )
);

function changeCurrentPage(newPage) {
  currentPage.value = newPage;
}
const props = defineProps({
  items: {
    type: Object,
    default: () => ({}),
  },
  isLoading: { type: Boolean, required: true },
});

const activeCategory = ref("authors");

function setActiveCategory(newValue) {
  activeCategory.value = newValue;
}

const MAJOR_STAKEHOLDER_COLOR_MAP = {
  politiques: "#E48B34",
  "médias & journalistes": "#4FAFD5",
  lobbies: "#9C7FDD",
  institutions: "#DE339C",
  vip: "#ECCB45",
  events: "#63D562",
};

const pieChartData = computed(() => {
  const data = props.items[activeCategory.value];

  if (!data) {
    return {
      labels: [],
      datasets: [],
    };
  }
  const labels = Object.keys(data).map(
    (element) =>
      `${capitalizeFirstLetter(element)} : ${(
        (data[element] / totalItemsCount.value) *
        100
      ).toFixed(2)}%`
  );

  return {
    labels,
    datasets: [
      {
        backgroundColor: Object.keys(data).map(
          (mS) => MAJOR_STAKEHOLDER_COLOR_MAP[mS] || "grey"
        ),
        data: Object.values(data),
      },
    ],
  };
});

const pieChartOptions = {
  responsive: true,
  onClick: (evt, elements) => {
    const clickedIdx = elements[0].index;
    const clickedValue = Object.keys(props.items[activeCategory.value])[
      clickedIdx
    ];
    store.addFilterItem({ filter: "major_stakeholder", item: clickedValue });
  },
  onHover: (evt, elements) => {
    evt.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
  plugins: {
    legend: {
      position: "right",
      labels: {
        color: "black",
        usePointStyle: true,
        font: {
          size: 15,
        },
      },
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

const stakeholderCategory = [
  {
    label: "Auteurs",
    value: "authors",
  },
  {
    label: "Tweets",
    value: "tweets",
  },
  {
    label: "Classement",
    value: "classement",
  },
];

function downloadAsExcel() {
  const dataAsJson = [
    {
      sheet: "Auteurs",
      columns: [
        { label: "Partie prenante", value: "name" },
        { label: "Pourcentage", value: "percent" },
      ],
      content: Object.entries(props.items.authors).map(([key, val]) => ({
        name: key,
        percent: val,
      })),
    },
    {
      sheet: "Tweets",
      columns: [
        { label: "Partie prenante", value: "name" },
        { label: "Pourcentage", value: "percent" },
      ],
      content: Object.entries(props.items.tweets).map(([key, val]) => ({
        name: key,
        percent: val,
      })),
    },
    {
      sheet: "Classement",
      columns: [
        { label: "Partie prenante", value: "name" },
        { label: "Nombre de mentions", value: "number_mentions" },
      ],
      content: Object.entries(props.items.classement).map(([key, val]) => ({
        name: key,
        number_mentions: val,
      })),
    },
  ];

  xlsx(dataAsJson, {
    fileName: "Export Partie Prenantes",
  });
}

defineEmits(["selectStakeholderCategory"]);
</script>

<style scoped lang="scss">
.ranking-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.item-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .item-entry {
    width: 100%;

    display: flex;
    justify-content: space-between;
    font-size: 0.875em;
    color: black;
    padding: 8px 0;

    border-bottom: 1px solid var(--clr-p2);
    &:last-child {
      border-bottom: initial;
    }
    span {
      text-transform: capitalize;
    }
  }
}

.ranking-footer {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.height-400 {
  height: 468px;
}
.rank-header {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;

  .header-title {
    font-size: 1.0625rem;
    font-weight: 600;
    color: black;
  }

  .header-tools {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.loading-wrapper,
.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
</style>
