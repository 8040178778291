<template>
  <div class="template">
    <h2 class="card">Conseil des ministres</h2>
    <LoaderFullPage v-if="store.loading"></LoaderFullPage>
    <ul v-else-if="store.reports.length > 0" class="reports-list">
      <li v-for="report in store.reports" :key="report._id">
        <ReportCard :report="report" />
      </li>
    </ul>
    <p v-else class="card">
      Pas de conseil des ministres dans la période sélectionnée.
    </p>
  </div>
</template>

<script setup>
// The Conseil des Ministres section is a special view, which is actually the Compte-Rendu view with special filters applied
// So we "hack" it by re-using the store and hardcoding the filters, and not providing a Params component

import ReportCard from "@/components/legi-reports/molecules/ReportCard.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useLegiReportStore } from "@/stores/legi-reports";

const store = useLegiReportStore();

store.fetchConseilMinistresLegiReportsList();
</script>

<style scoped lang="scss">
.reports-list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @container (width > 500px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (width > 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @container (width > 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
