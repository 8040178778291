<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="params-row request-and-check-all">
    <SwitchSlide
      :is-active="!store.bypassQueryActivities"
      @toggle="toggleUseRequest"
    >
      Utiliser la requête
    </SwitchSlide>
    <CheckBox
      :checked="isAllSelected"
      @change="
        () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
      "
    >
      Toutes les activités
    </CheckBox>
  </div>
  <div class="params-row">
    <div class="page-settings filters">
      <FilterCheckBox
        label="Lobbying"
        color="var(--clr-p5)"
        :item-count="store.activitiesCounts?.lobbiesCount"
        :is-checked="
          lobbiesActivitiesFilters.includes(ACTIVITIES_FILTERS.LOBBYING)
        "
        @check="addFilter(ACTIVITIES_FILTERS.LOBBYING)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.LOBBYING)"
      />
      <FilterCheckBox
        label="Tweets"
        color="var(--color-tweets)"
        :item-count="store.activitiesCounts?.tweetCount"
        :is-checked="
          lobbiesActivitiesFilters.includes(ACTIVITIES_FILTERS.TWEETS)
        "
        @check="addFilter(ACTIVITIES_FILTERS.TWEETS)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.TWEETS)"
      />
    </div>

    <div class="page-settings">
      <SelectItemsLimit
        :items-limit="selectedLobbiesActivitiesParams.size"
        @change-limit="changeLimit"
      >
        Activités
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { ACTIVITIES_FILTERS } from "@/stores/lobbies";
import { useLobbiesStore } from "@/stores/lobbies";

const route = useRoute();
const store = useLobbiesStore();

const props = defineProps({
  isZoomed: {
    type: Boolean,
    default: false,
  },
});

const { lobbiesActivitiesFilters, selectedLobbiesActivitiesParams } =
  storeToRefs(store);

const addFilter = (filter) => {
  lobbiesActivitiesFilters.value.push(filter);
  fetchActivities();
};

const removeFilter = (filter) => {
  lobbiesActivitiesFilters.value = lobbiesActivitiesFilters.value.filter(
    (f) => f !== filter
  );
  fetchActivities();
};

const selectAllFilters = () => {
  lobbiesActivitiesFilters.value = [
    ACTIVITIES_FILTERS.TWEETS,
    ACTIVITIES_FILTERS.LOBBYING,
  ];
  fetchActivities();
};

const unselectAllFilters = () => {
  lobbiesActivitiesFilters.value = [];
  fetchActivities();
};

const isAllSelected = computed(() => {
  return (
    lobbiesActivitiesFilters.value.length ===
    Object.keys(ACTIVITIES_FILTERS)?.length
  );
});

const toggleUseRequest = () => {
  store.bypassQueryActivities = !store.bypassQueryActivities;
  fetchActivities();
};

// Limit
const changeLimit = (size) => {
  selectedLobbiesActivitiesParams.value.size = size;
  selectedLobbiesActivitiesParams.value.from = 0;
  fetchActivities();
};

const showScrollTop = computed(
  () => selectedLobbiesActivitiesParams.value.size > 0
);

const fetchActivities = async () => {
  selectedLobbiesActivitiesParams.value.from = 0;
  props.isZoomed
    ? await store.fetchOneLobbyActivities(route.params._id)
    : await store.fetchFollawedLobbiesActivities();
};

fetchActivities();
</script>

<style lang="scss" scoped>
.activities-params {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.request-and-check-all {
  justify-content: flex-start;
  gap: 15px;
}
.filters {
  gap: 10px;
}
</style>
