<template>
  <div v-if="Object.entries(content).length > 0" class="card box">
    <ul class="list">
      <li v-for="[key, item] in Object.entries(content)" :key="key">
        <!-- Toggle button for collapse/expand functionality -->
        <div class="title-article" @click="toggleCollapse(item.title)">
          {{ item.title || "Titre manquant" }}
          <i
            v-if="hasSubItems(item)"
            class="fa-solid"
            :class="{
              'fa-chevron-up': !isCollapsed[item.title],
              'fa-chevron-down': isCollapsed[item.title],
            }"
          ></i>
        </div>
        <div v-show="!isCollapsed[item.title]">
          <ul class="article-wrapper">
            <RecursiveLegiTextContent
              v-if="hasSubItems(item)"
              :content="item"
              :occurrences="occurrences"
            />
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { reactive, toRefs } from "vue";
import { goToArticle } from "@/utils/browse-articles";
import RecursiveLegiTextContent from "@/components/legi-work/atoms/RecursiveLegiTextContent.vue";

const state = reactive({
  isCollapsed: {},
});

defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  occurrences: { type: Object, required: true },
});

const { isCollapsed } = toRefs(state);

function toggleCollapse(itemTitle) {
  if (state.isCollapsed[itemTitle] === undefined) {
    state.isCollapsed[itemTitle] = true; // Start collapsed
  } else {
    state.isCollapsed[itemTitle] = !state.isCollapsed[itemTitle];
  }
  if (!state.isCollapsed[itemTitle]) {
    goToArticle(itemTitle);
  }
}
// Helper function to check if the content item has sub-items
function hasSubItems(item) {
  if (item.title) {
    return true;
  }
  return Object.values(item).some(
    (val) => val.title || (Array.isArray(val) && val.some((v) => v.title))
  );
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/helpers/variables.scss";

.box {
  min-width: 300px;
  height: fit-content;
  padding: 16px;
  overflow-y: auto;
  color: var(--clr-s8);
}
.title-article {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.article-wrapper {
  margin-top: 2px;
}
.title-article {
  padding: 3px 10px;
  cursor: pointer;
  height: fit-content;
  &:hover {
    color: var(--clr-s7);
    background-color: var(--clr-s2);
  }
}

ul.list {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
