<template>
  <div class="template">
    <div class="englobe-params">
      <RouterLink class="back" to="/organes"
        ><i class="fa-solid fa-chevron-left"></i> Retour à la liste</RouterLink
      >
    </div>

    <LoaderFullPage v-if="store.loading" />
    <template v-else>
      <div class="commission-zoom">
        <div class="card">
          <div class="header">
            Dernière mise à jour :
            {{ getDateShortFormat(store.groupZoom.updated_at) }}
          </div>
          <div class="title-body">
            <div class="name">{{ store.groupZoom.name }}</div>
            <div class="type">{{ store.groupZoom.group_type }}</div>
            <div class="title-stats">
              <div>
                <i class="fa-solid fa-users"></i>
                {{ store.groupZoom.number_of_members }} membre<span
                  v-if="store.groupZoom.number_of_members > 1"
                  >s</span
                >
              </div>
              <div>
                <i class="fa-sharp fa-solid fa-location-dot"></i>
                {{ store.groupZoom.institution }}
              </div>
            </div>
          </div>
        </div>

        <RouterRadioButtons :options="routerOptions" />

        <RouterView />
      </div>
    </template>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import { getDateShortFormat } from "@/utils/formatDate";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { computed } from "vue";

const store = useInstituGroupsStore();
const route = useRoute();

const hasPetition = computed(
  () =>
    store.groupZoom?.institution.match(/assemblée nationale/i) &&
    store.groupZoom?.group_type.match(/permanente/i)
);

const routerOptions = computed(() => [
  {
    label: "Composition",
    value: "composition",
  },
  {
    label: "Dernières activités",
    value: "activites",
  },
  {
    label: "Tweets",
    value: "tweets",
  },
  ...(store.groupZoom?.ref
    ? [
        {
          label: "Missions",
          value: "_missions",
        },
      ]
    : []),
  {
    label: "Agenda",
    value: "agenda",
  },
  ...(hasPetition.value
    ? [
        {
          label: "Pétitions",
          value: "petitions",
        },
      ]
    : []),
]);

if (
  store.groupZoom?._id !== route.params._id ||
  store.prevZoomUrlQuery !== JSON.stringify(route.query)
) {
  store.fetchOneGroup(route.params._id);
}
</script>

<style scoped lang="scss">
.commission-zoom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 0.9rem;
}

.title-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  color: var(--clr-s8);

  margin-bottom: 10px;

  .name {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .type {
    color: var(--clr-s7);
    font-size: 0.9rem;
    text-transform: capitalize;
  }

  .title-stats {
    display: flex;
    font-size: 0.9rem;
    gap: 15px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.report-content {
  color: var(--clr-n8);
}
</style>
