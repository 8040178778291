<template>
  <div class="card zoom">
    <div class="header">
      <div class="row">
        <h3 class="title">{{ title }}</h3>
        <div class="tools">
          <RadioButtons
            :options="displayOptions"
            :active-option="activeDisplay"
            @select-option="changeDisplay($event)"
          />
          <button class="close-btn" @click="closesZoom">
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
      </div>
      <div class="row sorters">
        <!-- Tweets Sort-->

        <SortBy
          v-if="activeDisplay === 'tweets'"
          :items="tweetsSortersNames"
          :selected-sort-item="currentTweetsSortLabel"
          @change-sort="handleTweetsSortChange"
        />
        <!-- Articles Sort -->
        <SortBy
          v-else
          :items="articlesSortersNames"
          :selected-sort-item="currentArticlesSortLabel"
          @change-sort="handleArticlesSortChange"
        />
      </div>
    </div>
    <div class="body">
      <div v-if="store.isTimeCurveTopsLoading" class="loading-wrapper">
        <HollowDotsSpinner :animation-duration="1000" color="var(--clr-p6)" />
      </div>

      <template
        v-else-if="
          store.timeCurveTopTweets.length > 0 && activeDisplay === 'tweets'
        "
      >
        <TweetsCard
          v-for="tweet in store.timeCurveTopTweets"
          :key="tweet.id_twitter"
          :tweet="tweet"
          is-overview
        />
      </template>
      <template
        v-else-if="
          store.timeCurveTopArticles.length > 0 && activeDisplay === 'links'
        "
      >
        <ArticleCard
          v-for="article in store.timeCurveTopArticles"
          :key="article._id"
          :article="article"
        />
      </template>
      <div v-else class="no-data-wrapper"><NoData /></div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable vue/no-mutating-props */
import TweetsCard from "../molecules/TweetsCard.vue";
import ArticleCard from "../molecules/TwitterArticleCard.vue";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import dayjs from "dayjs";
import { ref, toRefs, computed } from "vue";
import { HollowDotsSpinner } from "epic-spinners";

const props = defineProps({ store: { type: Object, required: true } });
const { store } = toRefs(props);
const emit = defineEmits(["closesZoom"]);

const displayOptions = [
  {
    label: "Tweets",
    value: "tweets",
  },
  {
    label: "Articles",
    value: "links",
  },
];

const activeDisplay = ref("tweets");

store.value.timeCurveTopTweetsFilters.sort = { retweet: "desc" };
// eslint-disable-next-line vue/no-setup-props-destructure
store.value.fetchTopList();

const tweetsSortersNames = {
  retweet: "Retweets",
  impression: "Impressions",
};

// Tweets sorting
const currentTweetsSortField = ref(
  Object.keys(store.value.timeCurveTopTweetsFilters.sort)[0] ?? "Retweets"
);

const currentTweetsSortLabel = computed(
  () => tweetsSortersNames[currentTweetsSortField.value] || "Sélectionner"
);

const handleTweetsSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(tweetsSortersNames).find(
    (key) => tweetsSortersNames[key] === selectedItem
  );

  if (selectedSortField) {
    if (currentTweetsSortField.value !== selectedSortField) {
      currentTweetsSortField.value = selectedSortField;

      store.value.timeCurveTopTweetsFilters.sort = {
        [currentTweetsSortField.value]: "desc",
      };
      store.value.fetchTopList();
    }
  }
};

// Articles Sorting
const articlesSortersNames = {
  number_of_tweets: "Tweets",
  number_of_retweets: "Retweets",
};

const currentArticlesSortField = ref(
  Object.keys(store.value.timeCurveTopArticlesFilters.sort)[0] ?? "Tweets"
);

const currentArticlesSortLabel = computed(
  () => articlesSortersNames[currentArticlesSortField.value] || "Sélectionner"
);

const handleArticlesSortChange = (selectedItem) => {
  const selectedArticlesSortField = Object.keys(articlesSortersNames).find(
    (key) => articlesSortersNames[key] === selectedItem
  );

  if (selectedArticlesSortField) {
    if (currentArticlesSortField.value !== selectedArticlesSortField) {
      currentArticlesSortField.value = selectedArticlesSortField;

      store.value.timeCurveTopArticlesFilters.sort = {
        [currentArticlesSortField.value]: "desc",
      };

      store.value.fetchTopList();
    }
  }
};

const title = computed(() => {
  const date = store.value.timeCurveTopDate.toLowerCase();
  const count =
    activeDisplay.value === "tweets"
      ? props.store.timeCurveTopTweetsCount
      : props.store.timeCurveTopArticlesCount;
  if (dayjs(date, "MMMM YYYY", true).isValid()) {
    return `Top ${Math.min(count, 10)} de ${date} :`;
  } else if (dayjs(date, "DD/MM/YYYY", true).isValid()) {
    return `Top ${Math.min(count, 10)} du ${dayjs(date, "DD/MM/YYYY").format(
      "DD MMMM YYYY"
    )} :`;
  } else {
    return `Top ${Math.min(count, 10)} du ${dayjs(date, "lll").format(
      "DD MMMM YYYY à HH:mm"
    )} :`;
  }
});

const changeDisplay = (event) => {
  activeDisplay.value = event;
  props.store.fetchTopList();
};

const closesZoom = () => {
  emit("closesZoom");
};
</script>

<style lang="scss" scoped>
.card.zoom {
  padding: 16px 4px 4px 4px;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin-bottom: 5px;
  padding: 0 11px;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.sorters {
      justify-content: flex-end;
    }
  }

  .title {
    margin: 0;
    font-size: 1em;
  }

  .tools {
    display: flex;
    align-items: center;
    gap: 10px;

    .close-btn {
      font-size: 0.8em;
      width: 25px;
      height: 25px;
      padding: 4px;
      border-radius: 50%;

      &:hover {
        background-color: var(--clr-p5);
        color: white;
      }
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
  height: 400px;
  padding: 0;
  overflow-y: auto;

  .loading-wrapper,
  .no-data-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 100%;
  }
}

/* Scrollbar
========================================================================== */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: var(--clr-p4);
}
</style>
