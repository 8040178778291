<template>
  <div
    v-on-click-outside="() => (isMenuOpen = false)"
    class="filter-single"
    :class="mode"
    :title="selectedItem ? getLabel(selectedItem) : undefined"
  >
    <div class="select-box" :class="{ error }" @click="toggleMenuOpen">
      <label class="selected-label">
        {{ selectedItem ? getLabel(selectedItem) : name }}
      </label>

      <div class="select-arrow">
        <i
          class="fa-solid"
          :class="isMenuOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
        ></i>
      </div>
    </div>

    <div v-if="isMenuOpen" class="menu">
      <!-- SearchBar -->
      <SelectSearchBar
        v-if="items.length > 10"
        v-model.trim="searchField"
        :mode="mode"
      />
      <div ref="listOfItems" class="items-list">
        <div
          v-for="item in searchedItems"
          :key="getValue(item)"
          class="item"
          :class="{ active: modelValue == getValue(item) }"
          @click="selectOption(item)"
        >
          <span class="item-text">
            {{ getLabel(item) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, nextTick } from "vue";
import { vOnClickOutside } from "@vueuse/components";

import SelectSearchBar from "@/components/reusable/atoms/SelectSearchBar.vue";
import { normalizeForSearch } from "@/utils/helper.utils";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  itemLabel: {
    type: String,
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [Number, String],
    required: true,
  },
  mode: {
    validator(value) {
      // The value must match one of these strings
      return ["purple", "blue", "topbar", "form-input"].includes(value);
    },
    default: "purple",
  },
  error: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const isMenuOpen = ref(false);
const searchField = ref("");
const listOfItems = ref(null);

const searchedItems = computed(() => {
  let arr = [];
  if (props.itemLabel) {
    arr = props.items.filter((item) =>
      normalizeForSearch(item[props.itemLabel]).includes(
        normalizeForSearch(searchField.value)
      )
    );
  } else {
    arr = props.items.filter((item) =>
      normalizeForSearch(item).includes(normalizeForSearch(searchField.value))
    );
  }
  return arr;
});

const selectedItem = computed(() =>
  props.items.find((element) =>
    props.itemValue
      ? element[props.itemValue] === props.modelValue
      : element === props.modelValue
  )
);
const getValue = (item) => (props.itemValue ? item[props.itemValue] : item);
const getLabel = (item) => (props.itemLabel ? item[props.itemLabel] : item);

const selectOption = (item) => {
  isMenuOpen.value = false;
  emit("update:modelValue", getValue(item));
};

async function toggleMenuOpen() {
  isMenuOpen.value = !isMenuOpen.value;

  if (isMenuOpen.value) {
    await nextTick();
    const activeItem =
      listOfItems.value.getElementsByClassName("item active")?.[0];
    activeItem?.scrollIntoView();
  }
}
</script>

<style lang="scss" scoped>
/* Purple & Topbar styles
========================================================================== */
.purple,
.topbar {
  color: var(--clr-n8);
  .select-box {
    box-shadow: 0 3px 5px #00000014;
    border-radius: 15px;
    background: white;
  }
  .menu {
    box-shadow: 0 1px 6px #0000005d;
    border-radius: 13px;
    background: white;
  }

  .active {
    background-color: var(--clr-p2);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-p2);
  }
}

.topbar {
  .select-box {
    color: white;
    background: var(--clr-p8);
  }
  .menu {
    color: var(--clr-n8);
  }
}

/* Blue styles
========================================================================== */
.blue {
  color: var(--clr-s8);
  .select-box,
  .menu {
    border-radius: 4px;
    background: white;
  }

  .select-box {
    box-shadow: 0 3px 5px #00000014;
  }

  .menu {
    box-shadow: 0 1px 6px #0000005d;
  }

  .active {
    background-color: var(--clr-s2);
  }

  .btn-reset,
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-s2);
  }
}

/* Form styles
========================================================================== */
.form-input {
  color: var(--clr-n8);

  .selected-label {
    color: var(--clr-n7);
  }

  .select-box,
  .menu {
    width: 100%;
    border-radius: 5px;
    background: var(--clr-n2);
  }

  .select-box {
    border: 1px solid var(--clr-p2);
    padding: 4px 8px;
    .select-arrow {
      border-left: 1px solid var(--clr-p2);
      padding-left: 8px;
    }
  }

  .menu {
    border: 1px solid var(--clr-p2);
    max-height: 300px;
    .items-list {
      max-height: 260px;
    }
  }

  .active,
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-p2);
  }
}

/* Whole component
========================================================================== */
.filter-single {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

/* Input
========================================================================== */
.select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  height: 30px;
  padding: 4px 15px;
  background: white;
  cursor: pointer;
  &.error {
    border-color: var(--clr-e200);
  }
  .selected-label {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .select-arrow {
    margin-left: 4px;
    pointer-events: none;
  }
}

/* Popover Menu
========================================================================== */
.menu {
  position: absolute;
  z-index: 3;
  top: 33px;
  left: 0;
  width: 220px;
  max-height: 435px;
  padding: 4px;
  background: white;
  .items-list {
    margin-top: 4px;
    max-height: 395px;
    overflow-y: scroll;
  }
}

/* Individual items
========================================================================== */
.item {
  display: block;
  padding: 2px 8px;
  margin-right: 2px;
  line-height: 1.5;
  border-radius: 4px;
  cursor: pointer;

  &:first-letter {
    text-transform: uppercase;
  }

  &:hover {
    background-color: var(--clr-p1);
  }

  &.active:hover {
    background-color: var(--clr-p2);
  }
}

/* Scrollbar
========================================================================== */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 7px;
}
</style>
