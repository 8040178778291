<template>
  <RouterLink :to="linkTo" :class="prospect._id ? '' : 'disabled'">
    <div class="prm card card-hover">
      <section
        v-if="prospect.group || prospect.stakeholder || prospect.party"
        class="header"
      >
        <GroupTag v-if="prospect.group" :group-color="prospect.group.color">
          {{ prospect.group.acronym }}
        </GroupTag>
        <GroupTag v-if="prospect.party" :group-color="prospect.party.color">
          {{ prospect.party.acronym }}
        </GroupTag>
        <GenericTag v-if="prospect.stakeholder">
          {{ prospect.stakeholder }}
        </GenericTag>
      </section>

      <section class="body">
        <section class="infos-and-follaw">
          <CardProfilePicture
            :picture-url="prospect.photo"
            class="picture"
            :size="70"
          />
          <div class="prospect-infos">
            <p class="name">{{ prospect.name }}</p>
            <p class="circonspection">{{ prospect.circo_name }}</p>
            <p v-if="prospect.llocality" class="locality">
              {{ prospect.locality }}
            </p>
          </div>
          <FollawButton
            v-if="prospect._id"
            :element-id="prospect._id"
            :feature="FOLLAWED_FEATURES.PRM"
            class="follaw"
          />
        </section>

        <section class="stats">
          <div class="tweets">
            <span class="number">{{
              SINumberFormatter(prospect.number_of_tweets)
            }}</span>
            <p>
              Tweet<template v-if="prospect.number_of_tweets > 1">s</template>
            </p>
          </div>

          <div class="activity">
            <span class="number">
              {{ SINumberFormatter(prospect.number_of_activities) }}
            </span>
            <p>
              Activité<template v-if="prospect.number_of_activities > 1"
                >s</template
              >
              parlementaire<template v-if="prospect.number_of_activities > 1"
                >s</template
              >
            </p>
          </div>
        </section>
      </section>
    </div>
  </RouterLink>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { FOLLAWED_FEATURES } from "@/stores/user";

const route = useRoute();
const props = defineProps({
  prospect: {
    type: Object,
    required: true,
  },
});

const linkTo = computed(() => {
  if (!props.prospect._id) {
    return "";
  }
  return route.fullPath.toLowerCase().includes("national")
    ? `/prm-national/${props.prospect._id}`
    : `/prm-regional/${props.prospect._id}`;
});
</script>

<style lang="scss" scoped>
.header {
  justify-content: initial;
  text-transform: capitalize;
  gap: 4px;

  .stakeholder {
    text-transform: capitalize;
  }
}

.body {
  .infos-and-follaw {
    display: flex;
    gap: 10px;

    .prospect-infos {
      display: flex;
      flex-direction: column;

      p {
        margin: 1px;
        line-height: 22px;
        color: black;
      }

      .name {
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    .follaw {
      margin-left: auto;
    }
  }

  .stats {
    display: flex;
    justify-content: space-around;
    margin-top: 11px;

    .tweets,
    .activity {
      text-align: center;
      color: var(--clr-s7);
      font-size: 0.938em;

      p {
        margin: 0;
      }
    }

    .number {
      color: var(--clr-n8);
      font-size: 1.25em;
    }
  }
}

.disabled {
  pointer-events: none;
}
</style>
