import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { httpRequest } from "@/utils/request.utils";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";

import router from "@/router";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialTwitterStakeholdersFilters = {
  from: 0, //* Page number
  size: 20, //* Number of items per page
  get_filters: true, //* Retrieve available filters
  sort_field: "number_of_tweets", //* Sort field
  sort_order: "desc", //* Sort order
  highlight: true, //* Retrieve highligted text based on querries/dashboard
  // domain: [], //* Filters for sources
  //* Other options include : “filter” string for search, “start” and “end” strings for dates (dd/mm/yyyy)
};

export const UI_VIEWS = {
  RANKING: "RANKING",
  POLITICAL_MAP: "POLITICAL_MAP",
  SOCIETAL_MAP: "SOCIETAL_MAP",
};

export const MAPPING_METRICS = {
  TWEETS: "number_of_tweets",
  IMPRESSIONS: "number_of_impressions",
  MENTIONS: "number_of_mentions",
};

export const useTwitterStakeholdersStore = defineStore(
  "twitter-stakeholders",
  () => {
    const userStore = useUserStore();
    const {
      loading: topbarLoading,
      sharedFiltersAsPayload: sharedFiltersAsPayload,
      selectedInterval: topbarInterval,
    } = storeToRefs(userStore);

    // Stakeholders list
    const items = ref([]);
    const itemCount = ref(null);

    const currentUiView = ref(UI_VIEWS.RANKING);

    const tweetsByStakeholder = ref([]);
    const tweetsByStakeholderCount = ref(null);

    const tweetsMentioningStakeholder = ref([]);
    const tweetsMentioningStakeholderCount = ref(null);

    const filters = ref(null);
    const selectedFilters = ref({ ...initialTwitterStakeholdersFilters });
    const isFollawedActive = ref(false);

    const loading = ref(false);
    const isRelatedTweetsLoading = ref(false);
    const isRelatedMentionsLoading = ref(false);

    // Mappings
    const politicalMap = ref({});
    const societalMap = ref({});
    const mapMetric = ref(MAPPING_METRICS.TWEETS);

    const createListingPayload = () => {
      const payload = {
        ...sharedFiltersAsPayload.value,
        ...selectedFilters.value,
      };

      return payload;
    };

    const fetchTwitterStakeholdersList = async (forcedFilters = {}) => {
      loading.value = true;

      const payload = {
        ...createListingPayload(),
        ...forcedFilters,
      };

      if (isFollawedActive.value) {
        //* Add the list of follawed ids
        payload.author = userStore.follawed[FOLLAWED_FEATURES.TWEET_AUTHORS];
      }

      try {
        const response = await httpRequest("post", "/authors", payload);
        if (response.data) {
          itemCount.value = response.data.item_count;
          items.value = response.data.items;
          filters.value = response.data.filters;
        }
      } catch (err) {
        itemCount.value = null;
        items.value = [];
        filters.value = null;
        handleNetworkError(err);
      } finally {
        loading.value = false;
      }
    };

    const fetchTweetsForOneStakeholder = async (id) => {
      isRelatedTweetsLoading.value = true;

      const payload = {
        author: [id],
        get_filters: true,
        highlight: true,
        ...sharedFiltersAsPayload.value,
      };

      try {
        const response = await httpRequest("post", `/tweets`, payload);
        if (response.data) {
          tweetsByStakeholderCount.value = response.data.item_count;
          tweetsByStakeholder.value = response.data.items;
        }
      } catch (err) {
        tweetsByStakeholderCount.value = null;
        tweetsByStakeholder.value = [];
        handleNetworkError(err);
      } finally {
        isRelatedTweetsLoading.value = false;
      }
    };

    const fetchMentionsForOneStakeholder = async (handle) => {
      isRelatedMentionsLoading.value = true;

      const payload = {
        mentions: [handle],
        get_filters: true,
        highlight: true,
        ...sharedFiltersAsPayload.value,
      };

      try {
        const response = await httpRequest("post", `/tweets`, payload);
        if (response.data) {
          tweetsMentioningStakeholderCount.value = response.data.item_count;
          tweetsMentioningStakeholder.value = response.data.items;
        }
      } catch (err) {
        tweetsMentioningStakeholderCount.value = null;
        tweetsMentioningStakeholder.value = [];
        handleNetworkError(err);
      } finally {
        isRelatedMentionsLoading.value = false;
      }
    };

    const fetchPoliticalMapping = async () => {
      politicalMap.value = {};

      // Fetch all authors matching the query with one unbounded request
      const payload = {
        ...createListingPayload(),
        get_filters: true,
        size: -1, // No size limit => get  e v e r y t h i n g
      };
      const res = await httpRequest("post", "/authors", payload);
      filters.value = res.data.filters;

      // Prune irrelevant data (no group or no mentions)
      const politicalAuthors = res.data.items
        .filter((author) => author.group)
        .filter(
          (author) =>
            author.number_of_tweets > 0 ||
            author.number_of_mentions > 0 ||
            author.number_of_impressions > 0
        );

      filters.value.group.forEach((group) => {
        politicalMap.value[group] = politicalAuthors.filter(
          (author) => author.group?.acronym === group
        );
      });
    };

    const fetchSocietalMapping = async () => {
      societalMap.value = {};

      // Fetch all authors matching the query with one unbounded request
      const payload = {
        ...createListingPayload(),
        get_filters: true,
        size: -1, // No size limit => get  e v e r y t h i n g
      };
      const res = await httpRequest("post", "/authors", payload);
      filters.value = res.data.filters;

      // Prune irrelevant data (no group or no mentions)
      const societalAuthors = res.data.items
        .filter((author) => author.major_stakeholder)
        .filter(
          (author) =>
            author.number_of_tweets > 0 ||
            author.number_of_mentions > 0 ||
            author.number_of_impressions > 0
        );

      filters.value.major_stakeholder.forEach((mS) => {
        societalMap.value[mS] = societalAuthors.filter(
          (author) => author.major_stakeholder === mS
        );
      });
    };

    const toggleFollawed = () => {
      isFollawedActive.value = !isFollawedActive.value;
      selectedFilters.value.from = 0;
      fetchTwitterStakeholdersList();
    };

    const resetFilters = () => {
      selectedFilters.value = { ...initialTwitterStakeholdersFilters };
    };

    const hasActiveFilters = computed(() => {
      return Object.values(selectedFilters.value).some((filter) => {
        return Array.isArray(filter) && filter.length > 0;
      });
    });

    const resetStore = () => {
      items.value = [];
      filters.value = null;
      selectedFilters.value = { ...initialTwitterStakeholdersFilters };
      loading.value = false;

      itemCount.value = null;
    };

    //* Trigger the fetch when the topbar timerange is changed
    watch(
      [topbarLoading, topbarInterval],
      ([newTopbarLoading, newTopbarInterval]) => {
        if (
          !newTopbarLoading &&
          newTopbarInterval &&
          router.currentRoute.value.name === "Parties Prenantes"
        ) {
          fetchTwitterStakeholdersList();
        }
      }
    );

    return {
      currentUiView,

      filters,
      isFollawedActive,
      hasActiveFilters,
      isRelatedTweetsLoading,
      isRelatedMentionsLoading,
      itemCount,
      items,
      loading,
      mapMetric,
      politicalMap,
      selectedFilters,
      societalMap,
      tweetsByStakeholder,
      tweetsByStakeholderCount,
      tweetsMentioningStakeholder,
      tweetsMentioningStakeholderCount,
      fetchMentionsForOneStakeholder,
      fetchPoliticalMapping,
      fetchSocietalMapping,
      fetchTweetsForOneStakeholder,
      fetchTwitterStakeholdersList,
      toggleFollawed,
      resetFilters,
      resetStore,
    };
  }
);
