<template>
  <div v-if="!disabled" class="search-field">
    <input
      type="search"
      placeholder="Recherche par mot-clé"
      :value="props.modelValue"
      @keyup.enter="confirmSearch"
      @input="(evt) => onValueChange(evt.target.value)"
      @focus="$emit('focus')"
      @blur="confirmSearch"
    />
    <div v-if="props.modelValue.length > 0" class="clear-btn">
      <button
        @mouseenter="() => (isResetHovered = true)"
        @mouseleave="() => (isResetHovered = false)"
        @click="resetCurrentValue"
      >
        <i
          :class="`fa-${
            isResetHovered ? 'solid' : 'light'
          } fa-arrow-rotate-left`"
        ></i>
      </button>
    </div>
    <button class="icon" @click="confirmSearch">
      <i class="fa-regular fa-magnifying-glass"></i>
    </button>
  </div>

  <div v-else class="search-field disabled">
    <input type="search" placeholder="Recherche par mot clé" disabled />
    <button class="icon">
      <i class="fa-regular fa-magnifying-glass"></i>
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  modelValue: { type: String, required: true },
  placeholder: {
    type: String,
    default: "Rechercher...",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["clickSearch", "update:modelValue", "focus"]);

const isResetHovered = ref(false);

const onValueChange = (newValue) => {
  emit("update:modelValue", newValue);
};

const resetCurrentValue = () => {
  emit("update:modelValue", "");
  confirmSearch();
  isResetHovered.value = false;
};

const confirmSearch = () => {
  emit("clickSearch");
  isResetHovered.value = false;
};
</script>

<style lang="scss" scoped>
.search-field {
  position: relative;

  input[type="search"] {
    box-shadow: inset 0px 3px 10px #00000029;
    border-radius: 15px;
    width: 100%;
    height: 30px;
    padding: 4px 0 3px 15px;
    color: var(--clr-n8);
    background: white;
    border: none;

    &::placeholder {
      color: var(--clr-n8);
    }

    &:focus {
      box-shadow: 0px 3px 6px 0px #00000054 inset;
      &::placeholder {
        color: transparent;
      }
    }
  }

  .clear-btn {
    position: absolute;
    top: 0;
    right: 50px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 25px;
      height: 25px;

      font-size: 0.8rem;
      color: black;

      background-color: white;
      border-radius: 50%;

      &:focus {
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) inset;
      }
    }
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 3px 10px #00000029;
    border-radius: 17px;
    width: 42px;
    height: 30px;
    font-size: 1.125em;
    background-color: var(--clr-p8);
    color: white;
  }

  &.disabled {
    opacity: 0.6;

    input[type="search"] {
      background-color: var(--clr-p5);
      cursor: not-allowed;

      &::placeholder {
        color: white;
      }
    }
    .icon {
      background-color: var(--clr-p5);
      cursor: not-allowed;
    }
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>
