import { ref } from "vue";
import { defineStore } from "pinia";

import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

// Defining store
export const useAlertsStore = defineStore("alerts", () => {
  const loading = ref(false);

  const alerts = ref([]);
  const filters = ref([]);

  // CREATE
  const createAlert = async (formData) => {
    loading.value = true;

    if (!formData) {
      return;
    }

    try {
      const response = await httpRequest("post", "/alerts", formData);

      if (response.status === 200) {
        readAlerts();
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  // READ
  const readAlerts = async () => {
    loading.value = true;

    try {
      const response = await httpRequest("get", "/alerts");

      if (response.data) {
        alerts.value = response.data.items;
        filters.value = response.data.filters;
      }
    } catch (err) {
      alerts.value = [];
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  // UPDATE
  const updateAlert = async (id, updatedAlert) => {
    loading.value = true;

    const alert = alerts.value.find((a) => a._id === id);

    const payload = {};

    for (const key in updatedAlert) {
      if (updatedAlert[key] !== alert[key]) {
        payload[key] = updatedAlert[key];
      }
    }

    if (Object.keys(payload).length === 0) {
      loading.value = false;
      return;
    }

    try {
      const response = await httpRequest("patch", `/alerts/${id}`, payload);
      if (response.status === 200) {
        readAlerts();
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const toggleAlert = async (id) => {
    if (!id) {
      return;
    }

    const alert = alerts.value.find((a) => a._id === id);

    try {
      const res = await httpRequest("patch", `/alerts/${id}`, {
        active: alert.active === true ? false : true,
      });

      if (res.status === 200) {
        alert.active = !alert.active;
      }
    } catch (err) {
      handleNetworkError(err);
    }
  };

  // DELETE
  const deleteAlert = async (id) => {
    if (!id) {
      return;
    }

    const alert = alerts.value.indexOf(alerts.value.find((a) => a._id === id));

    alerts.value.splice(alert, 1);

    try {
      await httpRequest("delete", `/alerts/${id}`);
    } catch (err) {
      handleNetworkError(err);
    }
  };

  return {
    loading,
    alerts,
    filters,
    createAlert,
    readAlerts,
    updateAlert,
    toggleAlert,
    deleteAlert,
  };
});
