<template>
  <div
    class="twitter-authors-card"
    :class="{ 'card-zoomed': props.isTweetsZoomed || props.isMentionsZoomed }"
  >
    <!-- Head -->
    <section class="head">
      <GenericTag
        color="var(--clr-n8)"
        background-color="var(--clr-tag-stake)"
        >{{ capitalizeFirstLetter(author.stakeholder) }}</GenericTag
      >
      <GenericTag v-if="author.theme">{{ author.theme }}</GenericTag>
      <GroupTag v-if="author.group" :group-color="author.group.color">
        {{ author.group.acronym }}
      </GroupTag>
    </section>
    <!-- Body -->
    <section class="body">
      <section class="body-head">
        <!--  Personnal informations -->
        <span class="personnal-infos-wrapper">
          <CardProfilePicture
            :picture-url="author.photo"
            class="picture"
            :size="70"
          />
          <div class="author-infos">
            <!-- Name and twitter-id -->
            <span class="name">{{ author.name }}</span>
            <span class="handle">
              <a
                :href="`https://twitter.com/${author.twitter_information.handle_twitter}`"
                target="_blank"
                >@{{ author.twitter_information.handle_twitter }}</a
              >
            </span>
            <PrmLink v-if="author.is_prm" :to="`/prm-national/${author._id}`" />
          </div>
        </span>
        <FollawButton
          :element-id="author._id"
          :feature="FOLLAWED_FEATURES.TWEET_AUTHORS"
          class="justify-self-end"
        />
      </section>

      <section class="metrics">
        <div class="followers">
          <span class="number">{{
            SINumberFormatter(author.twitter_information.followers_tot)
          }}</span>
          <span>Abonné(e)s</span>
        </div>

        <div class="impressions">
          <span class="number">
            {{ SINumberFormatter(author.number_of_impressions) }}
          </span>
          <span>Impressions</span>
        </div>

        <button
          class="mentions"
          :class="{
            'is-zoomed': props.isMentionsZoomed,
          }"
          @click="
            props.isMentionsZoomed
              ? emit('closeZoom')
              : emit(
                  'activateMentionsZoom',
                  author.twitter_information.handle_twitter
                )
          "
          @mouseover="showMentionsText = true"
          @mouseleave="showMentionsText = false"
        >
          <div v-if="isMentionsZoomed" class="share-btn-data">
            <span>Cacher les</span>
            <span class="display-block">mentions </span>
          </div>
          <span v-else-if="!showMentionsText">
            <span class="number">{{
              SINumberFormatter(author.number_of_mentions)
            }}</span>
            <span class="display-block">Mentions</span>
          </span>

          <span v-else class="margin-top-5">
            Voir les <span class="display-block">mentions</span>
          </span>
        </button>

        <button
          class="tweets"
          :class="{ 'is-zoomed': props.isTweetsZoomed }"
          @click="
            props.isTweetsZoomed
              ? emit('closeZoom')
              : emit('activateTweetsZoom', author._id)
          "
          @mouseover="showTweetsText = true"
          @mouseleave="showTweetsText = false"
        >
          <div v-if="isTweetsZoomed" class="share-btn-data">
            <span>Cacher les</span>
            <span class="display-block">tweets </span>
          </div>
          <span v-else-if="!showTweetsText">
            <span class="number">{{
              SINumberFormatter(author.number_of_tweets)
            }}</span>
            <span class="display-block">Tweets</span>
          </span>

          <span v-else class="margin-top-5">
            Voir les <span class="display-block">tweets</span>
          </span>
        </button>
      </section>

      <section class="content typo-body-regular">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="author.twitter_information.description"></p>
      </section>
    </section>
    <!-- Body end -->
  </div>
</template>

<script setup>
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import PrmLink from "@/components/twitter/atoms/PrmLink.vue";
import { ref } from "vue";
import {
  SINumberFormatter,
  capitalizeFirstLetter,
} from "@/utils/helper.utils.js";
import { FOLLAWED_FEATURES } from "@/stores/user";

const props = defineProps({
  author: {
    type: Object,
    required: true,
  },
  isTweetsZoomed: {
    type: Boolean,
    default: false,
  },
  isMentionsZoomed: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "activateMentionsZoom",
  "activateTweetsZoom",
  "closeZoom",
]);
const showTweetsText = ref(null);
const showMentionsText = ref(null);
</script>

<style lang="scss" scoped>
.card-zoomed {
  border: var(--clr-service-twitter) solid 1px;
}

.body-head {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.personnal-infos-wrapper {
  display: flex;
  justify-content: flex-start;
}
.display-block {
  display: block;
}
.share-btn-data {
  padding: 5px 10px;
  margin: 0 auto;
}
.margin-top-5 {
  margin-top: 5px;
}
.metrics {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  text-align: center;

  .followers,
  .impressions,
  .tweets,
  .mentions {
    color: var(--clr-s7);
    padding: 0.5em;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    .number {
      text-align: center;
      margin: 0 auto;
      font-size: 1.6875rem;
    }
    span {
      text-align: center;
      font-weight: 300;
      flex-basis: 100%;
    }
    &.is-zoomed {
      background-color: var(--clr-s7);
      color: white;
    }
  }
  .tweets:hover,
  .mentions:hover {
    border: 1px solid var(--clr-service-twitter);
  }
  .tweets.is-zoomed:hover,
  .mentions.is-zoomed:hover {
    border: none;
  }
  .card-zoomed {
    border: var(--clr-service-twitter) solid 1px;
  }

  .tweets,
  .mentions {
    background: white;
    box-shadow: var(--shd-card);
    border: 1px solid transparent;
    border-radius: 4px;
  }
}
.justify-self-end {
  justify-self: end;
}

.picture {
  min-width: 70px;
}

.body {
  margin-top: 10px;
  .author-infos {
    margin-left: 10px;
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 600;
      overflow-wrap: anywhere;
      text-transform: capitalize;
      line-height: 22px;
    }

    .handle {
      margin-bottom: 4px;
    }
  }
  .content {
    margin-top: 16px;
    line-height: 1.5;

    p {
      margin: 0;
      font-size: 0.9375em;
    }
  }
}

.head {
  border-bottom: 1.4px solid var(--clr-s2);
  padding-bottom: 10px;

  display: flex;
  gap: 4px;
  align-items: start;

  .theme {
    color: var(--clr-s8);
    background: var(--clr-s2) 0% no-repeat padding-box;
  }
}

.twitter-authors-card {
  box-shadow: var(--shd-card);
  border-radius: 4px;
  height: 100%;
  padding: 16px 16px;
  background-color: white;
}
</style>
