import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import { httpRequest } from "@/utils/request.utils";
import { FOLLAWED_FEATURES, useUserStore } from "@/stores/user";
import router from "@/router";
import {
  getInstitutionsNames,
  reverseInstitutionsNames,
} from "@/utils/institutions.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialInstituGroupsFilters = {
  size: 20,
  from: 0,
  get_filters: true,
  sort_field: "number_of_members",
  sort_order: "desc",
};

export const ACTIVITIES_FILTERS = {
  LEGIDOCS: "legi-doc",
  AMENDMENTS: "amendment",
  QUESTIONS: "question",
};

// Defining store
export const useInstituGroupsStore = defineStore("institu-groups", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);
  const isFollawedActive = ref(false);

  const groups = ref([]);
  const selectedFilters = ref({ ...initialInstituGroupsFilters });
  const filters = ref(null);
  const searchedInstituGroups = ref([]);
  const groupsCount = ref(0);
  const isInstitutionalGroupsFilterLoading = ref(false);

  const groupZoom = ref(null);
  const missionZoom = ref(null);
  const prevZoomUrlQuery = ref(null);

  // Activities
  const loadingActivities = ref(false);
  const bypassQuery = ref(false);
  const activities = ref([]);
  const activitiesCount = ref(0);
  const activitiesFilters = ref({
    from: 0,
    size: 20,
    indices: Object.values(ACTIVITIES_FILTERS),
  });

  // Tweets
  const loadingTweets = ref(false);
  const tweets = ref([]);
  const tweetsCount = ref(0);
  const tweetsFilters = ref({ from: 0, size: 20 });

  // Missions (children elements of commissions)
  const loadingMissions = ref(false);
  const missions = ref([]);
  const missionsCount = ref(0);
  const missionsFilters = ref({ from: 0, size: 100 });

  // Agenda
  const loadingEvents = ref(false);
  const events = ref([]);
  const eventsCount = ref(0);
  const eventsFilters = ref({
    from: 0,
    size: 20,
  });

  // Petitions
  const loadingPetitions = ref(false);
  const petitions = ref([]);
  const petitionsCount = ref(0);
  const petitionsFilters = ref({
    from: 0,
    size: 20,
    sort: [{ published_at: "desc" }],
  });

  async function fetchInstituGroupsList() {
    loading.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    // Reverse institution names in filters to backend format
    if (payload.institution) {
      payload.institution = payload.institution.map((institution) =>
        reverseInstitutionsNames(institution)
      );
    }

    // Replace institional_groups by the _id filter
    if (payload.institutional_groups) {
      payload._id = payload.institutional_groups.map((g) => g._id);
      delete payload.institutional_groups;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload._id = userStore.follawed[FOLLAWED_FEATURES.COMMISSION];
    }

    try {
      const response = await httpRequest(
        "post",
        "/institutional-groups",
        payload
      );

      if (response.data) {
        groups.value = response.data.items.map((r) => ({
          ...r,
          // Translate instution to human-readable
          institution: getInstitutionsNames(r.institution),
        }));
        groupsCount.value = response.data.item_count;
        filters.value = response.data.filters;
        // Convert institutions to more readable format
        filters.value.institution =
          filters.value.institution.map(getInstitutionsNames);
      }
    } catch (err) {
      groups.value = [];
      groupsCount.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  }

  async function fetchOneGroup(groupId) {
    loading.value = true;

    // This ref can be used to check if the query has changed in the zoom views
    prevZoomUrlQuery.value = JSON.stringify(router.currentRoute.value.query);

    try {
      const response = await httpRequest("post", `/institutional-groups/zoom`, {
        _id: [groupId],
        highlight: true,
        ...sharedFiltersAsPayload.value,
      });

      groupZoom.value = response.data;
      // institution to human-readable format
      groupZoom.value.institution = getInstitutionsNames(
        groupZoom.value.institution
      );
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  }

  async function fetchActivities(groupId) {
    loadingActivities.value = true;

    const payload = {
      _id: [groupId],
      ...sharedFiltersAsPayload.value,
      ...activitiesFilters.value,
    };

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    if (!payload.indices || payload.indices.length === 0) {
      payload.indices = Object.values(ACTIVITIES_FILTERS);
    }

    try {
      const response = await httpRequest(
        "post",
        `/institutional-groups/zoom/activities`,
        payload
      );

      activities.value = response.data.items;
      activitiesCount.value = response.data.item_count;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loadingActivities.value = false;
    }
  }

  async function fetchTweets(groupId) {
    loadingTweets.value = true;

    try {
      const response = await httpRequest(
        "post",
        `/institutional-groups/zoom/tweets`,
        {
          _id: [groupId],
          ...sharedFiltersAsPayload.value,
          ...tweetsFilters.value,
        }
      );

      tweets.value = response.data.items;
      tweetsCount.value = response.data.item_count;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loadingTweets.value = false;
    }
  }

  async function fetchMissions() {
    loadingMissions.value = true;

    try {
      const response = await httpRequest("post", `/institutional-groups`, {
        parent_ref: [groupZoom.value.ref],
        ...sharedFiltersAsPayload.value,
        ...missionsFilters.value,
      });

      missions.value = response.data.items;
      missionsCount.value = response.data.item_count;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loadingMissions.value = false;
    }
  }

  async function fetchAgenda(groupRef) {
    loadingEvents.value = true;

    try {
      const response = await httpRequest(
        "post",
        `/institutional-groups/zoom/agenda`,
        {
          organe_ref: [groupRef],
          ...sharedFiltersAsPayload.value,
          ...eventsFilters.value,
        }
      );

      events.value = response.data.items;
      eventsCount.value = response.data.item_count;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loadingEvents.value = false;
    }
  }

  async function fetchPetitions(groupId) {
    loadingPetitions.value = true;

    try {
      const response = await httpRequest(
        "post",
        `/institutional-groups/zoom/petitions`,
        {
          _id: [groupId],
          ...sharedFiltersAsPayload.value,
          ...petitionsFilters.value,
        }
      );

      petitions.value = response.data.items;
      petitionsCount.value = response.data.item_count;
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loadingPetitions.value = false;
    }
  }

  const getInstitutionalGroupsFilters = async (name) => {
    isInstitutionalGroupsFilterLoading.value = true;
    try {
      const response = await httpRequest(
        "post",
        "/institutional-groups/search_institutional-groups",
        { name: name }
      );
      searchedInstituGroups.value = response.data.institutional_groups;
    } catch (err) {
      handleNetworkError(err);
    }
    isInstitutionalGroupsFilterLoading.value = false;
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialInstituGroupsFilters };
  };

  const resetStore = () => {
    groups.value = [];
    selectedFilters.value = { ...initialInstituGroupsFilters };
    filters.value = null;
    loading.value = false;
    groupsCount.value = 0;
  };

  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    fetchInstituGroupsList();
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        if (router.currentRoute.value.path === "/organes") {
          fetchInstituGroupsList();
        }

        if (
          router.currentRoute.value?.path.includes("/organes/") &&
          router.currentRoute.value?.name.includes("Zoom")
        ) {
          fetchOneGroup(router.currentRoute.value?.params._id);
        }
      }
    }
  );

  return {
    activities,
    activitiesCount,
    bypassQuery,
    activitiesFilters,
    fetchActivities,
    fetchInstituGroupsList,
    fetchOneGroup,
    fetchTweets,
    fetchMissions,
    fetchPetitions,
    fetchAgenda,
    filters,
    getInstitutionalGroupsFilters,
    groups,
    groupsCount,
    groupZoom,
    isFollawedActive,
    isInstitutionalGroupsFilterLoading,
    hasActiveFilters,
    loading,
    loadingActivities,
    loadingEvents,
    loadingTweets,
    loadingMissions,
    loadingPetitions,
    missions,
    missionsCount,
    missionsFilters,
    missionZoom,
    prevZoomUrlQuery,
    petitions,
    petitionsCount,
    petitionsFilters,
    events,
    eventsCount,
    eventsFilters,
    resetFilters,
    resetStore,
    searchedInstituGroups,
    selectedFilters,
    toggleFollawed,
    tweets,
    tweetsCount,
    tweetsFilters,
  };
});
