<template>
  <div v-if="store.isNewsLoading">
    <LoaderFullPage />
  </div>

  <template v-else-if="store.lobbiesNews?.length">
    <div class="lobby-news-wrapper" :class="{ zoomed: !isFollawed }">
      <template
        v-for="item in store.lobbiesNews"
        :key="item._id || item.id_twitter"
      >
        <!-- Tweets Articles Questions Événements Comptes-rendus -->
        <TweetCard v-if="item.type === 'tweet'" :tweet="item" />
        <TwitterArticleCard v-if="item.type === 'article'" :article="item" />
        <QuestionCard
          v-if="item.type === 'question'"
          :question="item"
          :to="`/lobbies/${route.params._id || 'follawed'}/question/${item._id}`"
        />
        <EventCard
          v-if="item.type === 'event'"
          no-select
          :is-selected="false"
          :event="item"
        />
        <ReportCard v-if="item.type === 'compte_rendu'" :report="item" />
      </template>
    </div>
    <div class="pagination-wrapper">
      <PaginationElement
        v-if="totalCount"
        :key="`${totalCount}${store.selectedLobbiesNewsParams.from}${store.selectedLobbiesNewsParams.size}`"
        :total-items="totalCount"
        :current-page="store.selectedLobbiesNewsParams.from"
        :items-limit="store.selectedLobbiesNewsParams.size"
        @change-current-page="changeCurrentPage"
      />
    </div>
  </template>

  <div v-else class="card">
    <EmptySection :message="emptyMessage" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import TweetCard from "@/components/twitter/molecules/TweetsCard.vue";
import TwitterArticleCard from "@/components/twitter/molecules/TwitterArticleCard.vue";
import QuestionCard from "@/components/questions/atoms/QuestionCard.vue";
import EventCard from "@/components/agenda/organisms/EventCard.vue";
import ReportCard from "@/components/legi-reports/molecules/ReportCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import EmptySection from "@/components/lobbies/molecules/EmptySection.vue";
import { useLobbiesStore } from "@/stores/lobbies";

const store = useLobbiesStore();
const route = useRoute();

const isFollawed = ref(route.fullPath.includes("follawed") ? true : false);

const emptyMessage = computed(() => {
  return isFollawed.value
    ? "Vos organisations follawed n'ont pas d'actualités."
    : "Cette organisation n'a pas d'actualités.";
});

const totalCount = computed(() => {
  let count = 0;
  Object.values(store.newsCounts).forEach((c) => {
    count += c;
  });
  return count;
});

const changeCurrentPage = (from) => {
  store.selectedLobbiesNewsParams.from = from;
  isFollawed.value
    ? store.fetchFollawedLobbiesNews()
    : store.fetchOneLobbyNews(route.params._id);
};
</script>

<style lang="scss" scoped>
.lobby-news-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  &.zoomed {
    display: flex;
    flex-direction: column;
  }
}
.pagination-wrapper {
  grid-column: span 2;
}
</style>
