<template>
  <div class="wrapper">
    <div class="dialog-box">
      <div data-product-tour="step-1" />
      <div class="headline-medium-semibold welcome">Bienvenue sur Follaw !</div>
      <p>Votre veille actuelle est :</p>
      <p>
        <span class="highlight">{{ store.selectedDashboardName }}</span>
      </p>
      <p>Choisissez une section sur la gauche pour commencer.</p>
    </div>
    <img src="@/assets/img/owl-home.png" class="owl-img" />
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";

const store = useUserStore();
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.owl-img {
  width: 20.125em;
  margin-top: 15px;
}

.dialog-box {
  position: relative;
  margin-bottom: 20px;
  box-shadow: var(--shd-popup);
  padding: 0.5em 2em;

  &::after {
    content: "";
    border: 20px solid transparent;
    position: absolute;
    border-top-color: var(--clr-n2);
    border-bottom: 0;
    bottom: -20px;
    left: 23%;
  }
}

.welcome,
p {
  text-align: center;
}
</style>
