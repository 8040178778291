<template>
  <div class="card">
    <template v-if="store.lobbyZoom.clients.length">
      <section v-if="clients.length">
        <div class="bold">Clients actuels :</div>
        <ul class="clients">
          <li v-for="(client, index) in clients" :key="index" class="item">
            {{ client.name }}
          </li>
        </ul>
      </section>
      <section v-if="oldClients.length">
        <div class="bold">Anciens clients :</div>
        <ul class="clients">
          <li v-for="(client, index) in oldClients" :key="index" class="item">
            {{ client.name }}
          </li>
        </ul>
      </section>
    </template>
    <EmptySection v-else message="Cette organisation n'a pas de client." />
  </div>
</template>

<script setup>
import { computed } from "vue";
import EmptySection from "@/components/lobbies/molecules/EmptySection.vue";
import { useLobbiesStore } from "@/stores/lobbies";

const store = useLobbiesStore();

const clients = computed(() => {
  return store.lobbyZoom.clients.filter((client) => !client.old_client);
});
const oldClients = computed(() => {
  return store.lobbyZoom.clients.filter((client) => client.old_client);
});
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .bold {
    margin-bottom: 4px;
    font-weight: 600;
    line-height: 24px;
  }

  .clients {
    margin: 0;
    padding-left: 18px;
    column-count: 2;

    .item {
      margin: 4px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
