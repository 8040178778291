<template>
  <RouterLink :to="linkUrl">
    <div class="card card-hover">
      <section class="header">
        <div>
          Dépot le :<br />{{ getDateShortFormat(legiWork.depot_initial) }}
        </div>
        <div>
          Dernière mise à jour :<br />{{ getDateShortFormat(lastUpdateDate) }}
        </div>
        <div>{{ legiWork.legislature }}e législature</div>
      </section>
      <section class="main">
        <i class="fa-kit fa-dossierlegi"></i>
        <div class="title">{{ legiWork.title }}</div>
        <div class="follaw-btn">
          <FollawButton
            :id="`legiwork-follaw-btn-${props.idx}`"
            :element-id="props.legiWork._id"
            :feature="FOLLAWED_FEATURES.LEGIDOCS"
          />
        </div>
      </section>
      <section class="details">
        <div class="nature">
          {{ legiWork.nature }} - {{ legiWork.procedure }}
        </div>
        <GenericTag
          v-if="legiWork.procedure_acceleree"
          color="var(--clr-n8)"
          background-color="var(--clr-w75)"
        >
          Procédure accélérée
        </GenericTag>
        <div v-if="legiWork.authors" class="authors">
          <PersonSmallCard
            v-for="author of legiWork.authors.filter((_, idx) => idx < 2)"
            :key="author.name"
            :author="author"
          />
          <div v-if="legiWork.authors.length > 2" class="more-authors">
            et {{ legiWork.authors.length - 2 }} autre<span
              v-if="legiWork.authors.length - 2 > 1"
              >s</span
            >
          </div>
        </div>
        <div>
          <span class="emphasis"
            ><i class="fa-sharp fa-solid fa-location-dot"></i>Dépot initial : </span
          >{{ legiWork.depot_initial_institution }}
        </div>
        <div class="emphasis">
          <i class="fa-solid fa-calendar-days"></i>{{ legiWork.current_etape }}
        </div>
        <div v-if="legiWork.current_rapporteurs.length > 0" class="reporter">
          <span class="emphasis"
            ><i class="fa-solid fa-user"></i>Rapporteur actuel :</span
          >
          <span class="reporter-name">{{
            legiWork.current_rapporteurs[0].name
          }}</span>
          <GroupTag
            v-if="legiWork.current_rapporteurs?.[0]?.group"
            :group-color="legiWork.current_rapporteurs[0].group.color"
            >{{ legiWork.current_rapporteurs[0].group.acronym }}</GroupTag
          >
        </div>
      </section>
      <section class="footer">
        <ThemeTag
          v-for="thematic in legiWork.themes"
          :key="thematic"
          :theme="thematic"
        />
      </section>
    </div>
  </RouterLink>
</template>

<script setup>
import { computed } from "vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import ThemeTag from "@/components/reusable/atoms/ThemeTag.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";
import PersonSmallCard from "../atoms/PersonSmallCard.vue";
import { useUserStore } from "@/stores/user";
import { getDateShortFormat } from "@/utils/formatDate";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";

const userStore = useUserStore();

const props = defineProps({
  legiWork: {
    type: Object,
    required: true,
  },
  idx: {
    type: Number,
    required: false,
    default: 0,
  },
});

const isFollawed = computed(() => {
  if (!userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS]) {
    return false;
  }
  return userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS]?.includes(
    props.legiWork._id
  );
});

const linkUrl = computed(() =>
  isFollawed.value
    ? `/legislatives/${props.legiWork.ref}/timeline`
    : `/legislatives/${props.legiWork.ref}/expose-motifs`
);

const lastUpdateDate = computed(() => props.legiWork.latest_update);
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.header {
  color: var(--clr-s8);
  font-weight: 300;

  border-bottom: 2px solid var(--color-laws);
}

.main {
  display: flex;
  align-items: center;
  gap: 10px;

  i {
    flex-shrink: 0;
    border-radius: 50%;
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-laws);
    color: white;
    font-size: 1.1rem;
  }

  .title {
    flex-grow: 1;
    color: var(--clr-s8);
    font-weight: 600;
  }

  .follaw-btn {
    flex-grow: 0;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--clr-s8);
}

.nature {
  color: var(--clr-s7);
  font-weight: 600;
}

.authors {
  display: flex;
  gap: 15px;

  .more-authors {
    padding: 5px 0;
    align-self: flex-end;
  }
}

.emphasis {
  color: var(--clr-s7);

  i {
    padding-right: 10px;
  }
}

.reporter {
  display: flex;
  align-items: center;
  gap: 10px;

  .reporter-name {
    text-transform: capitalize;
  }
}

.footer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
