import dayjs from "dayjs";

export function getDateShortFormat(isoDate) {
  return dayjs(isoDate).format("DD/MM/YYYY");
}

export function getDateLongFormat(isoDate) {
  return dayjs(isoDate).format("DD MMMM YYYY");
}

export function getDayTimeFormat(isoDate) {
  return dayjs(isoDate).format("lll");
}
