<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="store.loading"
        :disabled="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SearchAsYouTypeFilter
          v-if="!store.isFollawedActive"
          filter="authors"
          :items="store.authors"
          item-label="name"
          item-value="_id"
          :active-filters="selectedFilters.authors"
          :is-loading="store.isAuthorFilterLoading"
          mode="purple"
          @search="fetchAuthors"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetAuthorFilter"
        >
          Auteurs
        </SearchAsYouTypeFilter>
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          :has-search="store.filters[key]?.length > 10"
          :disabled="!store.filters[key] || !store.filters[key].length"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>

      <FollawedRadioButtons
        :all-match-route="currentUrl.replace('/follawed', '')"
        :follawed-route="currentUrl.replace('/follawed', '') + '/follawed'"
      />
    </div>

    <h5 class="params-row legislature">
      Politician Relationship Management - 17ème Législature
    </h5>

    <div v-if="store.isFollawedActive" class="params-row">
      <RouterRadioButtons :options="textOptions" />
    </div>

    <PrmNewsParams v-if="route.fullPath.includes('news')" />
    <PrmActivitiesParams v-else-if="route.fullPath.includes('activities')" />

    <div v-else class="params-row">
      <TotalMatched :item-count="store.itemCount" />
      <div class="page-settings">
        <SortBy
          :items="sortersNames"
          :selected-sort-item="currentSortLabel"
          @change-sort="handleSortChange"
        />
        <SelectItemsLimit
          :items-limit="selectedFilters.size"
          @change-limit="changeLimit"
        >
          Personnalités
        </SelectItemsLimit>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";

import PrmNewsParams from "./PrmNewsParams.vue";
import PrmActivitiesParams from "./PrmActivitiesParams.vue";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import side_menu from "@/utils/side_menu";
import { usePrmStore } from "@/stores/prm";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import FollawedRadioButtons from "@/components/reusable/atoms/FollawedRadioButtons.vue";

const store = usePrmStore();
const { selectedFilters } = storeToRefs(store);
const route = useRoute();
const router = useRouter();

// All filters in wanted display order with their labels
const filtersNames = {
  circo_name: "Circonscription",
  group: "Groupe",
  // The level filter is only present in the regional listing
  ...(store.isRegional && { level: "Niveau" }),
  party: "Parti",
  stakeholder: "Partie prenante",
};
const showScrollTop = computed(() => selectedFilters.value.size > 20);
// All sorters in wanted display order with their labels
const sortersNames = {
  number_of_activities: "Nombre d'activités parlementaires",
  number_of_tweets: "Nombre de tweets",
  alphabetical: "Ordre alphabétique",
};

const currentSortField = ref(
  Object.keys(selectedFilters.value.sort)[0] ?? "number_of_activities"
);
const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const currentUrl = computed(() => router.currentRoute.value.path);

// Build follawed navbar options from router config
const routerOptions = route.name.toLowerCase().includes("national")
  ? side_menu
      .find((item) => item.name === "PRM")
      .secondary_tabs.find((item) => item.path === "/prm-national").children
  : side_menu
      .find((item) => item.name === "PRM")
      .secondary_tabs.find((item) => item.path === "/prm-regional").children;

const textOptions = route.name.toLowerCase().includes("national")
  ? routerOptions
      .filter((item) => item.name)
      .map((item) => ({
        label: item.name.replace("PRM National Follawed - ", ""),
        value: `/prm-national/${item.path}`,
        exact: true,
      }))
  : routerOptions
      .filter((item) => item.name)
      .map((item) => ({
        label: item.name.replace("PRM Régional Follawed - ", ""),
        value: `/prm-regional/${item.path}`,
        exact: true,
      }));

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentSortField.value = selectedSortField;
      selectedFilters.value.sort = { [currentSortField.value]: "desc" };
      fetchPrmList();
    }
  }
};

const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] || [];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, event.item],
  };
  fetchPrmList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] || [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }
  fetchPrmList();
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  selectedFilters.value.authors?.forEach((filter) => {
    arr = [
      ...arr,
      {
        filter: "authors",
        item: filter,
      },
    ];
  });
  return arr;
});

const fetchAuthors = (event) => {
  if (event.length > 0) {
    store.getAuthorFilters(event);
  } else {
    store.authors = [];
  }
};

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchPrmList();
};

const resetAuthorFilter = () => {
  store.authors = [];
  delete selectedFilters.value.authors;
  fetchPrmList();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchPrmList();
};

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchPrmList();
};

const fetchPrmList = async () => {
  selectedFilters.value.from = 0;
  await store.fetchPrmList();
  if (route.path.includes("/news")) {
    await store.fetchFollawedPrmNews();
  }
  if (route.path.includes("/activities")) {
    await store.fetchFollawedPrmActivities();
  }
};
</script>

<style lang="scss" scoped>
.params-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 10px;
}

.legislature {
  margin: 0;
}
</style>
