<template>
  <div class="englobe-params">
    <FiltersMenu
      :active-filters="activeFilters"
      :is-loading="!store.filters"
      @remove-filter="store.removeFilterItem"
      @reset-all-filters="store.resetFilters"
    >
      <SelectMultipleCounter
        v-for="(value, key) in filtersNames"
        :key="key"
        :filter="key"
        :items="store.filters[key]"
        :active-filters="selectedFilters[key]"
        mode="purple"
        has-search
        @add-filter-item="store.addFilterItem"
        @remove-filter-item="store.removeFilterItem"
        @reset-filter="store.resetFilter"
      >
        {{ value }}
      </SelectMultipleCounter>
    </FiltersMenu>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import { useTwitterStatsStore } from "@/stores/twitter-stats";

const store = useTwitterStatsStore();

const { selectedFilters } = storeToRefs(store);

// All filters in wanted display order with their labels
const filtersNames = {
  group: "Groupe",
  major_stakeholder: "Grande Partie Prenante",
  stakeholder: "Partie Prenante",
  level: "Niveau",
  circo_name: "Localité",
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  return arr;
});
</script>
