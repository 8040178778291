<template>
  <div class="time-curve-wrapper">
    <div class="card wrapper" :class="store.timeCurveTopDate ? 'zoomed' : ''">
      <div class="card-header">
        <div class="header-title">Courbe temporelle des tweets</div>
        <div class="header-tools">
          <DownloadButton @click="downloadAsExcel" />
          <RadioButtons
            :options="timeCurveIntervals"
            :active-option="store.timeCurveInterval"
            @select-option="changeInterval"
          />
        </div>
      </div>
      <div v-if="store.isTimeCurveLoading" class="loading-wrapper">
        <HollowDotsSpinner :animation-duration="1000" color="var(--clr-p6)" />
      </div>
      <div
        v-else-if="Object.keys(store.timeCurve).length > 0"
        class="chart-wrapper"
      >
        <LineChart :chart-data="lineChartData" :options="lineChartOptions" />
      </div>
      <div v-else class="no-data-wrapper">
        <NoData />
      </div>
    </div>
    <div v-if="store.timeCurveTopDate" class="zoom-wrapper">
      <OverviewTimeCurveZoom
        :key="store.timeCurveTopDate"
        :store="store"
        @closes-zoom="closeZoom"
      />
    </div>
  </div>
</template>

<script setup>
/*   eslint-disable vue/no-mutating-props */
import { computed } from "vue";
import { LineChart } from "vue-chart-3";
import { HollowDotsSpinner } from "epic-spinners";
import xlsx from "json-as-xlsx";
import dayjs from "dayjs";

import OverviewTimeCurveZoom from "../molecules/OverviewTimeCurveZoom.vue";
import DownloadButton from "@/components/reusable/atoms/DownloadButton.vue";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { getDayTimeFormat, getDateShortFormat } from "@/utils/formatDate";
import { TIME_INTERVALS } from "@/stores/twitter-stats";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const props = defineProps({
  store: { type: Object, required: true },
});

const timeCurveIntervals = [
  {
    label: "Mois",
    value: TIME_INTERVALS.MONTH,
  },
  {
    label: "Jour",
    value: TIME_INTERVALS.DAY,
  },
  {
    label: "Heure",
    value: TIME_INTERVALS.HOUR,
  },
];

const changeInterval = (e) => {
  props.store.timeCurveInterval = e;
  props.store.timeCurveTopDate = null;
  props.store.fetchTimeCurve();
};

function closeZoom() {
  props.store.timeCurveTopDate = null;
}

const getIsoDateAsCurrentFormat = (isoDate) => {
  switch (props.store.timeCurveInterval) {
    case TIME_INTERVALS.HOUR:
      return getDayTimeFormat(isoDate);
    case TIME_INTERVALS.MONTH: {
      const date = dayjs(isoDate).format("MMMM YYYY");
      return capitalizeFirstLetter(date);
    }
    default:
      return getDateShortFormat(isoDate);
  }
};

const formattedDates = computed(() => {
  return Object.keys(props.store.timeCurve).map((element) =>
    getIsoDateAsCurrentFormat(element)
  );
});

const lineChartOptions = {
  responsive: true,
  onClick: (evt, elements, chart) => {
    if (elements.length) {
      const i = elements[0].index;
      props.store.timeCurveTopDate = chart.data.labels[i];
    }
  },
  onHover: (evt, elements) => {
    evt.native.target.style.cursor = elements.length ? "pointer" : "default";
  },
  plugins: {
    legend: {
      onHover: (evt) => {
        evt.native.target.style.cursor = "pointer";
      },
      onLeave: (evt) => {
        evt.native.target.style.cursor = "default";
      },
    },
    scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
    tooltip: {
      caretPadding: 7,
      footerMarginTop: 10,
      displayColors: false,
      callbacks: {
        footer(context) {
          return context[0].raw > 9
            ? `Cliquer pour voir le top 10`
            : `Cliquer pour voir les tweets`;
        },
      },
    },
  },
};

const lineChartData = computed(() => ({
  labels: formattedDates.value,
  datasets: [
    {
      label: "Nombre de tweets",
      indexAxis: "x",
      backgroundColor: "#6C0073",
      data: Object.values(props.store.timeCurve),
    },
  ],
}));

function downloadAsExcel() {
  const intervalName = timeCurveIntervals.find(
    (elem) => elem.value === props.store.timeCurveInterval
  ).label;

  const dataAsJson = [
    {
      sheet: props.name,
      columns: [
        { label: intervalName, value: "time" },
        { label: "Nombre de tweets", value: "nbTweets" },
      ],
      content: Object.entries(props.store.timeCurve).map(([key, val]) => ({
        time: getIsoDateAsCurrentFormat(key),
        nbTweets: val,
      })),
    },
  ];

  xlsx(dataAsJson, {
    fileName: "Export tweets",
  });
}
</script>

<style lang="scss" scoped>
.time-curve-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.wrapper {
  display: flex;
  flex-direction: column;
  grid-column: span 2;

  .card-header {
    display: flex;
    justify-content: space-between;

    .header-title {
      font-size: 1.063em;
      font-weight: 600;
      color: black;
    }

    .header-tools {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &.zoomed {
    grid-column: span 1;
  }
}

.zoom-wrapper {
  grid-column: span 1;
}

.loading-wrapper,
.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>
