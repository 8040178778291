<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="params-line-wrapper">
    <SwitchSlide :is-active="!store.bypassQuery" @toggle="toggleUseRequest">
      Utiliser la requête
    </SwitchSlide>
    <CheckBox
      :checked="isAllSelected"
      @change="
        () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
      "
    >
      Toutes les activités
    </CheckBox>
  </div>
  <div class="params-line-wrapper space-between">
    <div class="filters-wrapper">
      <FilterCheckBox
        label="Dossiers Législatifs"
        color="var(--color-legidocs)"
        :is-checked="
          store.activitiesFilters.indices.includes(ACTIVITIES_FILTERS.LEGIDOCS)
        "
        @check="addFilter(ACTIVITIES_FILTERS.LEGIDOCS)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.LEGIDOCS)"
      />
      <FilterCheckBox
        label="Amendements"
        color="var(--color-amendments)"
        :is-checked="
          store.activitiesFilters.indices.includes(
            ACTIVITIES_FILTERS.AMENDMENTS
          )
        "
        @check="addFilter(ACTIVITIES_FILTERS.AMENDMENTS)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.AMENDMENTS)"
      />
      <FilterCheckBox
        label="Questions"
        color="var(--color-questions)"
        :is-checked="
          store.activitiesFilters.indices.includes(ACTIVITIES_FILTERS.QUESTIONS)
        "
        @check="addFilter(ACTIVITIES_FILTERS.QUESTIONS)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.QUESTIONS)"
      />
    </div>
    <SelectItemsLimit
      :items-limit="activitiesFilters.size"
      @change-limit="changeLimit"
    >
      Activités
    </SelectItemsLimit>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import {
  useInstituGroupsStore,
  ACTIVITIES_FILTERS,
} from "@/stores/institutional-groups";

const route = useRoute();
const store = useInstituGroupsStore();

const { activitiesFilters } = storeToRefs(store);

const addFilter = (filter) => {
  activitiesFilters.value.indices.push(filter);
  fetchActivities();
};

const showScrollTop = computed(() => activitiesFilters.value.size > 5);

const removeFilter = (filter) => {
  activitiesFilters.value.indices = activitiesFilters.value.indices.filter(
    (f) => f !== filter
  );
  fetchActivities();
};

const selectAllFilters = () => {
  activitiesFilters.value.indices = Object.values(ACTIVITIES_FILTERS);
  fetchActivities();
};

const unselectAllFilters = () => {
  activitiesFilters.value.indices = [];
  fetchActivities();
};

const isAllSelected = computed(
  () =>
    activitiesFilters.value.indices.length ===
    Object.keys(ACTIVITIES_FILTERS)?.length
);

const toggleUseRequest = () => {
  store.bypassQuery = !store.bypassQuery;
  fetchActivities();
};

// Limit
const changeLimit = (size) => {
  activitiesFilters.value.size = size;
  activitiesFilters.value.from = 0;
  fetchActivities();
};

const fetchActivities = () => {
  activitiesFilters.value.from = 0;
  store.fetchActivities(route.params._id);
};
</script>

<style lang="scss" scoped>
.params-line-wrapper,
.filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.params-line-wrapper {
    gap: 15px;
    &.space-between {
      justify-content: space-between;
    }
  }
  &.filters-wrapper {
    gap: 10px;
  }
}
</style>
