<template>
  <RouterLink :to="to">
    <div class="card card-hover">
      <section class="head">
        <span>
          Publiée le :
          {{ dayjs(props.question.created_at).format("DD/MM/YYYY") }}</span
        >
        <span>
          {{
            props.question.closed_at
              ? `Répondue le : ${dayjs(props.question.closed_at).format(
                  "DD/MM/YYYY"
                )}`
              : "Absence de réponse"
          }}
        </span>
        <span> {{ props.question.legislature }}e législature</span>
      </section>
      <section class="body">
        <!-- Questions -->
        <div class="card-title">
          <span v-if="props.question.title" class="question">
            <i class="fa-kit fa-questions"></i>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="question-text" v-html="props.question.title"> </span>
          </span>
          <span v-else class="no-component-data">Donnée(s) manquante(s)</span>

          <FollawButton
            :element-id="props.question._id"
            :feature="FOLLAWED_FEATURES.QUESTIONS"
            class="follawed-button"
          />
        </div>
        <div class="status-wrapper">
          <StatusTag :status="props.question.status" />
        </div>

        <!-- Questions Author and Description -->
        <div class="questions-author">
          <div class="author-infos-wrapper">
            <CardProfilePicture
              :picture-url="props.question.author.photo"
              class="picture"
              :size="50"
            />
            <span>
              <span class="name">{{ props.question.author.name }}</span>
              <GroupTag
                v-if="props.question.author.group"
                :group-color="props.question.author.group.color"
                class="group-tag"
              >
                {{ props.question.author.group.acronym }}
              </GroupTag>
            </span>
          </div>
          <span class="question-type-wrapper">
            <span class="vertical-separator"></span>
            <div class="questions-informations">
              <span>{{
                capitalizeFirstLetter(props.question.type_question)
              }}</span>
              <span>n° : {{ props.question.number }}</span>
              <span
                ><i class="fas fa-map-marker-alt"></i>
                <span> {{ props.question.institution }}</span></span
              >
            </div>
          </span>
        </div>
        <!-- Ministries-->
        <div class="ministries-infos">
          <span class="ministry">
            <span class="ministry-title">Ministère attributaire :</span>
            {{ props.question.ministry_responsible }}</span
          >
          <!-- Matched words -->
          <div
            v-if="
              props.question.matched_words &&
              props.question.matched_words.length > 0
            "
            class="ministry"
          >
            <span class="ministry-title"
              >Mot{{
                props.question.matched_words.length > 1 ? "s" : ""
              }}
              matché{{
                props.question.matched_words.length > 1 ? "s" : ""
              }}
              :</span
            >
            <!-- eslint-disable-next-line vue/no-v-html-->
            <span v-html="listOfWords"></span>
            <!-- eslint-enable-next-line vue/no-v-html-->
          </div>

          <div class="response-limit">
            Date limite pour répondre :
            <!-- Set the created date + 2 Months as expected response -->
            <span class="response-limit-content">
              {{
                dayjs(props.question.created_at)
                  .add(2, "month")
                  .format("DD/MM/YYYY")
              }}
            </span>
          </div>
        </div>
      </section>
      <section class="footer">
        <div class="themes-group">
          <ThemeTag
            v-for="(theme, index) in props.question.themes"
            :key="index"
            :theme="theme"
          />
        </div>

        <SourceLink :href="question.source">Source</SourceLink>
      </section>
    </div>
  </RouterLink>
</template>

<script setup>
import dayjs from "dayjs";
import { computed } from "vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import StatusTag from "@/components/reusable/atoms/StatusTag.vue";
import ThemeTag from "@/components/reusable/atoms/ThemeTag.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  to: {
    type: String,
    required: true,
  },
});

const listOfWords = computed(() =>
  props.question.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  height: 100%;

  color: var(--clr-n8);
}
.head {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  font-size: 0.9375em; // 15px
  border-bottom: 2px solid var(--clr-service-question);
  padding-bottom: 10px;
  color: var(--clr-s8);
}
.questions-author {
  display: flex;
  gap: 4em;
  font-size: 0.9375em; // 15px
  justify-content: space-between;
  .vertical-separator {
    height: 61px;
    width: 1px;
    background-color: var(--clr-s3);
  }
  .questions-informations {
    display: flex;
    flex-direction: column;
    font-weight: 300;

    color: var(--clr-s7);
    .fa-map-marker-alt {
      margin-right: 0.3em;
    }
    span {
      padding-top: 0.2em;
    }
    i {
      color: var(--clr-s7);
    }
  }
  .question-type-wrapper {
    flex-grow: 2;
    margin-left: 1.5em;
  }
}
.author-infos-wrapper,
.question-type-wrapper {
  display: flex;
  gap: 11px;
}
.status-wrapper {
  margin: 0.5em 0 0.8em 0;
}
.author-infos-wrapper {
  span {
    .group-tag {
      margin-top: 0.3em;
      width: max-content;
    }
    .name {
      font-weight: 300;
      color: var(--clr-s8);
      overflow-wrap: anywhere;
      text-transform: capitalize;
      line-height: 22px;
    }
  }
}

.fa-questions {
  width: 32px;
  height: 32px;
  padding-top: 6.5px;
  font-size: 19px;
  color: white;
  text-align: center;
  border-radius: 50px;
  background-color: var(--clr-service-question);
}
.matched-words {
  font-weight: 300;
}
.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .card-title {
    display: flex;
    margin-top: 0.9em;
    justify-content: space-between;
    .question {
      width: 98%;
      font-weight: 500;
      text-align: left;
      display: flex;
      i {
        flex: none;
      }
    }

    .question-text {
      margin-top: 0.25em;
      margin-left: 0.3em;
      color: var(--clr-s8);
    }
  }
}

.ministries-infos {
  padding: 0.5em 0;
  color: var(--clr-n8);

  .ministry {
    margin-top: 0.5em;
    font-weight: 300;
    display: block;
    .ministry-title {
      color: var(--clr-s7);
      margin-right: 0.2em;
      font-weight: 500;
    }
    color: var(--clr-s8);
  }

  .response-limit {
    margin-top: 0.5em;
    font-weight: 500;
    color: var(--clr-s7);
    .response-limit-content {
      color: var(--clr-s8);
      margin-right: 0.2em;
      font-weight: 300;
    }
  }
}
.no-component-data {
  font-style: italic;
  color: var(--clr-s8);
}
.footer {
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 15px;

  .themes-group {
    display: flex;
    gap: 4px;
  }
}
</style>
