<template>
  <div class="card" :class="{ zoomed: isZoomed }">
    <div class="title-row">
      <img v-if="article.logo" class="source-pp" :src="article.logo" />
      <i v-else class="source-pp pp-placeholder fa-solid fa-newspaper"></i>

      <div class="source-and-title">
        <SourceLink v-if="article.url" :href="article.url">
          {{ article.domain }}
        </SourceLink>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h5 class="title" v-html="article.title"></h5>
      </div>
    </div>

    <div class="content typo-body-regular">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="article.description"></p>
    </div>

    <div class="footer">
      <h3 class="last-shares-title">Derniers partages&nbsp;:</h3>
      <div class="share-row">
        <div class="sharers">
          <PersonBadge
            v-for="person in article.authors"
            :key="person._id"
            :person="person"
          />
        </div>
        <div v-if="!clickableMetrics" class="metrics-wrapper">
          <div class="metric-data">
            <span class="big-number">{{
              SINumberFormatter(article.number_of_tweets)
            }}</span>
            tweets
          </div>
          <div class="metric-data">
            <span class="big-number">{{
              SINumberFormatter(article.number_of_retweets)
            }}</span>
            retweets
          </div>
        </div>
        <button
          v-else
          class="metrics-wrapper btn"
          :class="{ zoomed: isZoomed }"
          @click="
            isZoomed ? emit('closeZoom') : emit('activateZoom', article._id)
          "
          @mouseenter="isZoomBtnHovered = true"
          @mouseleave="isZoomBtnHovered = false"
        >
          <div v-if="isZoomed" class="metric-data">
            <span>Cacher les</span> tweets & retweets
          </div>
          <div v-else-if="isZoomBtnHovered" class="metric-data">
            <span>Voir les</span>tweets & retweets
          </div>
          <template v-else>
            <div class="metric-data">
              <span class="big-number">{{
                SINumberFormatter(article.number_of_tweets)
              }}</span>
              tweets
            </div>
            <div class="metric-data">
              <span class="big-number">{{
                SINumberFormatter(article.number_of_retweets)
              }}</span>
              retweets
            </div>
          </template>
        </button>
      </div>

      <div
        v-if="article.published_at || article.appeared_at"
        class="dates-wrapper"
      >
        <div v-if="article.published_at">
          <span class="date-name">Date de publication de l'article : </span>
          <span class="date-value">{{
            getDateShortformat(article.published_at)
          }}</span>
        </div>
        <div v-if="article.created_at">
          <span class="date-name">1e apparition dans un tweet le : </span>
          <span class="date-value">{{
            getDateShortFormat(article.created_at)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import PersonBadge from "@/components/reusable/molecules/PersonBadge.vue";
import { SINumberFormatter } from "@/utils/helper.utils";
import { getDateShortFormat } from "@/utils/formatDate";

defineProps({
  article: {
    type: Object,
    required: true,
  },
  isZoomed: { type: Boolean, default: false },
  clickableMetrics: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["activateZoom", "closeZoom"]);

const isZoomBtnHovered = ref(false);
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  height: fit-content;

  &.zoomed {
    border: var(--clr-service-twitter) solid 1px;
  }
}

.source-pp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.pp-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 50px;

  color: white;
  background-color: var(--clr-service-article);

  font-size: 1.6em;
}

.title-row {
  display: flex;
  align-items: center;
  gap: 10px;

  .source-and-title {
    display: flex;
    flex-direction: column;
  }
}

.last-shares-title {
  margin: 10px 0;
  font-size: 0.938em;
  font-weight: 600;
}

.share-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .sharers {
    display: flex;
    gap: 17px;
    margin-left: 10px;
  }

  .metrics-wrapper {
    width: 170px;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px 16px;

    &.btn {
      box-shadow: var(--shd-card);
      border-radius: 4px;
      color: var(--clr-s7);
    }

    &.zoomed {
      background-color: var(--clr-s7);
      color: white;
    }

    .metric-data {
      display: flex;
      flex-direction: column;
      align-items: center;

      .big-number {
        font-size: 1.5rem;
      }
    }
  }
}

.dates-wrapper {
  margin-top: 6px;

  .date-name {
    color: var(--clr-s7);
  }

  .date-value {
    color: var(--clr-n6);
  }
}
</style>
