<template>
  <div class="card">
    <CollapseSection :initial-active="startOpen">
      <template #title>
        <div class="commission-title">
          <RouterLink
            v-if="commission.organe_ref._id"
            class="name"
            :to="`/organes/${commission.organe_ref._id}/composition`"
            @click.stop
          >
            {{ commission.organe_ref.name }}
          </RouterLink>
          <div v-else class="name">
            {{ commission.organe_ref.name }}
          </div>
          <div class="description">{{ commission.dossier_etape }}</div>
        </div>
      </template>
      <template #content>
        <div class="content-wrapper">
          <h4>
            Rapporteur<span v-if="commission.rapporteurs.length > 1">s</span>
          </h4>
          <ul class="rapporteurs-list">
            <li v-for="perso in commission.rapporteurs" :key="perso.rapporteur">
              <PersonSmallCard
                :author="store.getPersonalityData(perso.rapporteur) || {}"
              />
            </li>
          </ul>

          <template v-if="commission.rapport">
            <h4>Rapports de la commission</h4>
            <ul>
              <li v-if="commission.rapport.texte_associe">
                <RouterLink
                  :to="`/reports/${commission.rapport.texte_associe}`"
                >
                  Rapport du {{ getDateShortFormat(commission.rapport.date) }}
                </RouterLink>
              </li>
              <li v-else>Pas de rapport disponible</li>
            </ul>
          </template>

          <div v-if="commission.reunions?.length > 0">
            <h4>Réunions</h4>
            <p v-if="loadingReunions">Chargement...</p>
            <ul v-else class="reunion-list">
              <li v-for="reu in reunions" :key="reu.reunion_ref">
                <ReunionCard :reunion="reu" />
              </li>
            </ul>
          </div>
        </div>
      </template>
    </CollapseSection>
  </div>
</template>

<script setup>
import { ref } from "vue";
import CollapseSection from "@/components/agenda/atoms/CollapseSection.vue";
import PersonSmallCard from "../atoms/PersonSmallCard.vue";
import { useLegiWorkStore } from "@/stores/legi-work";
import { getDateShortFormat } from "@/utils/formatDate";
import { httpRequest } from "@/utils/request.utils";
import ReunionCard from "../atoms/ReunionCard.vue";
import { handleNetworkError } from "@/utils/handleNetworkError";

const props = defineProps({
  commission: { type: Object, required: true },
  startOpen: { type: Boolean, default: false },
});

const store = useLegiWorkStore();

const loadingReunions = ref(false);
const reunions = ref([]);

async function fetchReunions() {
  loadingReunions.value = true;

  try {
    if (props.commission.reunions?.length) {
      const response = await httpRequest("post", "/legi-reunions", {
        ref: props.commission.reunions.map((r) => r.reunion_ref),
      });
      reunions.value = response.data.items;
    } else {
      reunions.value = [];
    }
  } catch (err) {
    handleNetworkError(err);
  } finally {
    loadingReunions.value = false;
  }
}

fetchReunions();
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  color: var(--clr-s8);
}

.commission-title {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .name {
    &:hover {
      text-decoration: underline;
    }
  }

  .description {
    color: var(--clr-s7);
    text-transform: initial;
  }
}

a {
  width: fit-content;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

ul.rapporteurs-list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

ul.reunion-list {
  list-style: none;
  margin: 0;
  padding: 0;

  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @container (width > 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (width > 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
