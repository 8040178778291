<template>
  <div class="template">
    <template v-if="userSettingsStore.hasPermission('SOCIETAL')">
      <slot></slot>
    </template>
    <template v-else>
      <FreemiumUpgradePopup :closable="false" title="Veille Réseaux Sociaux">
        <p>
          Vous souhaitez aller encore plus loin, avec une veille globale
          regroupant une surveillance des institutions mais aussi des
          informations sociétales pouvant impacter vos enjeux ? Follaw.SV vous
          permet dès maintenant d'être alerté et d'analyser efficacement toutes
          les prises de parole des parties prenantes influentes : médias,
          journalistes, lobbies, politiques... Et ce, en fonction de vos enjeux
          !
        </p>
        <p>
          Nous avons sélectionné pour vous, un panel de plus de 20 000
          personnalités clés au niveau français et européen pour que vous
          puissiez anticiper les changements sociétaux... qui impacteront par la
          suite les prochaines évolutions politiques.
        </p>
      </FreemiumUpgradePopup>
    </template>
  </div>
</template>

<script setup>
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";
import { useUserSettingsStore } from "@/stores/user-settings";
const userSettingsStore = useUserSettingsStore();
</script>

<style scoped>
p {
  margin: 0;
}
</style>
