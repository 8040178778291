<template>
  <SocialsViewWrapper>
    <TweetsParams />
    <LoaderFullPage v-if="store.loading" />
    <TweetsDatas v-else />
  </SocialsViewWrapper>
</template>

<script setup>
import { useRoute } from "vue-router";
import SocialsViewWrapper from "@/components/twitter/templates/SocialsViewWrapper.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import TweetsParams from "@/components/twitter/organisms/TweetsParams.vue";
import TweetsDatas from "@/components/twitter/organisms/TweetsDatas.vue";
import { useTweetsStore } from "@/stores/tweets";
import { useUserSettingsStore } from "@/stores/user-settings";

const store = useTweetsStore();
const userSettingsStore = useUserSettingsStore();

if (userSettingsStore.hasPermission("SOCIETAL")) {
  store.selectedFilters.from = 0;
  store.fetchTweetsList(useRoute().query.author_id ?? null);
}
</script>
