import { ref, computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { httpRequest } from "@/utils/request.utils";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import dayjs from "dayjs";
import router from "@/router";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialLobbiesFilters = {
  size: 20,
  from: 0,
  sort: { expenses: "desc" },
  get_filters: true,
};

const initialLobbiesSubParams = {
  size: 20,
  from: 0,
};

export const NEWS_FILTERS = {
  TWEETS: "tweets",
  ARTICLES: "articles",
  QUESTIONS: "questions",
  EVENTS: "events",
  REPORTS: "compte-rendus",
};

export const ACTIVITIES_FILTERS = {
  LOBBYING: "lobbies",
  TWEETS: "tweets",
};

// Defining store
export const useLobbiesStore = defineStore("lobbies", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);

  // Listing
  const items = ref([]);
  const itemCount = ref(null);

  // Zoom
  const lobbyZoom = ref(null);
  const prevZoomUrlQuery = ref(null);
  const isLatestNewsLoading = ref(false);
  const lobbyZoomLatestNews = ref(null);
  const isChartLoading = ref(false);
  const activityChartDatas = ref(null);
  const tweetsChartDatas = ref(null);

  // News
  const isNewsLoading = ref(false);
  const bypassQueryNews = ref(false);
  const lobbiesNews = ref(null);
  const selectedLobbiesNewsParams = ref(initialLobbiesSubParams);
  const lobbiesNewsFilters = ref([
    NEWS_FILTERS.TWEETS,
    NEWS_FILTERS.ARTICLES,
    NEWS_FILTERS.QUESTIONS,
    NEWS_FILTERS.EVENTS,
    NEWS_FILTERS.REPORTS,
  ]);
  const newsCounts = ref(null);
  const searchQuery = ref(null);

  // Question
  const isQuestionLoading = ref(false);
  const question = ref(null);

  // Report
  const isReportLoading = ref(false);
  const report = ref(null);

  // Activities
  const isActivitiesLoading = ref(false);
  const bypassQueryActivities = ref(false);
  const lobbiesActivities = ref(null);
  const selectedLobbiesActivitiesParams = ref(initialLobbiesSubParams);
  const lobbiesActivitiesFilters = ref([
    ACTIVITIES_FILTERS.LOBBYING,
    ACTIVITIES_FILTERS.TWEETS,
  ]);
  const activitiesCounts = ref(null);

  // filters
  const filters = ref(null);
  const selectedFilters = ref({ ...initialLobbiesFilters });
  const isCompanyFilterLoading = ref(false);
  const companies = ref([]);
  const isFollawedActive = ref(false);
  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  const readLobbies = async () => {
    loading.value = true;

    //* Make the payload using the filters from the feature and those from the topbar
    let payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    //* Adapt the sorter format to the backend
    delete payload.sort;
    payload.sort_field = Object.keys(selectedFilters.value.sort)[0];
    payload.sort_order = Object.values(selectedFilters.value.sort)[0];

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload.national_id = userStore.follawed[FOLLAWED_FEATURES.LOBBIES];

      //* Get follawed filters
      delete payload.get_filters;
      payload.get_follawed_filters = true;
    } else {
      //* If there is author filters selected map them to only their ids
      if (payload.companies?.length > 0) {
        payload.companies = payload.companies.map(
          (company) => company.national_id
        );
      }

      //* Replace the companies field by the national_id field
      payload = {
        ...payload,
        national_id: payload.companies,
      };
      delete payload.companies;
    }

    try {
      const response = await httpRequest("post", "/lobbies/listing", payload);

      if (response.data) {
        items.value = response.data.items;
        itemCount.value = response.data.item_count;
        filters.value = response.data.filters;
      }
    } catch (err) {
      items.value = [];
      itemCount.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchFollawedLobbiesNews = async () => {
    isNewsLoading.value = true;

    const payload = {
      ...selectedFilters.value,
      ...selectedLobbiesNewsParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: lobbiesNewsFilters.value,
      national_id: userStore.follawed[FOLLAWED_FEATURES.LOBBIES],
      highlight: true,
      get_follawed_filters: true,
    };

    if (bypassQueryNews.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });
    try {
      const response = await httpRequest(
        "post",
        `/lobbies/followed-news`,
        payload
      );
      newsCounts.value = {
        tweetsCount: response.data.tweet_count,
        articlesCount: response.data.article_count,
        eventsCount: response.data.event_count,
        questionsCount: response.data.question_count,
        reportsCount: response.data.compte_rendu_count,
      };
      lobbiesNews.value = response.data.items;
      searchQuery.value = response.data.query_names;
    } catch (err) {
      handleNetworkError(err);
    }
    isNewsLoading.value = false;
  };

  const fetchFollawedLobbiesActivities = async () => {
    loading.value = true;

    const payload = {
      ...selectedFilters.value,
      ...selectedLobbiesActivitiesParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: lobbiesActivitiesFilters.value,
      national_id: userStore.follawed[FOLLAWED_FEATURES.LOBBIES],
      highlight: true,
      get_follawed_filters: true,
    };

    if (bypassQueryActivities.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/lobbies/followed-activities`,
        payload
      );
      lobbiesActivities.value = response.data.items;
      activitiesCounts.value = {
        lobbiesCount: response.data.lobbies_count,
        tweetCount: response.data.tweet_count,
      };
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const readOneLobby = async (lobbyId) => {
    loading.value = true;

    // This ref can be used to check if the query has changed in the zoom views
    prevZoomUrlQuery.value = JSON.stringify(router.currentRoute.value.query);

    try {
      const response = await httpRequest("post", `/lobbies/zoom/${lobbyId}`, {
        ...sharedFiltersAsPayload.value,
      });
      lobbyZoom.value = response.data;
    } catch (err) {
      handleNetworkError(err);
    }

    loading.value = false;
  };

  const fetchOneQuestion = async (questionId) => {
    isQuestionLoading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      _id: [questionId],
      highlight: true,
    };

    if (payload.filter || searchQuery.value) {
      payload.filter = payload.filter
        ? (payload.filter += searchQuery.value ?? "")
        : searchQuery.value;
    }

    try {
      const response = await httpRequest("post", `/question-zoom`, payload);
      question.value = response.data.items[0];
    } catch (err) {
      handleNetworkError(err);
    }

    isQuestionLoading.value = false;
  };

  const fetchOneReport = async (docRef) => {
    isReportLoading.value = true;

    const payload = {
      ...sharedFiltersAsPayload.value,
      ref: [docRef],
      highlight: true,
    };

    if (payload.filter || searchQuery.value) {
      payload.filter = payload.filter
        ? (payload.filter += searchQuery.value ?? "")
        : searchQuery.value;
    }

    try {
      const response = await httpRequest(
        "post",
        "/legi-compte-rendus-zoom",
        payload
      );
      report.value = response.data;
    } catch (err) {
      handleNetworkError(err);
    }

    isReportLoading.value = false;
  };

  const fetchOneLobbyNews = async (lobbyId) => {
    isNewsLoading.value = true;

    const payload = {
      ...selectedLobbiesNewsParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: lobbiesNewsFilters.value,
      highlight: true,
    };

    if (bypassQueryNews.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/lobbies/zoom/${lobbyId}/news`,
        payload
      );
      newsCounts.value = {
        tweetsCount: response.data.tweet_count,
        articlesCount: response.data.article_count,
        eventsCount: response.data.event_count,
        questionsCount: response.data.question_count,
        reportsCount: response.data.compte_rendu_count,
      };
      lobbiesNews.value = response.data.items;
      searchQuery.value = response.data.query_names;
    } catch (err) {
      handleNetworkError(err);
    }
    isNewsLoading.value = false;
  };

  const readOneLobbyLatestNews = async (lobbyId) => {
    isLatestNewsLoading.value = true;

    try {
      const response = await httpRequest(
        "get",
        `/lobbies/zoom/${lobbyId}/general-news`
      );
      lobbyZoomLatestNews.value = response.data;
    } catch (err) {
      handleNetworkError(err);
    }

    isLatestNewsLoading.value = false;
  };

  const readOneLobbyChart = async (lobbyId) => {
    isChartLoading.value = true;

    try {
      const response = await httpRequest(
        "get",
        `/lobbies/zoom/${lobbyId}/timeline`
      );
      activityChartDatas.value = response.data.lobbies_timeline;
      tweetsChartDatas.value = Object.entries(response.data.tweet_timeline);
      if (tweetsChartDatas.value.length > 0) {
        tweetsChartDatas.value = tweetsChartDatas.value
          .map((entry) => ({ date: dayjs(entry[0]).format(), value: entry[1] }))
          .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
        tweetsChartDatas.value = tweetsChartDatas.value.slice(
          Math.max(tweetsChartDatas.value.length - 3, 0)
        );
      }
    } catch (err) {
      handleNetworkError(err);
    }

    isChartLoading.value = false;
  };

  const fetchOneLobbyActivities = async (lobbyId) => {
    isActivitiesLoading.value = true;

    const payload = {
      ...selectedLobbiesActivitiesParams.value,
      ...sharedFiltersAsPayload.value,
      indexes: lobbiesActivitiesFilters.value,
      highlight: true,
    };

    if (bypassQueryActivities.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/lobbies/zoom/${lobbyId}/activities`,
        payload
      );
      activitiesCounts.value = {
        lobbiesCount: response.data.lobbies_count,
        tweetCount: response.data.tweet_count,
      };
      lobbiesActivities.value = response.data.items;
    } catch (err) {
      handleNetworkError(err);
    }
    isActivitiesLoading.value = false;
  };

  const getCompanyFilters = async (name) => {
    isCompanyFilterLoading.value = true;

    try {
      const response = await httpRequest("post", "/lobbies/search_lobbies", {
        name: name,
      });
      companies.value = response.data.lobbies;
    } catch (err) {
      handleNetworkError(err);
    }
    isCompanyFilterLoading.value = false;
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialLobbiesFilters };
  };

  //* Trigger the fetch when the topbar timerange is changed
  const route = computed(() => router.currentRoute.value);
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        if (
          route.value.path.includes("/lobbies/") &&
          !route.value.path.includes("follawed")
        ) {
          if (route.value.path.includes("/activities")) {
            fetchOneLobbyActivities(route.value.params._id);
          } else if (route.value.path.includes("/news")) {
            fetchOneLobbyNews(route.value.params._id);
          } else {
            readOneLobby(route.value.params._id);
            readOneLobbyChart(route.value.params._id);
            readOneLobbyLatestNews(route.value.params._id);
          }
        } else if (
          route.value.path.includes("/lobbies") ||
          route.value.path.includes("follawed")
        ) {
          readLobbies();
        }
      }
    }
  );

  return {
    loading,
    items,
    itemCount,
    lobbyZoom,
    prevZoomUrlQuery,
    isLatestNewsLoading,
    lobbyZoomLatestNews,
    isNewsLoading,
    bypassQueryNews,
    lobbiesNews,
    selectedLobbiesNewsParams,
    lobbiesNewsFilters,
    newsCounts,
    searchQuery,
    isQuestionLoading,
    question,
    isReportLoading,
    report,
    isChartLoading,
    activityChartDatas,
    tweetsChartDatas,
    isActivitiesLoading,
    bypassQueryActivities,
    lobbiesActivities,
    selectedLobbiesActivitiesParams,
    lobbiesActivitiesFilters,
    activitiesCounts,
    filters,
    selectedFilters,
    isCompanyFilterLoading,
    companies,
    isFollawedActive,
    hasActiveFilters,
    readLobbies,
    fetchFollawedLobbiesNews,
    fetchFollawedLobbiesActivities,
    readOneLobby,
    fetchOneLobbyNews,
    fetchOneQuestion,
    fetchOneReport,
    readOneLobbyLatestNews,
    readOneLobbyChart,
    fetchOneLobbyActivities,
    getCompanyFilters,
    resetFilters,
  };
});
