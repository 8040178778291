<template>
  <LoaderFullPage v-if="store.loadingMissions" />

  <template v-else-if="store.missionsCount > 0">
    <div v-for="type in groupTypes" :key="type" class="card">
      <h3 class="capitalize-letter">{{ type }}</h3>
      <ul class="missions-list">
        <li
          v-for="elem in store.missions.filter((m) => m.group_type === type)"
          :key="elem._id"
        >
          <MissionCard :mission="elem" />
        </li>
      </ul>
    </div>
  </template>

  <div v-else class="empty">
    <img
      src="@/assets/img/institutional-groups/empty-states/commissions-missions-nodatas.svg"
      alt="aria-hidden=true"
    />
    <p>Aucune mission n’a été créée au sein de cet organe.</p>
  </div>
</template>

<script setup>
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import MissionCard from "@/components/institu-groups/atoms/MissionCard.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import { computed } from "vue";
const store = useInstituGroupsStore();

const groupTypes = computed(() =>
  // Use Set to only keep unique values
  [...new Set(store.missions.map((m) => m.group_type))].sort()
);

store.fetchMissions();
</script>

<style scoped lang="scss">
.empty > img {
  max-width: 315px;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  font-size: 1.125em;
  flex-direction: column;
  p {
    max-width: 31.3125em;
    text-align: center;
    font-weight: 700;
    font-size: 1.125em;
    color: var(--clr-n8);
  }
  small {
    font-size: 1em;
  }
}
.missions-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @container (width > 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (width > 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
