<template>
  <div class="template">
    <iframe
      src="https://eliasse.assemblee-nationale.fr/eliasse/index.html"
      frameborder="0"
    ></iframe>
  </div>
</template>

<style scoped>
iframe {
  height: calc(100vh - 120px);
}
</style>
