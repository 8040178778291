<template>
  <LoaderFullPage v-if="store.loadingFetch" />
  <template v-else>
    <div class="card current-plan">
      <h6>Abonnement actuel</h6>
      <div class="plan-infos">
        <img
          v-if="store.user.permission_level === 'TRIAL'"
          src="@/assets/img/stars-trial.svg"
          class="trial-icon"
        />
        <h5>{{ currentPlanName }}</h5>
        <PermissionLevelIcon
          v-if="
            store.user.permission_level === 'BASIC' ||
            store.user.permission_level === 'SOCIETAL'
          "
          class="plan-icon"
          :permission-level="store.user.permission_level"
        />
        <span v-if="store.user.permission_level !== 'FREE'" class="plan-end">
          {{
            store.user.permission_level === "TRIAL" ||
            store.user.stripe_information.canceled
              ? "Prend fin le "
              : "Renouvellement le "
          }}
          <span class="date"
            >{{
              dayjs(store.user.stripe_information.end_date).format(
                "DD MMMM YYYY"
              )
            }}.</span
          >
        </span>
      </div>
      <p v-if="store.user.permission_level === 'TRIAL'">
        En tant que nouvel utilisateur, vous profitez du plan Societal pendant
        un mois.
      </p>
      <p v-if="store.user.stripe_information.canceled">
        Vous serez automatiquement rétrogradé à l'abonnement Free à l'expiration
        de votre abonnement. Pour continuer à profiter des avantages, veuillez
        renouveler votre abonnement.
      </p>
      <h6>Facturation</h6>
      <div class="stripe-row">
        <p v-if="store.user.permission_level === 'TRIAL'">
          Vous serez automatiquement rétrogradé à l’abonnement Free à
          l'expiration de la période d'essai. Pour continuer à profiter des
          avantages, veuillez ajoutez vos informations de paiement.
        </p>
        <p v-else>
          Information sur votre méthode de paiement, vos factures ou le
          renouvellement de votre abonnement
        </p>
        <BaseLink
          class="btn-primary to-stripe"
          :href="store.user.stripe_customer_portal_url"
        >
          Gérer mon abonnement
        </BaseLink>
      </div>
    </div>

    <button
      class="btn-primary plan-compare"
      @click="() => toast.info('Pas encore disponible')"
    >
      Comparer les abonnements
    </button>
  </template>
</template>

<script setup>
import { useToast } from "vue-toastification";
import dayjs from "dayjs";
import { computed } from "vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import PermissionLevelIcon from "@/components/general/atoms/PermissionLevelIcon.vue";

import { useUserSettingsStore } from "@/stores/user-settings";

const toast = useToast();
const store = useUserSettingsStore();

const currentPlanName = computed(() => {
  switch (store.user.permission_level) {
    case "SOCIETAL":
      return "Sociétal";
    case "BASIC":
      return "Essentiel";
    case "TRIAL":
      return "Période d'essai";
    case "FREE":
    default:
      return "Gratuit";
  }
});
</script>

<style lang="scss">
p {
  margin: 0;
  line-height: var(--lh-normal);
}

.current-plan {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .plan-infos {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    padding: 8px 16px;
    background-color: var(--clr-n2);

    .trial-icon {
      width: 23px;
      height: 23px;
    }

    .plan-end {
      margin-left: auto;

      .date {
        font-weight: var(--fw-semibold);
      }
    }
  }

  .stripe-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .btn-primary {
      &:hover,
      &:active {
        color: white;
      }
    }
  }
}

.plan-icon {
  width: 22px;
  height: 22px;
}

.plan-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @container (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  gap: 8px;

  .plan {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.current {
      border: 2px solid var(--clr-p5);
    }

    .plan-title {
      display: flex;
      align-items: baseline;
      gap: 16px;
    }

    .plan-short-description {
      min-height: 72px;
    }

    .plan-price {
      .big-price-row {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 13px;

        .big-price {
          font-size: 33.18px;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .monthly-price-row {
        display: flex;
        gap: 8px;
        align-items: center;

        .big-price {
          font-size: 16px;
          font-weight: 600;
          line-height: 150%; /* 24px */
        }
      }
    }

    .plan-features {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .features-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .features-title {
        font-weight: var(--fw-semibold);
      }

      .features-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0;
        padding-left: 0;
        line-height: var(--lh-normal);
        list-style-position: inside;

        li::marker {
          font-family: "Font Awesome 6 Pro";
          color: var(--clr-s6);
          content: "\f00c  "; /* Unicode for the desired Font Awesome icon */
          margin-right: 8px;
        }
      }
    }

    .btn-primary {
      align-self: flex-start;
    }
  }
}

.custom-dashboard-tooltip-trigger {
  color: var(--clr-s7);
}

.custom-dashboard-tooltip-text {
  width: 100%;
}

.plan-compare {
  align-self: flex-start;
}
</style>
