import { ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { httpRequest } from "@/utils/request.utils";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import * as Sentry from "@sentry/vue";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialTweetsFilters = {
  from: 0, //* Page number
  size: 20, //* Number of items per page
  sort: { retweet: "desc" }, //* Primary sorter of the list
  get_filters: true, //* Retrieve available filters
  highlight: true, //* Retrieve highligted text based on querries/dashboard
  //* Other options include : “filter” string for search, “start” and “end” strings for dates (dd/mm/yyyy)
};

export const useTweetsStore = defineStore("tweets", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const items = ref([]);
  const item_count = ref(null);
  const filters = ref(null);
  const selectedFilters = ref({ ...initialTweetsFilters });
  const loading = ref(false);

  const isAuthorFilterLoading = ref(false);
  const authors = ref([]);

  const fetchTweetsList = async (author_id = null) => {
    loading.value = true;

    //* Create the payload as a separate copy of selectedFilters so that the added sorters don't affect the original object
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    //* If there is author filters selected map them to only their ids
    if (payload.author?.length > 0) {
      payload.author = payload.author.map((author) => author._id);
    }

    delete payload.sort;
    payload.sort_field = Object.keys(selectedFilters.value.sort)[0];
    payload.sort_order = Object.values(selectedFilters.value.sort)[0];

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (author_id) {
      payload.author = [author_id];
    }

    try {
      const response = await httpRequest("post", `/tweets`, payload);
      if (response.data) {
        item_count.value = response.data.item_count;
        items.value = response.data.items;
        filters.value = response.data.filters;
      }

      // Validate data from back-end and send errors to Sentry
      for (const tweet of items.value) {
        if (!tweet.author.twitter_information) {
          Sentry.withScope((scope) => {
            scope.setExtra("twitter-id", tweet?.id_twitter);
            Sentry.captureException(
              new Error("API /tweets : tweet with missing twitter infos")
            );
          });
        }
      }
    } catch (err) {
      item_count.value = null;
      items.value = [];
      filters.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const getAuthorFilters = async (name) => {
    isAuthorFilterLoading.value = true;

    try {
      const response = await httpRequest("post", "/twitter/search_authors", {
        name: name,
      });
      authors.value = response.data.personalities;
    } catch (err) {
      handleNetworkError(err);
    }
    isAuthorFilterLoading.value = false;
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialTweetsFilters };
  };

  const resetStore = () => {
    items.value = [];
    filters.value = null;
    selectedFilters.value = { ...initialTweetsFilters };
    loading.value = false;

    item_count.value = null;
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        router.currentRoute.value.name === "Tweets"
      ) {
        selectedFilters.value.from = 0;
        fetchTweetsList();
      }
    }
  );

  return {
    items,
    item_count,
    filters,
    selectedFilters,
    loading,

    isAuthorFilterLoading,
    authors,
    fetchTweetsList,
    getAuthorFilters,
    resetFilters,
    resetStore,
  };
});
