<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="params-row request-and-check-all">
    <SwitchSlide :is-active="!store.bypassQueryNews" @toggle="toggleUseRequest"
      >Utiliser la requête</SwitchSlide
    >
    <CheckBox
      :checked="isAllSelected"
      @change="
        () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
      "
    >
      Toutes les actualités
    </CheckBox>
  </div>
  <div class="params-row">
    <div class="page-settings filters">
      <FilterCheckBox
        label="Tweets"
        color="var(--color-tweets)"
        :item-count="store.tweetCount"
        :is-checked="store.prmNewsFilters.includes(NEWS_FILTERS.TWEETS)"
        @check="addFilter(NEWS_FILTERS.TWEETS)"
        @uncheck="removeFilter(NEWS_FILTERS.TWEETS)"
      />
      <FilterCheckBox
        label="Articles"
        color="var(--color-articles)"
        :item-count="store.linkCount"
        :is-checked="store.prmNewsFilters.includes(NEWS_FILTERS.ARTICLES)"
        @check="addFilter(NEWS_FILTERS.ARTICLES)"
        @uncheck="removeFilter(NEWS_FILTERS.ARTICLES)"
      />
    </div>
    <div class="page-settings">
      <SortBy
        :items="sortersNames"
        :selected-sort-item="currentSortLabel"
        @change-sort="handleSortChange"
      />
      <SelectItemsLimit
        :items-limit="selectedPrmNewsParams.size"
        @change-limit="changeLimit"
      >
        Actualités
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { usePrmStore } from "@/stores/prm";
import { NEWS_FILTERS } from "@/stores/prm";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const route = useRoute();
const store = usePrmStore();

const props = defineProps({
  isZoomed: {
    type: Boolean,
    default: false,
  },
});

// Sorters
const sortersNames = {
  created_at: "Date",
  number_of_retweets: "Retweets",
};

const { selectedPrmNewsParams } = storeToRefs(store);

const currentSortField = ref(
  Object.keys(selectedPrmNewsParams.value.sort)[0] ?? "created_at"
);
const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const showScrollTop = computed(() => selectedPrmNewsParams.value.size > 20);

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentSortField.value = selectedSortField;
      selectedPrmNewsParams.value.sort = { [currentSortField.value]: "desc" };
      fetchNews();
    }
  }
};

const addFilter = (filter) => {
  store.prmNewsFilters.push(filter);
  fetchNews();
};

const removeFilter = (filter) => {
  store.prmNewsFilters = store.prmNewsFilters.filter((f) => f !== filter);
  fetchNews();
};

const selectAllFilters = () => {
  store.prmNewsFilters = [NEWS_FILTERS.TWEETS, NEWS_FILTERS.ARTICLES];
  fetchNews();
};

const unselectAllFilters = () => {
  store.prmNewsFilters = [];
  fetchNews();
};

const isAllSelected = computed(
  () => store.prmNewsFilters.length === Object.keys(NEWS_FILTERS)?.length
);

const toggleUseRequest = () => {
  store.bypassQueryNews = !store.bypassQueryNews;
  fetchNews();
};

// Limit
const changeLimit = (size) => {
  selectedPrmNewsParams.value.size = size;
  selectedPrmNewsParams.value.from = 0;
  fetchNews();
};

const fetchNews = async () => {
  selectedPrmNewsParams.value.from = 0;
  props.isZoomed
    ? await store.fetchOnePersonnalityNews(route.params._id)
    : await store.fetchFollawedPrmNews();
};

fetchNews();
</script>

<style lang="scss" scoped>
.request-and-check-all {
  justify-content: flex-start;
  gap: 15px;
}
.filters {
  gap: 10px;
}
</style>
