<template>
  <div
    v-if="!disabled"
    v-on-click-outside="() => (isMenuOpen = false)"
    class="select-interval"
  >
    <div class="select-box" @click.self="isMenuOpen = !isMenuOpen">
      <label v-if="!intervalPreset"> Période </label>
      <div v-else class="selected" @click="isMenuOpen = !isMenuOpen">
        {{ intervalLabel }}
      </div>

      <div class="select-arrow">
        <!-- Ouvert -->
        <i v-if="isMenuOpen" class="fa-solid fa-chevron-up"></i>
        <!-- Fermé -->
        <i v-else class="fa-solid fa-chevron-down"></i>
      </div>
    </div>

    <div v-show="isMenuOpen" class="menu">
      <!-- preset interval -->
      <TimerangeOptions
        :interval-preset="intervalPreset"
        :start-date="start_date"
        :end-date="end_date"
        @select-time="selectTime($event)"
        @set-start="start_date = $event"
        @set-end="end_date = $event"
      />

      <!-- Reset -->
      <div class="btn-row">
        <button
          v-if="isCustomIntervalValid"
          class="btn-primary btn-apply"
          @click="selectTime(TIME_INTERVALS.FREE)"
        >
          Filtrer la date
        </button>
        <button class="btn-reset btn-secondary" @click="resetInterval">
          <i class="fa-solid fa-arrow-rotate-left"></i>
          <span class="label">Réinitialiser</span>
        </button>
      </div>
    </div>
  </div>

  <!-- Disabled State -->
  <div v-else class="select-interval disabled">
    <div class="select-box">
      <label> Période </label>

      <div class="select-arrow">
        <i class="fa-solid fa-chevron-down"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import dayjs from "dayjs";

import TimerangeOptions from "@/components/general/atoms/TimerangeOptions.vue";
import { vOnClickOutside } from "@vueuse/components";
import { TIME_INTERVALS } from "@/stores/user";
import {
  getEndDateFromPreset,
  getStartDateFromPreset,
} from "@/utils/getDateFromPreset";

const props = defineProps({
  intervalPreset: {
    type: String,
    required: true,
  },
  intervalStart: {
    type: String,
    default: "",
  },
  intervalEnd: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["setTimeRange"]);

const isMenuOpen = ref(false);

// Disable Eslint rule because we actually want to lose reactivity here
/* eslint-disable vue/no-setup-props-destructure */
const start_date = ref(
  props.intervalStart?.split("T")[0] ??
    getStartDateFromPreset(props.intervalPreset)
);
const end_date = ref(
  props.intervalEnd?.split("T")[0] ?? getEndDateFromPreset(props.intervalPreset)
);
/* eslint-enable vue/no-setup-props-destructure */

const isCustomIntervalValid = computed(
  () =>
    !!start_date.value &&
    !!end_date.value &&
    dayjs(start_date.value) < dayjs(end_date.value)
);

const intervalLabel = computed(() => {
  switch (props.intervalPreset) {
    case TIME_INTERVALS.LAST_24H:
      return "< 24h";
    case TIME_INTERVALS.LAST_WEEK:
      return "< 1 semaine";
    case TIME_INTERVALS.LAST_MONTH:
      return "< 1 mois";
    case TIME_INTERVALS.LAST_YEAR:
      return "< 1 an";
    case TIME_INTERVALS.LEGI_15:
      return "XVe legi.";
    case TIME_INTERVALS.LEGI_16:
      return "XVIe legi.";
    case TIME_INTERVALS.LEGI_17:
      return "XVIIe legi.";
    default:
      if (start_date.value && end_date.value) {
        return `${dayjs(start_date.value).format("DD/MM/YY")} - ${dayjs(
          end_date.value
        ).format("DD/MM/YY")}`;
      } else {
        return "Période";
      }
  }
});

function selectTime(option) {
  if (option !== TIME_INTERVALS.FREE) {
    start_date.value = getStartDateFromPreset(option);
    end_date.value = getEndDateFromPreset(option);
  }

  emit("setTimeRange", {
    interval: option,
    start: dayjs(start_date.value).tz("Europe/Paris").format(),
    end: dayjs(end_date.value).tz("Europe/Paris").format(),
  });
  isMenuOpen.value = false;
}

function resetInterval() {
  emit("setTimeRange", {
    interval: TIME_INTERVALS.LAST_24H,
    start: getStartDateFromPreset(TIME_INTERVALS.LAST_24H),
    end: getEndDateFromPreset(TIME_INTERVALS.LAST_24H),
  });
  isMenuOpen.value = false;
}
</script>

<style scoped lang="scss">
.select-interval {
  position: relative;
  -webkit-user-select: none;
  user-select: none;

  /* Input
========================================================================== */
  .select-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: fit-content;
    @media (max-width: 1100px) {
      width: 120px;
    }

    height: 30px;
    padding: 4px 15px;
    background: var(--clr-p8);
    border-radius: 15px;
    box-shadow: 0 3px 5px #00000014;

    color: white;
    font-size: v-bind(
      'props.intervalPreset === "free" ? "0.75rem" : "inherit"'
    );

    cursor: pointer;

    .select-arrow {
      margin-left: 5px;
      pointer-events: none;
    }
  }

  &.disabled > .select-box {
    opacity: 0.6;
    cursor: not-allowed;
  }

  /* Popover Menu
========================================================================== */
  .menu {
    position: absolute;
    z-index: 2;
    top: 33px;
    left: 0;
    box-shadow: 0 1px 6px #0000005d;
    border-radius: 13px;
    width: 280px;
    max-height: 435px;
    padding: 5px 0;
    overflow: hidden;
    color: var(--clr-n8);
    background: white;
  }
}

/* Footer Reset
========================================================================== */
.btn-row {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 5px 10px;
  gap: 10px;

  .btn-apply {
    font-size: 0.813rem;
    flex-shrink: 0;
  }

  .btn-reset {
    font-size: 0.9rem;

    .label {
      margin-left: 4px;
    }
  }
}
</style>
