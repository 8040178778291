import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useToast } from "vue-toastification";
import dayjs from "dayjs";
import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";
import { PRESET_FEATURES, features } from "@/utils/features";
import { useUserStore } from "@/stores/user";
import { useAgendaStore } from "@/stores/agenda";
import { useJorfsStore } from "@/stores/jorfs";
import { usePetitionsStore } from "@/stores/petitions";
import { useNewsStore } from "@/stores/news";
import { useNominationsStore } from "@/stores/nominations";
import { useLegiWorkStore } from "@/stores/legi-work";
import { useAmendmentsStore } from "@/stores/amendments";
import { useQuestionsStore } from "@/stores/questions";
import { useLegiReportStore } from "@/stores/legi-reports";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import { usePrmStore } from "@/stores/prm";
import { useLobbiesStore } from "@/stores/lobbies";
import { useTwitterStatsStore } from "@/stores/twitter-stats";
import { useTweetsStore } from "@/stores/tweets";
import { useTwitterStakeholdersStore } from "@/stores/twitter-stakeholders";
import { useTwitterArticlesStore } from "@/stores/twitter-articles";
import { useRoute, useRouter } from "vue-router";

export const useFilterPresetsStore = defineStore("filter-presets", () => {
  const route = useRoute();
  const router = useRouter();
  const toast = useToast();
  const userStore = useUserStore();

  const featuresStores = {
    [PRESET_FEATURES.AGENDA]: useAgendaStore(), // Agenda
    [PRESET_FEATURES.JORFS]: useJorfsStore(), // Journal Officiel
    [PRESET_FEATURES.PETITIONS]: usePetitionsStore(), // Pétitions
    [PRESET_FEATURES.NEWS_NEWS]: useNewsStore(), // Actualités - Communiqués
    [PRESET_FEATURES.NEWS_NOMINATIONS]: useNominationsStore(), // Actualités - Nominations
    [PRESET_FEATURES.LEGI_LEGIWORKS]: useLegiWorkStore(), // Travaux parlementaires - Dossiers législatifs
    [PRESET_FEATURES.LEGI_AMENDMENTS]: useAmendmentsStore(), // Travaux parlementaires - Amendements
    [PRESET_FEATURES.LEGI_QUESTIONS]: useQuestionsStore(), // Travaux parlementaires - Questions
    [PRESET_FEATURES.LEGI_REPORTS]: useLegiReportStore(), // Travaux parlementaires - Comptes-rendus et rapports
    [PRESET_FEATURES.LEGI_INSTITU_GROUPS]: useInstituGroupsStore(), // Travaux parlementaires - Commissions et autres organes
    [PRESET_FEATURES.PRM_NATIONAL]: usePrmStore(), // PRM - National & Régional, départemental & local
    [PRESET_FEATURES.PRM_REGIONAL]: usePrmStore(), // PRM - Régional, départemental & local
    [PRESET_FEATURES.PRM_LOBBIES]: useLobbiesStore(), // PRM - Lobbies
    [PRESET_FEATURES.SM_OVERVIEW]: useTwitterStatsStore(), // Social media - Overview
    [PRESET_FEATURES.SM_TWEETS]: useTweetsStore(), // Social media - Tweets
    [PRESET_FEATURES.SM_STAKEHOLDERS]: useTwitterStakeholdersStore(), // Social media - Parties prenantes
    [PRESET_FEATURES.SM_ARTICLES]: useTwitterArticlesStore(), // Social media - Articles
  };

  const featuresLoading = ref([]);
  const featuresFilters = ref({});

  const getFeatureFilters = async (key) => {
    if (!Object.keys(featuresFilters.value).includes(key)) {
      featuresLoading.value.push(key);
      try {
        let payload = {
          get_filters: true,
          size: 0,
        };
        if (key === PRESET_FEATURES.PETITIONS) {
          delete payload.get_filters;
          payload.filters = true;
        }
        if (key === PRESET_FEATURES.AGENDA) {
          delete payload.size;
          payload.start = dayjs();
          payload.end = dayjs();
        }
        if (features[key]?.additionalPayload) {
          payload = { ...payload, ...features[key].additionalPayload };
        }
        const response = await httpRequest("post", features[key].url, payload);
        featuresFilters.value[key] = response.data.filters;
      } catch (err) {
        handleNetworkError(err);
      } finally {
        featuresLoading.value.splice(featuresLoading.value.indexOf(key));
      }
    }
  };
  const filtersToExclude = [
    "from",
    "size",
    "get_filters",
    "filters",
    "start",
    "end",
    "sort",
    "sort_field",
    "sort_order",
    "highlight",
  ];
  const activeFiltersByFeature = computed(() => {
    const groupedFilters = {};
    for (const [feature, { active_filters }] of Object.entries(features)) {
      active_filters.forEach((filter) => {
        if (featuresStores[feature][filter]) {
          groupedFilters[feature] = { ...featuresStores[feature][filter] };
          filtersToExclude.forEach((filterToExclude) => {
            delete groupedFilters[feature][filterToExclude];
          });
        }
      });
    }
    return groupedFilters;
  });

  const loading = ref(false);

  const presets = ref([]);

  const createPreset = async (preset) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", "/user-saved-filters", preset);

      if (response.status === 201) {
        readPresets();
        toast.success("Préselection de filtres ajoutée avec succès.");
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const readPresets = async () => {
    loading.value = true;

    try {
      const response = await httpRequest("get", "/user-saved-filters");

      if (response.data) {
        presets.value = response.data;
      }
    } catch (err) {
      presets.value = [];
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const updatePreset = async (preset_id, preset) => {
    loading.value = true;

    try {
      const response = await httpRequest(
        "patch",
        `/user-saved-filters/${preset_id}`,
        preset
      );

      if (response.status === 200) {
        const index = presets.value.findIndex((p) => p.id === preset_id);
        presets.value.splice(index, 1, response.data);
        presets.value[index].id = preset_id;
        toast.success("Préselection de filtres modifiée avec succès.");
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const deletePreset = async (preset_id) => {
    loading.value = true;

    try {
      const response = await httpRequest(
        "delete",
        `/user-saved-filters/${preset_id}`
      );

      if (response.status === 200) {
        presets.value = presets.value.filter(
          (preset) => preset.id !== preset_id
        );
        toast.success("Préselection de filtres supprimée avec succès.");
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const applyPreset = async (preset_id, filters) => {
    loading.value = true;

    // set the url query for the other topbar filters
    router.push({
      query: {
        ...route.query,
        search: filters.search.length ? filters.search : undefined,
        thematics:
          filters.thematics.length > 0
            ? filters.thematics.toString()
            : undefined,
        subthematics:
          filters.subthematics.length > 0
            ? filters.subthematics.toString()
            : undefined,
      },
    });

    // Set the timerange filters in the user store and update the backend
    userStore.selectedInterval.selected_from_date = dayjs(filters.start)
      .tz("Europe/Paris")
      .format();
    userStore.selectedInterval.selected_to_date = dayjs(filters.end)
      .tz("Europe/Paris")
      .format();
    userStore.selectedInterval.selected_time_range =
      filters.selected_time_range;
    userStore.updateFilters(userStore.selectedInterval);

    // Apply filters in features
    // 1) Get only features with filters in the preset filter
    const featureFilters = {};
    Object.values(PRESET_FEATURES).forEach((feature) => {
      if (filters[feature]) {
        featureFilters[feature] = Object.fromEntries(
          // Keep only non null filters
          // The [,v] syntax is because the entries are needed to recompose the object after filtering but we don't need to use the key in the callback
          Object.entries({ ...filters[feature] }).filter(([, v]) => v !== null)
        );
      }
    });
    // 2) for each feature with filters, set it in the corresponding store after reseting currently set filters
    Object.entries(featureFilters).forEach(([feature, activeFilters]) => {
      featuresStores[feature].resetFilters();
      featuresStores[feature].selectedFilters = {
        ...featuresStores[feature].selectedFilters,
        ...activeFilters,
      };
    });

    // Patch to update the updated_at value
    try {
      const response = await httpRequest(
        "patch",
        `/user-saved-filters/${preset_id}`,
        {}
      );

      if (response.status === 200) {
        const index = presets.value.findIndex((p) => p.id === preset_id);
        presets.value.splice(index, 1, response.data);
        presets.value[index].id = preset_id;
        toast.success("Préselection de filtres appliquée !");
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    activeFiltersByFeature,
    presets,
    features,
    featuresLoading,
    featuresFilters,
    getFeatureFilters,
    createPreset,
    readPresets,
    updatePreset,
    deletePreset,
    applyPreset,
  };
});
