<template>
  <div class="card">
    <section class="header">
      Date de publication :
      {{ dayjs(activity.created_at).format("DD/MM/YYYY") }}
    </section>
    <section class="body">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h5 class="title" v-html="activity.title" />
      <div class="pic-and-name">
        <CardProfilePicture
          :picture-url="
            activity.twitter_information?.profile_picture
              ? activity.twitter_information.profile_picture
              : null
          "
          class="picture"
          :size="48"
        />
        <p class="name">{{ activity.name }}</p>
      </div>
      <p class="category period">
        <span class="category-title">Période concernée :</span>
        {{ dayjs(activity.start).format("DD/MM/YYYY") }} au
        {{ dayjs(activity.end).format("DD/MM/YYYY") }}
      </p>
      <div class="category">
        <p class="category-title">Domaines d'intervention :</p>
        <ul>
          <li v-for="(category, index) in activity.categories" :key="index">
            {{ category }}
          </li>
        </ul>
      </div>

      <div class="category">
        <p class="category-title">Intérêts représentés :</p>
        <ul>
          <li
            v-for="(third_party, index) in activity.interest[0].third_parties"
            :key="index"
          >
            {{ third_party }}
          </li>
        </ul>
      </div>
      <template v-if="isExtended">
        <div class="category">
          <p class="category-title">Type de décisions publiques :</p>
          <ul>
            <li
              v-for="(decision, index) in activity.interest[0].decisions"
              :key="index"
            >
              {{ decision }}
            </li>
          </ul>
        </div>
        <div class="category">
          <p class="category-title">
            Type d'actions de représentation d'intérêts :
          </p>
          <ul>
            <li
              v-for="(action, index) in activity.interest[0].actions"
              :key="index"
            >
              {{ action }}
            </li>
          </ul>
        </div>
        <div class="category">
          <p class="category-title">Catégorie de responsables publics :</p>
          <ul>
            <li
              v-for="(person, index) in activity.interest[0].people"
              :key="index"
            >
              {{ person }}
            </li>
          </ul>
        </div>
        <div v-if="activity.interest[0].observation" class="category">
          <p class="category-title">Catégorie de responsables publics :</p>
          <p>{{ activity.interest[0].observation }}</p>
        </div>
      </template>
      <button class="see-more" @click="isExtended = !isExtended">
        <span>{{ isExtended ? "Voir moins" : "Voir plus" }}</span>
        <transition name="rotate" mode="out-in">
          <i v-if="isExtended" key="up" class="fa-solid fa-chevron-up"></i>
          <i v-else key="down" class="fa-solid fa-chevron-down"></i>
        </transition>
      </button>
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";
import dayjs from "dayjs";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";

defineProps({
  activity: {
    type: Object,
    required: true,
  },
});

const isExtended = ref(false);
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

ul {
  margin: 0;
  padding-left: 26px;

  li {
    margin: 4px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card {
  color: var(--clr-n8);
}

.body {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    margin: 0;
  }

  .pic-and-name {
    display: flex;
    align-items: center;
    gap: 8px;

    .name {
      text-transform: capitalize;
    }
  }

  .category-title {
    color: var(--clr-service-twitter);
  }

  .category:not(.period) .category-title {
    margin-bottom: 4px;
  }

  .see-more {
    display: flex;
    gap: 8px;
    margin-left: auto;
    width: fit-content;
    padding: 8px;
    color: var(--clr-s8);
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
}
</style>
