<template>
  <div class="card group-column">
    <div class="sticky-header">
      <slot name="header"></slot>
      <div class="header-row">
        <span>Personnalité</span><span>{{ metricLabel }}</span>
      </div>
    </div>
    <ul>
      <AuthorMappingCard
        v-for="author in boundColumnData"
        :key="author._id"
        :author="author"
        :metric="props.metric"
        :show-group-tags="showGroupTags"
      />
    </ul>
  </div>
</template>

<script setup>
import { computed } from "vue";
import AuthorMappingCard from "../molecules/AuthorMappingCard.vue";
import { MAPPING_METRICS } from "@/stores/twitter-stakeholders";

const props = defineProps({
  columnData: { type: Array, required: true },
  metric: { type: String, required: true },
  showGroupTags: { type: Boolean, default: true },
});

const metricLabel = computed(
  () =>
    ({
      [MAPPING_METRICS.TWEETS]: "Tweets",
      [MAPPING_METRICS.MENTIONS]: "Mentions",
      [MAPPING_METRICS.IMPRESSIONS]: "Impressions",
      number_of_amendments: "Amendements",
    })[props.metric]
);

// Display only the top 25 per column to avoid UI slow-downs
const boundColumnData = computed(() =>
  props.columnData.filter((_, idx) => idx < 25)
);
</script>

<style scoped lang="scss">
.group-column {
  display: flex;
  flex-direction: column;

  min-width: 280px;
  padding: 0;
  overflow-y: scroll;

  align-items: center;
  justify-content: flex-start;

  .sticky-header {
    position: sticky;
    top: 0;

    width: 100%;
    padding: 10px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;
  }

  .header-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
</style>
