import { ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { httpRequest } from "@/utils/request.utils";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialNominationsFilters = {
  size: 20,
  from: 0,
  get_filters: true,
  highlight: true,
};

// Defining store
export const useNominationsStore = defineStore("nominations", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const items = ref([]);
  const categories = ref({});
  const item_count = ref(null);
  const filters = ref({});
  const bypassQuery = ref(false);
  const loading = ref(false);

  const selectedFilters = ref({ ...initialNominationsFilters });

  const fetchNominationsList = async () => {
    loading.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest("post", "/nominations", payload);

      if (response.data) {
        items.value = response.data.items;
        item_count.value = response.data.item_count;
        filters.value.themes = response.data.filters?.themes || [];
      }
    } catch (err) {
      items.value = [];
      item_count.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialNominationsFilters };
  };

  const resetStore = () => {
    items.value = [];
    categories.value = null;
    selectedFilters.value = { ...initialNominationsFilters };
    loading.value = false;

    item_count.value = null;
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        router.currentRoute.value.name === "Nominations"
      ) {
        fetchNominationsList();
      }
    }
  );

  return {
    items,
    categories,
    filters,
    bypassQuery,
    item_count,
    loading,

    selectedFilters,
    resetFilters,
    resetStore,
    fetchNominationsList,
  };
});
