<template>
  <SocialsViewWrapper>
    <TwitterStakeholdersParamsRanking
      v-if="currentUiView === UI_VIEWS.RANKING"
    />
    <TwitterStakeholdersParamsMapping v-else />

    <LoaderFullPage v-if="store.loading" />

    <template v-else>
      <TwitterStakeholdersRanking v-if="currentUiView === UI_VIEWS.RANKING" />
      <TwitterStakeholdersPoliticalMap
        v-else-if="currentUiView === UI_VIEWS.POLITICAL_MAP"
      />
      <TwitterStakeholdersSocietalMap
        v-else-if="currentUiView === UI_VIEWS.SOCIETAL_MAP"
      />
    </template>
  </SocialsViewWrapper>
</template>

<script setup>
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import TwitterStakeholdersParamsRanking from "@/components/twitter/organisms/TwitterStakeholdersParamsRanking.vue";
import TwitterStakeholdersParamsMapping from "@/components/twitter/organisms/TwitterStakeholdersParamsMapping.vue";
import TwitterStakeholdersRanking from "@/components/twitter/organisms/TwitterStakeholdersRanking.vue";
import TwitterStakeholdersPoliticalMap from "@/components/twitter/organisms/TwitterStakeholdersPoliticalMap.vue";
import TwitterStakeholdersSocietalMap from "@/components/twitter/organisms/TwitterStakeholdersSocietalMap.vue";
import SocialsViewWrapper from "@/components/twitter/templates/SocialsViewWrapper.vue";
import {
  useTwitterStakeholdersStore,
  UI_VIEWS,
} from "@/stores/twitter-stakeholders";
import { useUserSettingsStore } from "@/stores/user-settings";

const route = useRoute();

const store = useTwitterStakeholdersStore();
const userSettingsStore = useUserSettingsStore();

store.isFollawedActive = route.fullPath.includes("follawed");

const { currentUiView } = storeToRefs(store);

if (userSettingsStore.hasPermission("SOCIETAL")) {
  store.selectedFilters.from = 0;

  if (Object.keys(route.query).includes("stakeholder")) {
    store.fetchTwitterStakeholdersList({ author: [route.query.stakeholder] });
  } else {
    store.fetchTwitterStakeholdersList();
  }
}
</script>
