<template>
  <template v-if="twitterStakeholdersStore.items.length">
    <div
      class="data-and-zoom-wrapper"
      :class="[isZoomed() ? 'grids-columns-2-1' : '']"
    >
      <div class="data-container">
        <div
          v-if="twitterStakeholdersStore.items"
          class="twitter-authors-card-wrapper"
        >
          <!-- Cards -->
          <AuthorCard
            v-for="author in twitterStakeholdersStore.items"
            :key="author._id"
            :author="author"
            :is-mentions-zoomed="
              author.twitter_information.handle_twitter ===
              sidebarMentionsAuthorHandle
            "
            :is-tweets-zoomed="author._id === sidebarTweetsAuthorId"
            @activate-tweets-zoom="activateTweetsZoom"
            @activate-mentions-zoom="activateMentionsZoom"
            @close-zoom="closeAllZoom"
          />
        </div>
        <!-- Pagination -->
        <PaginationElement
          v-if="twitterStakeholdersStore.itemCount"
          :key="`${twitterStakeholdersStore.itemCount}${twitterStakeholdersStore.selectedFilters.from}${twitterStakeholdersStore.selectedFilters.size}`"
          :total-items="twitterStakeholdersStore.itemCount"
          :current-page="twitterStakeholdersStore.selectedFilters.from"
          :items-limit="twitterStakeholdersStore.selectedFilters.size"
          @change-current-page="changeCurrentPage"
        />
      </div>
      <!-- Closing Tweets Column-->
      <div v-if="isZoomed()" ref="zoomedTweets" class="zoomed-tweets">
        <h3 class="sub-title">
          <span v-if="sidebarTweetsAuthorId"
            >{{ zoomedAuthor.number_of_tweets }} derniers tweets</span
          >
          <span v-else
            >{{ zoomedAuthor.number_of_mentions }} dernières mentions</span
          >
          <!-- Closing column -->
          <button @click="closeAllZoom()">
            <i class="fa-solid fa-x"></i>
          </button>
        </h3>
        <!-- Looping trougth the Author's Tweets-->
        <ul class="zoomed-tweets-list">
          <div v-if="isSidebarLoading" class="loading">
            <LoaderFullPage />
          </div>
          <li v-for="tweet in sidebarTweets" v-else :key="tweet._id">
            <AuthorsTweetsCard :tweet="tweet" />
          </li>
        </ul>
      </div>
    </div>
  </template>
  <NoData v-else-if="twitterStakeholdersStore.hasActiveFilters" />
  <NoDataFollawed
    v-else-if="
      !twitterStakeholdersStore.loading &&
      twitterStakeholdersStore.itemCount === 0 &&
      twitterStakeholdersStore.isFollawedActive
    "
  />
  <NoData v-else />
</template>

<script setup>
import { ref, computed } from "vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import AuthorCard from "@/components/twitter/molecules/AuthorCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import AuthorsTweetsCard from "../molecules/AuthorsTweetsCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";
import { useTwitterStakeholdersStore } from "@/stores/twitter-stakeholders";
const twitterStakeholdersStore = useTwitterStakeholdersStore();
const sidebarTweetsAuthorId = ref(null);
const sidebarMentionsAuthorHandle = ref(null);

const zoomedAuthor = computed(() =>
  twitterStakeholdersStore.items?.find(
    ({ _id, twitter_information: { handle_twitter } }) =>
      _id === sidebarTweetsAuthorId?.value ||
      handle_twitter === sidebarMentionsAuthorHandle?.value
  )
);

const isSidebarLoading = computed(
  () =>
    twitterStakeholdersStore.isRelatedTweetsLoading ||
    twitterStakeholdersStore.isRelatedMentionsLoading
);

const sidebarTweets = computed(() =>
  sidebarTweetsAuthorId.value
    ? twitterStakeholdersStore.tweetsByStakeholder
    : twitterStakeholdersStore.tweetsMentioningStakeholder
);

function activateTweetsZoom(authorId) {
  sidebarTweetsAuthorId.value = authorId;
  sidebarMentionsAuthorHandle.value = null;

  twitterStakeholdersStore.fetchTweetsForOneStakeholder(authorId);
}

function activateMentionsZoom(authorHandle) {
  sidebarMentionsAuthorHandle.value = authorHandle;
  sidebarTweetsAuthorId.value = null;

  twitterStakeholdersStore.fetchMentionsForOneStakeholder(authorHandle);
}

const changeCurrentPage = async (from) => {
  twitterStakeholdersStore.selectedFilters.from = from;
  await twitterStakeholdersStore.fetchTwitterStakeholdersList();
};

function closeAllZoom() {
  sidebarTweetsAuthorId.value = null;
  sidebarMentionsAuthorHandle.value = null;
}

function isZoomed() {
  return sidebarTweetsAuthorId.value || sidebarMentionsAuthorHandle.value;
}
</script>

<style scoped lang="scss">
.data-and-zoom-wrapper {
  display: grid;
  grid-gap: 10px;
  position: relative;
}
.twitter-authors-card-wrapper {
  display: grid;
  grid-gap: 10px;
  flex-grow: 3;
  grid-template-columns: 1fr;
}
.grids-columns-2-1 {
  grid-template-columns: 2fr 1fr;
}

.data-container {
  container-type: inline-size;
  // overflow-y: scroll;
  // height: 100vh;
}

@container (width > 860px) {
  .twitter-authors-card-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@container (width > 1300px) {
  .twitter-authors-card-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.zoomed-tweets {
  position: sticky;
  top: 0;

  height: auto;
  max-height: 90vh;

  color: white;
  overflow-y: auto;
  padding: 5px 4px;
  background: var(--clr-service-twitter) 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;

  .loading {
    height: 50vh;
  }

  .sub-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 4px 4px 15px;

    /* Sticking the Item number of tweets header */
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--clr-service-twitter);

    button {
      font-size: 0.8em;
      width: 25px;
      height: 25px;
      padding: 4px;
      border-radius: 50%;
      &:hover {
        background-color: white;
        color: var(--clr-service-twitter);
      }
    }
  }
  .zoomed-tweets-list {
    overflow: auto;
    max-height: calc(100% - 40px);
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      background-color: var(--clr-p5);
      color: var(--clr-p2);
      margin-bottom: 5px;
      border-radius: 8px;
    }
  }
}
</style>
