<template>
  <LoaderFullPage v-if="store.loadingEvents" />
  <template v-else-if="store.events.length > 0">
    <div class="events-params">
      <div>Total: {{ store.eventsCount }}</div>
      <SelectItemsLimit
        :items-limit="store.eventsFilters.size"
        @change-limit="changeLimit"
        >Événements</SelectItemsLimit
      >
    </div>
    <ul class="events-list">
      <li v-for="event in store.events" :key="event._id">
        <ReunionCard :reunion="event" />
      </li>
    </ul>

    <PaginationElement
      v-if="store.eventsCount"
      :key="`${store.eventsCount}${store.eventsFilters.from}${store.eventsFilters.size}`"
      :total-items="store.eventsCount"
      :current-page="store.eventsFilters.from"
      :items-limit="store.eventsFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <div v-else class="empty">
    <img
      src="@/assets/img/institutional-groups/empty-states/commissions-agendas-nodatas.svg"
      alt="aria-hidden=true"
    />
    <p>Aucun évènement prévu pour cet organe.</p>
  </div>
</template>

<script setup>
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import ReunionCard from "@/components/legi-work/atoms/ReunionCard.vue";
const store = useInstituGroupsStore();

const changeCurrentPage = (from) => {
  store.eventsFilters.from = from;
  store.fetchAgenda(store.missionZoom?.ref || store.groupZoom?.ref);
};

function changeLimit(size) {
  store.eventsFilters.size = size;
  store.eventsFilters.from = 0;
  store.fetchAgenda(store.missionZoom?.ref || store.groupZoom?.ref);
}

store.fetchAgenda(store.missionZoom?.ref || store.groupZoom?.ref);
</script>

<style scoped lang="scss">
.empty > img {
  max-width: 289px;
}
.events-params {
  display: flex;
  justify-content: space-between;
}

.events-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @container (width > 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (width > 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @container (width > 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @container (width > 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  :deep(.card) {
    display: flex;
    flex-direction: column;
    height: 100%;

    .signatures {
      font-weight: 300;
    }

    .body {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      gap: 15px;

      .link {
        margin-top: auto;
        align-self: flex-end;
      }
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  font-size: 1.125em;
  flex-direction: column;
  p {
    max-width: 31.3125em;
    text-align: center;
    font-weight: 700;
    font-size: 1.125em;
    color: var(--clr-n8);
  }
  small {
    font-size: 1em;
  }
}
</style>
