<template>
  <div class="englobe-params">
    <div class="params-row">
      <!-- Back to index -->
      <RouterLink :to="backRoute" class="back">
        <i class="fa-solid fa-chevron-left"></i> Retour à la liste
      </RouterLink>
    </div>
    <div class="params-row">
      <RouterRadioButtons :options="textOptions" />
    </div>
    <div class="params-row">
      <PrmNewsParams v-if="route.fullPath.includes('news')" is-zoomed />
      <PrmActivitiesParams
        v-if="route.fullPath.includes('activities')"
        is-zoomed
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import PrmNewsParams from "./PrmNewsParams.vue";
import PrmActivitiesParams from "./PrmActivitiesParams.vue";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import side_menu from "@/utils/side_menu";
import { usePrmStore } from "@/stores/prm";

const store = usePrmStore();
const route = useRoute();

const routerOptions = route.name.toLowerCase().includes("national")
  ? side_menu
      .find((r) => r.name === "PRM")
      .secondary_tabs.find((tab) => tab.name === "National")
      .secondary_routes.find((r) => r.path === "/prm-national/:_id").children
  : side_menu
      .find((r) => r.name === "PRM")
      .secondary_tabs.find((tab) => tab.name?.includes("Régional"))
      .secondary_routes.find((r) => r.path === "/prm-regional/:_id").children;

let textOptions = route.name.toLowerCase().includes("national")
  ? routerOptions.map((r) => ({
      label: r.name.replace("PRM National Zoom - ", ""),
      value: `/prm-national/${route.params._id}` + (r.path ? `/${r.path}` : ""),
      exact: true,
    }))
  : routerOptions.map((r) => ({
      label: r.name.replace("PRM Régional Zoom - ", ""),
      value: `/prm-regional/${route.params._id}` + (r.path ? `/${r.path}` : ""),
      exact: true,
    }));
// Remove the biography tab if no bio on personnality
textOptions = textOptions.filter((r) =>
  store.prmZoom.biographie ? r : r.label !== "Biographie"
);

const backRoute = computed(() => {
  let targetRoute = route.name.toLowerCase().includes("national")
    ? "/prm-national"
    : "/prm-regional";
  if (store.isFollawedActive) {
    targetRoute += "/follawed";
  }
  return targetRoute;
});
</script>

<style lang="scss" scoped>
.back {
  width: fit-content;
}
</style>
