import { httpRequest } from "@/utils/request.utils";
import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import dayjs from "dayjs";

const userStore = useUserStore();
const {
  loading: topbarLoading,
  sharedFiltersAsPayload: sharedFiltersAsPayload,
  selectedInterval: topbarInterval,
} = storeToRefs(userStore);

const initialTwitterStatsFilters = {};
const initialTwitterTimeCurveTopTweetsFilters = {
  sort: { retweet: "desc" }, //* Primary sorter of the list
  size: 10,
  get_filters: false,
  highlight: true,
};

const initialTwitterTimeCurveTopArticlesFilters = {
  sort: { number_of_tweets: "desc" }, //* Primary sorter of the list
  size: 10,
  get_filters: false,
  highlight: true,
};

export const TIMELINE_INTERVALS = {
  MONTH: "month",
  DAY: "day",
  HOUR: "hour",
};

export const useLegiSocialMediaStore = defineStore("legi-social-media", () => {
  const doc_ref = ref(null); // Needs to be initialized with the store

  const totalValues = ref({});
  const timeCurve = ref({});
  const timeCurveInterval = ref(TIMELINE_INTERVALS.DAY);
  const timeCurveTopDate = ref(null);
  const timeCurveTopTweets = ref([]);
  const timeCurveTopTweetsCount = ref(null);
  const timeCurveTopArticles = ref([]);
  const timeCurveTopArticlesCount = ref(null);
  const isStakeholderStatsLoading = ref(false);
  const stakeHoldersValues = ref({});
  const levelsValues = ref({});
  const groupsRanking = ref({});
  const rankingValues = ref({});
  const filters = ref(null);
  const selectedFilters = ref({ ...initialTwitterStatsFilters });
  const timeCurveTopTweetsFilters = ref({
    ...initialTwitterTimeCurveTopTweetsFilters,
  });
  const timeCurveTopArticlesFilters = ref({
    ...initialTwitterTimeCurveTopArticlesFilters,
  });
  const tweetsList = ref([]);
  const articlesList = ref([]);
  const isTimeCurveLoading = ref(false);
  const isTimeCurveTopsLoading = ref(false);
  const isTotalFieldsLoading = ref(false);
  const isGroupsLoading = ref(false);
  const isRankingStatsLoading = ref(false);
  const isLevelsRankingLoading = ref(false);
  const isTweetsListLoading = ref(false);
  const isArticlesListLoading = ref(false);

  const createPayloadBase = () => {
    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    return payload;
  };

  const fetchTotalFields = async () => {
    isTotalFieldsLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    try {
      const response = await httpRequest(
        "post",
        `/legi-social-media/totals`,
        payload
      );
      if (response.data) {
        totalValues.value = response.data.items;
        filters.value = response.data.filters;
      }
    } catch (error) {
      totalValues.value = [];
      filters.value = null;
    }

    isTotalFieldsLoading.value = false;
  };

  const fetchTimeCurve = async () => {
    isTimeCurveLoading.value = true;

    const payload = {
      doc_ref: doc_ref.value,
      interval: timeCurveInterval.value,
      ...createPayloadBase(),
    };

    try {
      const response = await httpRequest(
        "post",
        `/legi-social-media/time_curve`,
        payload
      );
      if (response.data) {
        timeCurve.value = response.data.items.tweet_timeline;
      }
    } catch (error) {
      timeCurve.value = {};
    }

    isTimeCurveLoading.value = false;
  };

  const fetchTopList = async () => {
    isTimeCurveTopsLoading.value = true;

    let payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    const date = timeCurveTopDate.value;
    let dateTime;
    switch (timeCurveInterval.value) {
      case TIMELINE_INTERVALS.MONTH:
        dateTime = dayjs(date.toLowerCase(), "MMMM YYYY");
        payload = {
          ...payload,
          start: dateTime.format(),
          end: dateTime.endOf("month").format(),
        };
        break;
      case TIMELINE_INTERVALS.HOUR:
        dateTime = dayjs(date.toLowerCase(), "lll");
        payload = {
          ...payload,
          start: dateTime.format(),
          end: dateTime.endOf("hour").format(),
        };
        break;
      case TIMELINE_INTERVALS.DAY:
      default:
        dateTime = dayjs(date.toLowerCase(), "DD/MM/YYYY");
        payload = {
          ...payload,
          start: dateTime.format(),
          end: dateTime.endOf("day").format(),
        };
        break;
    }

    // Fetch tweets
    try {
      payload = {
        ...payload,
        ...timeCurveTopTweetsFilters.value,
      };

      delete payload.sort;
      payload.sort_field = Object.keys(timeCurveTopTweetsFilters.value.sort)[0];
      payload.sort_order = Object.values(
        timeCurveTopTweetsFilters.value.sort
      )[0];

      if (userStore.sharedFilters.selected_keyword) {
        payload = {
          ...payload,
          filter: userStore.sharedFilters.selected_keyword,
        };
      }

      const response = await httpRequest(
        "post",
        "/legi-social-media/tweets",
        payload
      );
      if (response.data) {
        timeCurveTopTweets.value = response.data.items;
        timeCurveTopTweetsCount.value = response.data.item_count;
      }
    } catch (error) {
      timeCurveTopTweets.value = [];
      timeCurveTopTweetsCount.value = null;
    }

    // Fetch articles (links)
    try {
      payload = {
        ...payload,
        ...timeCurveTopArticlesFilters.value,
      };

      delete payload.sort;
      payload.sort_field = Object.keys(
        timeCurveTopArticlesFilters.value.sort
      )[0];
      payload.sort_order = Object.values(
        timeCurveTopArticlesFilters.value.sort
      )[0];

      const response = await httpRequest(
        "post",
        "/legi-social-media/links",
        payload
      );
      if (response.data) {
        timeCurveTopArticles.value = response.data.items;
        timeCurveTopArticlesCount.value = response.data.item_count;
      }
    } catch (error) {
      timeCurveTopArticles.value = [];
      timeCurveTopArticlesCount.value = null;
    }

    isTimeCurveTopsLoading.value = false;
  };

  const resetTimeCurveTopsFilters = () => {
    timeCurveTopTweetsFilters.value = {
      ...initialTwitterTimeCurveTopTweetsFilters,
    };
    timeCurveTopArticlesFilters.value = {
      ...initialTwitterTimeCurveTopArticlesFilters,
    };
  };

  const fetchGroupsRanking = async () => {
    isGroupsLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/legi-social-media/group_ranking`,
        payload
      );
      if (response.data) {
        groupsRanking.value = response.data.items;
      }
    } catch (error) {
      groupsRanking.value = {};
    }

    isGroupsLoading.value = false;
  };

  const fetchRankingStats = async () => {
    isRankingStatsLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    //* Remove all empty arrays (filters) from the object to send to the back
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        `/legi-social-media/rankings`,
        payload
      );
      if (response.data) {
        rankingValues.value = response.data.items;
      }
    } catch (error) {
      rankingValues.value = [];
    }

    isRankingStatsLoading.value = false;
  };

  const fetchStakeholders = async () => {
    isStakeholderStatsLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    try {
      const response = await httpRequest(
        "post",
        `/legi-social-media/major_stakeholder_ranking`,
        payload
      );

      if (response.data) {
        stakeHoldersValues.value = response.data.items;
      }
    } catch (error) {
      stakeHoldersValues.value = [];
    } finally {
      isStakeholderStatsLoading.value = false;
    }
  };

  const fetchLevelsRanking = async () => {
    isLevelsRankingLoading.value = true;

    const payload = { doc_ref: doc_ref.value, ...createPayloadBase() };

    try {
      const response = await httpRequest(
        "post",
        `/legi-social-media/level_ranking`,
        payload
      );

      if (response.data) {
        levelsValues.value = response.data.items;
      }
    } catch (error) {
      levelsValues.value = {};
    } finally {
      isLevelsRankingLoading.value = false;
    }
  };

  const fetchTweetsList = async () => {
    isTweetsListLoading.value = true;

    const payload = {
      doc_ref: doc_ref.value,
      ...createPayloadBase(),
      size: 20,
      from: 0,
      sort_field: "created_at",
      sort_order: "desc",
    };

    try {
      const response = await httpRequest(
        "post",
        `/legi-social-media/tweets`,
        payload
      );

      if (response.data) {
        tweetsList.value = response.data.items;
      }
    } catch (error) {
      tweetsList.value = [];
    } finally {
      isTweetsListLoading.value = false;
    }
  };

  const fetchArticlesList = async () => {
    isArticlesListLoading.value = true;

    const payload = {
      doc_ref: doc_ref.value,
      ...createPayloadBase(),
      size: 20,
      from: 0,
    };

    try {
      const response = await httpRequest(
        "post",
        `/legi-social-media/links`,
        payload
      );

      if (response.data) {
        articlesList.value = response.data.items;
      }
    } catch (error) {
      articlesList.value = [];
    } finally {
      isArticlesListLoading.value = false;
    }
  };

  const fetchOverview = () => {
    fetchTotalFields();
    fetchTimeCurve();
    fetchGroupsRanking();
    fetchRankingStats();
    fetchStakeholders();
    fetchLevelsRanking();
    fetchTweetsList();
    fetchArticlesList();
    if (timeCurveTopDate.value) {
      fetchTopList();
    }
  };

  const addFilterItem = (event) => {
    if (selectedFilters.value[event.filter]?.includes(event.item)) {
      // Filter is already added, so skip adding it
      return;
    }

    let targetFilters = selectedFilters.value[event.filter] ?? [];
    targetFilters = [...targetFilters, event.item];

    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };

    fetchOverview();
  };

  const removeFilterItem = (event) => {
    let targetFilters = selectedFilters.value[event.filter] ?? [];

    targetFilters = targetFilters.filter((el) => el !== event.item);

    if (targetFilters.length === 0) {
      delete selectedFilters.value[event.filter];
    } else {
      selectedFilters.value = {
        ...selectedFilters.value,
        [event.filter]: targetFilters,
      };
    }

    fetchOverview();
  };

  const resetFilter = (event) => {
    delete selectedFilters.value[event];
    fetchOverview();
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialTwitterStatsFilters };
    fetchOverview();
  };

  const resetStore = () => {
    totalValues.value = {};
    rankingValues.value = {};
    stakeHoldersValues.value = {};
    filters.value = null;
    levelsValues.value = {};
    selectedFilters.value = { ...initialTwitterStatsFilters };
    isTotalFieldsLoading.value = false;
    isStakeholderStatsLoading.value = false;
    isLevelsRankingLoading.value = false;
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (
        !newTopbarLoading &&
        newTopbarInterval &&
        router.currentRoute.value.name?.includes("Social Media")
      ) {
        selectedFilters.value.from = 0;
        fetchOverview();
      }
    }
  );

  return {
    addFilterItem,
    articlesList,
    doc_ref,
    fetchLevelsRanking,
    fetchOverview,
    fetchStakeholders,
    fetchTimeCurve,
    fetchTopList,
    filters,
    groupsRanking,
    isArticlesListLoading,
    isGroupsLoading,
    isLevelsRankingLoading,
    isRankingStatsLoading,
    isStakeholderStatsLoading,
    isTimeCurveLoading,
    isTimeCurveTopsLoading,
    isTotalFieldsLoading,
    isTweetsListLoading,
    levelsValues,
    rankingValues,
    removeFilterItem,
    resetFilter,
    resetFilters,
    resetStore,
    resetTimeCurveTopsFilters,
    selectedFilters,
    stakeHoldersValues,
    timeCurve,
    timeCurveInterval,
    timeCurveTopArticles,
    timeCurveTopArticlesCount,
    timeCurveTopArticlesFilters,
    timeCurveTopDate,
    timeCurveTopTweets,
    timeCurveTopTweetsCount,
    timeCurveTopTweetsFilters,
    totalValues,
    tweetsList,
  };
});
