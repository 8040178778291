<template>
  <ScrollTop />
  <div class="card">
    <!-- Header Section -->
    <div class="head">
      <span
        >Publiée le :
        {{ dayjs(question.created_at).format("DD/MM/YYYY") }}</span
      >
      <span>Dernière mise à jour : {{ formattedUpdatedAt }}</span>
      <span>{{ question.legislature }}e legislature</span>
    </div>
    <!-- Body Section -->
    <section class="card-body">
      <div class="card-title">
        <h4 v-if="question.title" class="question">
          <i class="fa-kit fa-questions"></i>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span class="question-title" v-html="question.title"> </span>
        </h4>
        <span v-else class="no-component-data">Donnée(s) manquante(s)</span>
        <!-- Follawed-->
        <FollawButton
          :element-id="question._id"
          :feature="FOLLAWED_FEATURES.QUESTIONS"
          class="follawed-button"
        />
      </div>
      <div class="status-wrapper">
        <StatusTag :status="question.status" />
      </div>

      <div class="question-ministries-wrapper">
        <!-- Questions Author-->
        <div class="questions-author">
          <div class="author-infos-wrapper">
            <CardProfilePicture
              :picture-url="question.author.photo"
              class="picture"
              :size="50"
            />
            <span>
              <span class="name">{{ question.author.name }}</span>
              <GroupTag
                v-if="question.author.group"
                :group-color="question.author.group.color"
                class="group-tag"
              >
                {{ question.author.group.acronym }}
              </GroupTag>
            </span>
          </div>
          <span class="question-type-wrapper">
            <span class="vertical-separator"></span>
            <div class="questions-informations">
              <span>{{ capitalizeFirstLetter(question.type_question) }}</span>
              <span>n° : {{ question.number }}</span>
              <span
                ><i class="fas fa-map-marker-alt"></i>
                <span> {{ question.institution }}</span></span
              >
            </div>
          </span>
        </div>
        <!-- Ministries Infos-->
        <div class="ministries-infos">
          <!-- <span class="vertical-separator"></span> -->
          <span v-if="question.ministry_responsible" class="ministry">
            <span class="ministry-title">Ministère attributaire :</span>
            {{ question.ministry_responsible }}</span
          >
          <span v-if="question.ministry_questioned" class="ministry">
            <span class="ministry-title">Ministère intérrogé :</span>
            {{ question.ministry_questioned }}</span
          >
          <span class="source-wrapper"
            ><SourceLink :href="question.source">Source</SourceLink></span
          >
        </div>
      </div>
      <!-- Matched words -->
      <div
        v-if="question.matched_words && question.matched_words.length > 0"
        class="matched-words"
      >
        <h5 class="ministry-title">
          Mot{{ question.matched_words.length > 1 ? "s" : "" }} matché{{
            question.matched_words.length > 1 ? "s" : ""
          }}
          :
        </h5>
        <!-- eslint-disable vue/no-v-html-->
        <span
          class="matched-words-content capitalize-letter"
          v-html="listOfWords"
        ></span>
        <!-- eslint-enable vue/no-v-html-->
      </div>
      <div class="responsive-row">
        <div class="question-content typo-body-regular">
          <h5>Question</h5>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="question.question"></div>
        </div>
        <div class="response-content">
          <!-- If response and response date are available -->
          <div v-if="question.closed_at" class="response-limit">
            Réponse émise le
            {{ dayjs(question.closed_at).format("DD/MM/YYYY") }}
          </div>

          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="question.closed_at && question.response"
            class="response typo-body-regular"
            v-html="question.response"
          ></div>
          <!-- If a temporary response is available as response meaning no response date -->
          <div v-else class="response-limit">
            <span
              v-if="question.response && !question.closed_at"
              v-html="question.response"
            ></span>
            <!-- Set the created date + 2 Months as expected response -->
            <span
              >Date limite pour répondre: le
              {{
                dayjs(question.created_at).add(2, "month").format("DD/MM/YYYY")
              }}
            </span>
          </div>
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </section>
    <!-- Footer Section -->
    <section class="footer">
      <ThemeTag
        v-for="(theme, index) in question.themes"
        :key="index"
        :theme="theme"
      />
    </section>
  </div>
</template>

<script setup>
import { computed } from "vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import StatusTag from "@/components/reusable/atoms/StatusTag.vue";
import { capitalizeFirstLetter } from "@/utils/helper.utils";
import { FOLLAWED_FEATURES } from "@/stores/user";
import dayjs from "dayjs";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import ThemeTag from "@/components/reusable/atoms/ThemeTag.vue";

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
});

const formattedUpdatedAt = computed(() => {
  return props.question.updated_at
    ? dayjs(props.question.updated_at).format("DD/MM/YYYY")
    : "donnée absente";
});

const listOfWords = computed(() =>
  props.question.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
}
.head {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  border-bottom: 2px solid var(--clr-service-question);
  padding-bottom: 10px;
  color: var(--clr-s8);
}

.questions-author {
  display: flex;
  gap: 4em;
  font-size: 0.9375em; // 15px
  justify-content: space-between;

  .questions-informations {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    border-left: 1px solid var(--clr-s3);
    padding-left: 0.625em;
    .fa-map-marker-alt {
      margin-right: 0.3em;
    }
    span {
      padding-top: 0.4em;
    }
    i {
      color: var(--clr-s7);
    }
  }
  .question-type-wrapper {
    margin-left: 1.5em;
    color: var(--clr-s7);
  }
}
.author-infos-wrapper,
.question-type-wrapper {
  display: flex;
  gap: 11px;
}

.author-infos-wrapper {
  span {
    .group-tag {
      margin-top: 0.3em;
      width: max-content;
    }
    .name {
      font-weight: 300;
      color: var(--clr-s8);
      text-transform: capitalize;
      line-height: 22px;
    }
  }
}

.fa-questions {
  width: 32px;
  height: 32px;
  padding-top: 6.5px;
  font-size: 19px;
  color: white;
  text-align: center;
  border-radius: 50px;
  background-color: var(--clr-service-question);
}

.card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  container-type: inline-size;

  .card-title {
    display: flex;
    margin-top: 0.9em;
    justify-content: space-between;
    .question {
      width: 98%;
      text-align: left;
      display: flex;
      gap: 8px;

      i {
        flex: none;
        align-self: center;
      }
    }

    .question-text {
      margin-top: 0.25em;
      margin-left: 0.3em;
    }
  }
  .question-ministries-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 2em;
  }

  .responsive-row {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;

    @container (width < 1100px) {
      grid-template-columns: 1fr;
    }
  }

  .question-content,
  .response-content {
    text-align: justify;
  }

  .question-content,
  .response-content,
  .matched-words {
    color: var(--clr-n8);
    line-height: 1.5;

    .matched-words-content {
      display: block;
    }
  }

  .response-limit {
    font-weight: 600;
  }
}

.status-wrapper {
  margin: 0.5em 0 0.8em 0;
}
.ministries-infos {
  color: var(--clr-n8);
  border-left: 1px solid var(--clr-s3);
  padding-left: 0.625em;

  .ministry,
  .source-wrapper {
    margin-top: 5px;
    font-weight: 300;
    display: block;
    .ministry-title {
      color: var(--clr-s7);
      margin-right: 0.2em;
      font-weight: 500;
    }
    color: var(--clr-n8);
  }
}

.no-component-data {
  font-style: italic;
  color: var(--clr-n8);
}
.footer {
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}
</style>
