<template>
  <div class="card">
    <!---------------------------------------- Header ---------------------------------------->
    <!--  Personnal informations -->
    <span class="personnal-infos-wrapper">
      <CardProfilePicture
        :picture-url="tweet.author.photo"
        class="picture"
        :size="70"
      />
      <div class="author-infos">
        <!-- Name and twitter-id -->
        <span class="name">{{ tweet.author.name }}</span>
        <span class="handle">
          <a :href="authorTwitterUrl" target="_blank"
            >@{{ tweet.author.twitter_information?.handle_twitter }}</a
          >
        </span>

        <GroupTag
          v-if="tweet.author.group"
          :group-color="tweet.author.group.color"
        >
          {{ tweet.author.group.acronym }}
        </GroupTag>

        <PrmLink
          v-if="tweet.author.is_prm"
          :to="`/prm-national/${tweet.author._id}`"
        />
      </div>
    </span>

    <!---------------------------------------- Body ---------------------------------------->
    <div class="main">
      <div class="content typo-body-regular">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="tweetText"></p>
        <SourceLink
          v-for="url in tweetLinks"
          :key="url"
          :href="url"
          target="_blank"
        >
          {{ url }}
        </SourceLink>
      </div>

      <div class="timestamps-wrapper">
        <p>
          Tweet posté le :<span class="timestamp">{{
            getDayTimeFormat(tweet.created_at)
          }}</span
          ><SourceLink :href="sourceTweetUrl"></SourceLink>
        </p>
        <p v-if="tweet.last_update">
          Métriques mises à jour le :<span class="timestamp">{{
            getDayTimeFormat(tweet.last_update)
          }}</span>
        </p>
      </div>
    </div>

    <!---------------------------------------- Footer ---------------------------------------->
    <div v-if="!tweet.is_retweet" class="metrics">
      <div class="retweets">
        <span class="number">{{
          SINumberFormatter(tweet.number_of_retweets)
        }}</span>
        <span class="retweets-label">Retweets</span>
      </div>

      <CommentsAndLikes
        :comments="SINumberFormatter(tweet.comment)"
        :likes="SINumberFormatter(tweet.like)"
        :impressions="SINumberFormatter(tweet.impression)"
      />
    </div>
    <div v-else class="is-retweet">
      <i class="fa-solid fa-retweet fa-xl"></i>
    </div>
  </div>
</template>

<script setup>
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import PrmLink from "@/components/twitter/atoms/PrmLink.vue";
import CommentsAndLikes from "@/components/twitter/atoms/CommentsAndLikes.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { computed } from "vue";
import { getDayTimeFormat } from "@/utils/formatDate";

const props = defineProps({
  tweet: {
    type: Object,
    required: true,
  },
});

const authorTwitterUrl = computed(
  () =>
    `https://twitter.com/${props.tweet.author.twitter_information?.handle_twitter}`
);
const sourceTweetUrl = computed(
  () =>
    `https://twitter.com/${props.tweet.author.twitter_information?.handle_twitter}/status/${props.tweet.id_twitter}`
);

const linksRegex = /(https:\/\/t.co\/[a-zA-Z0-9]{10})/g;

const tweetLinks = computed(() => props.tweet.text.match(linksRegex));
const tweetText = computed(() => props.tweet.text.replace(linksRegex, ""));
</script>

<style lang="scss" scoped>
.card {
  color: black;
}
.personnal-infos-wrapper {
  display: flex;
  justify-content: flex-start;
}
.author-infos {
  margin: 10px 0 0 10px;
  display: flex;
  flex-direction: column;

  .name {
    font-weight: 600;
    overflow-wrap: anywhere;
    text-transform: capitalize;
    line-height: 22px;
  }
  .group-tag {
    align-self: flex-start;
  }
  .handle {
    margin-bottom: 4px;
  }
  a {
    margin-top: 5px;
  }
}
.main {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  flex-grow: 1;

  .content {
    margin: 10px 0;

    p {
      display: inline;
      line-height: 22px;
    }

    a {
      margin-left: 5px;
    }
  }

  .timestamps-wrapper {
    margin-top: auto;

    p {
      margin: 0;
      color: var(--clr-s7);

      &:first-child {
        margin-bottom: 5px;
      }
    }

    .timestamp {
      margin: 0 5px;
      color: var(--clr-n6);
    }
  }
}

.metrics {
  display: flex;
  justify-content: right;

  .retweets {
    flex-grow: 0.06;
    margin-top: 10px;
    display: flex;
  }
  .number {
    font-size: 1.25em;
    align-self: center;
  }
  .retweets-label {
    align-self: center;
  }
  .number,
  .retweets-label {
    margin-left: 0.24em;
  }
}

.is-retweet {
  display: flex;
  justify-content: right;
  margin: 10px 3px;
  text-align: center;
  color: var(--clr-service-twitter);
}
</style>
