<template>
  <div class="template">
    <template v-if="userSettingsStore.hasPermission('BASIC')">
      <RouterRadioButtons :options="textOptions" />
      <RouterView />
    </template>
    <template v-else>
      <FreemiumUpgradePopup :closable="false" title="Alertes et récapitulatifs">
        <p>
          La fonctionnalité “alertes” de Follaw.SV vous informe des actualités
          institutionnelles ou sociétales en temps réel ou via des
          récapitulatifs. Vous pouvez recevoir les notifications par e-mail,
          Telegram, Slack ou Teams, selon vos préférences.
        </p>
        <p>
          Recevez instantanément des informations sur les nouvelles lois et
          décisions importantes, ou optez pour des récapitulatifs quotidiens,
          hebdomadaires ou mensuels pour une vue d'ensemble structurée.
          Personnalisez vos alertes en fonction de mots-clés, dossiers
          spécifiques et sources.
        </p>
        <p>
          Les alertes de Follaw.SV vous permettent de gagner du temps, de rester
          à jour et de réagir rapidement, tout en s'adaptant à vos besoins.
        </p>
      </FreemiumUpgradePopup>
    </template>
  </div>
</template>

<script setup>
import { RouterView, useRouter } from "vue-router";

import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { useUserSettingsStore } from "@/stores/user-settings";
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";

const router = useRouter();
const userSettingsStore = useUserSettingsStore();

// Build tabs navbar options from router config
const routerOptions = router
  .getRoutes()
  .find((route) => route.name === "Alerting").children;

const iconMapping = {
  "Alerting - Alertes": "fa-regular fa-bell",
  "Alerting - Récapitulatifs": "fa-regular fa-alarm-clock",
};
const textOptions = routerOptions.map((route) => {
  const iconClass = iconMapping[route.name];
  return {
    label: route.name.replace("Alerting - ", ""),
    value: `/alerting/${route.path}`,
    icon: iconClass,
  };
});
</script>

<style scoped>
p {
  margin: 0;
}
</style>
