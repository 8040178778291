<template>
  <form
    ref="addOrEditForm"
    method="dialog"
    class="add-or-edit-alert"
    @submit="submit()"
  >
    <div class="infos">
      Configurez vos alertes personnelles pour recevoir les nouveaux éléments de
      votre veille dans le canal de votre choix.
    </div>

    <div class="field">
      <label for="name">Nom : *</label>

      <input
        id="name"
        v-model="formData.name"
        data-1p-ignore
        type="text"
        required
      />
      <span v-if="!isFormValid && !formData.name" class="error"
        >Un nom est requis</span
      >
    </div>
    <div class="field">
      <label for="dashboard">Veille : *</label>
      <SelectFilter
        v-model="formData.dashboard_id"
        name="Choisir une veille"
        :items="userStore.dashboards"
        item-label="name"
        item-value="_id"
        mode="form-input"
      >
      </SelectFilter>
      <span v-if="!isFormValid && !formData.dashboard_id" class="error"
        >Une veille est requise</span
      >
    </div>
    <div class="field">
      <label for="additional-request"
        >Requête supplémentaire : <span class="tip">(optionnel)</span></label
      >
      <textarea
        id="additional-request"
        v-model="formData.additional_query"
      ></textarea>
    </div>
    <div class="field">
      <label for="service"
        >Services Concernés : * <span class="tip">(au moins un)</span></label
      >
      <div class="services-wrapper">
        <CheckBox
          v-for="(service, index) in selectableServices"
          :key="index"
          :checked="formData.alert_services.includes(service.name)"
          @change.stop="
            () =>
              formData.alert_services.includes(service.name)
                ? removeFilter(service.name)
                : addFilter(service.name)
          "
        >
          {{ service.displayName }}
        </CheckBox>
      </div>
      <span
        v-if="!isFormValid && !formData.alert_services.length > 0"
        class="error"
        >Un service est requis</span
      >
    </div>
    <div class="field">
      <label for="notification-types">Canal :</label>
      <div class="default-channel">
        <CheckBox
          :checked="formData.notification_type === 'default'"
          @click="
            formData.notification_type === 'default'
              ? (formData.notification_type = 'email')
              : (formData.notification_type = 'default')
          "
        >
          Envoyer l'alerte au canal de préférence
        </CheckBox>
        <p>
          Si vous changez de canal par défaut cette alerte sera envoyée au
          nouveau canal paramétré.
        </p>
      </div>
      <div v-if="formData.notification_type !== 'default'">
        <p>Paramétrer un canal spécifique:</p>
        <CheckboxRadioGroup
          v-model="formData.notification_type"
          :options="notificationTypes"
        />
        <span v-if="!isFormValid && !formData.notification_type" class="error"
          >Un canal est requis</span
        >
      </div>
    </div>
    <div v-if="formData.notification_type === 'telegram'" class="field">
      <label for="channel_id">ID de canal Télégram : *</label>
      <input
        id="channel_id"
        v-model="formData.telegram_channel_id"
        type="text"
      />
      <div class="telegram-instructions">
        <div class="instructions-text">
          <i class="fa-regular fa-circle-info"></i>
          Vous pouvez retrouver l’ID sur le canal FollawBot en invoquant la
          commande suivante: "/recevoirID".
          <p>Scannez le qr code ci-contre pour un accès rapide au canal.</p>
        </div>
        <TelegramQrCode></TelegramQrCode>
      </div>
      <span v-if="!isFormValid && !formData.telegram_channel_id" class="error"
        >Un id de canal est requis si le canal choisi est Télégram</span
      >
    </div>

    <div v-if="formData.notification_type === 'slack'" class="field">
      <div class="label-row">
        <label for="slack-webhook">Webhook Slack : *</label>
        <a
          class="more-info"
          href="https://docs.google.com/document/d/1ggGyt41o3QekpkPJsSj-ENbaoiRectyo4HE5upFn2yU/edit?usp=sharing"
          rel="noopener noreferrer"
          target="_blank"
          title="Comment obtenir un webhook"
        >
          <i class="fa-regular fa-circle-info"></i>
        </a>
      </div>
      <input id="slack-webhook" v-model="formData.slack_webhook" type="text" />
      <span v-if="!isFormValid && !formData.slack_webhook" class="error"
        >Un webhook est requis si le canal choisi est Slack</span
      >
    </div>

    <div v-if="formData.notification_type === 'msteams'" class="field">
      <div class="label-row">
        <label for="msteams-webhook">Webhook Teams : *</label>
        <a
          class="more-info"
          href="https://learn.microsoft.com/fr-be/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=newteams%2Cdotnet#create-an-incoming-webhook"
          rel="noopener noreferrer"
          target="_blank"
          title="Comment obtenir un webhook"
        >
          <i class="fa-regular fa-circle-info"></i>
        </a>
      </div>
      <input
        id="msteams-webhook"
        v-model="formData.msteams_webhook"
        type="text"
      />
      <span v-if="!isFormValid && !formData.msteams_webhook" class="error">
        Un webhook est requis si le canal choisi est Teams
      </span>
    </div>

    <div class="field">
      <label for="active">Active :</label>
      <SwitchSlide
        :is-active="formData.active"
        @toggle="formData.active = !formData.active"
      />
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import CheckboxRadioGroup from "@/components/reusable/molecules/CheckboxRadioGroup.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import SelectFilter from "@/components/reusable/atoms/SelectFilter.vue";
import { useAlertsStore } from "@/stores/alerts";
import { useUserStore } from "@/stores/user";
import TelegramQrCode from "@/components/alerting/atoms/TelegramQrCode.vue";

const store = useAlertsStore();
const userStore = useUserStore();

const props = defineProps({
  targetedAlert: {
    type: Object,
    default: null,
  },
});

const services = ref([
  { name: "event", displayName: "Événements" },
  { name: "jorf", displayName: "Jorf" },
  { name: "petition", displayName: "Pétitions" },
  { name: "news", displayName: "Actualités" },
  { name: "legislation", displayName: "Dossiers législatifs" },
  { name: "amendment", displayName: "Amendements" },
  { name: "question", displayName: "Questions" },
  { name: "compte-rendu", displayName: "Comptes-rendus" },
  { name: "tweet", displayName: "Twitter" },
  { name: "articles", displayName: "Articles" },
]);

const selectableServices = computed(() =>
  services.value.filter((service) =>
    store.filters.alert_services?.includes(service.name)
  )
);

const notificationTypes = ref([
  { value: "email", text: "E-mail" },
  { value: "telegram", text: "Télégram" },
  { value: "slack", text: "Slack" },
  { value: "msteams", text: "Teams" },
]);

const formData = ref({
  name: "",
  dashboard_id: "",
  additional_query: "",
  alert_services: [],
  notification_type: "email",
  telegram_channel_id: "",
  slack_webhook: "",
  msteams_webhook: "",
  active: false,
});

const isFormValid = ref(true);

const validate = () => {
  isFormValid.value =
    formData.value.name &&
    formData.value.dashboard_id &&
    formData.value.alert_services.length > 0 &&
    formData.value.notification_type; //shouldn't be needed as a radio shouldn't be empty but never too sure
  if (
    (formData.value.notification_type === "slack" &&
      !formData.value.slack_webhook) ||
    (formData.value.notification_type === "msteams" &&
      !formData.value.msteams_webhook) ||
    (formData.value.notification_type === "telegram" &&
      !formData.value.telegram_channel_id)
  ) {
    isFormValid.value = false;
  }
  return isFormValid.value;
};

onMounted(() => {
  if (props.targetedAlert) {
    formData.value.name = props.targetedAlert.name;
    formData.value.dashboard_id = props.targetedAlert.dashboard_id;
    formData.value.additional_query = props.targetedAlert?.additional_query;
    formData.value.alert_services = [...props.targetedAlert.alert_services];
    formData.value.notification_type = props.targetedAlert.notification_type;
    formData.value.active = props.targetedAlert.active;
    formData.value.telegram_channel_id =
      props.targetedAlert.telegram_channel_id;
    formData.value.slack_webhook = props.targetedAlert.slack_webhook;
    formData.value.msteams_webhook = props.targetedAlert.msteams_webhook;
  }
});

const addFilter = (name) => {
  formData.value.alert_services.push(name);
};
const removeFilter = (name) => {
  formData.value.alert_services = formData.value.alert_services.filter(
    (service) => service !== name
  );
};

const submit = () => {
  if (isFormValid.value) {
    if (formData.value.notification_type !== "telegram") {
      delete formData.value.telegram_channel_id;
    }

    if (formData.value.notification_type !== "slack") {
      delete formData.value.slack_webhook;
    }

    if (formData.value.notification_type !== "msteams") {
      delete formData.value.msteams_webhook;
    }

    if (props.targetedAlert) {
      store.updateAlert(props.targetedAlert._id, formData.value);
    } else {
      store.createAlert(formData.value);
    }
  }
};

const addOrEditForm = ref(null);

defineExpose({
  form: addOrEditForm,
  validate,
});
</script>

<style lang="scss" scoped>
.infos {
  width: 100%;
  display: flex;

  align-items: center;
  gap: 8px;
}

.add-or-edit-alert {
  width: 467px;
  .field {
    display: flex;
    flex-direction: column;
    gap: 5px;

    margin-top: 1rem;

    .tip {
      color: var(--clr-n8);
      font-style: italic;
      font-size: 0.938em;
    }

    input[type="text"],
    textarea {
      border: 1px solid var(--clr-p2);
      padding: 4px 10px;
      border-radius: 5px;
      background-color: var(--clr-n2);
    }

    textarea {
      height: 90px;
      resize: vertical;
    }

    .services-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 4px;
      max-height: 100px;
    }
  }

  .error {
    margin-left: 10px;
    color: var(--clr-e300);
    font-style: italic;
    font-size: 0.875em;
  }

  .telegram-instructions {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .instructions-text {
      text-align: left;
      line-height: 19.1px;
      span {
        font-weight: var(--fw-bold);
      }
    }
    img {
      height: 100px;
    }
  }
}

.label-row {
  display: flex;
  justify-content: space-between;
}

.default-channel {
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    margin: 0;
    font-size: var(--size-small);
  }
}
</style>
