<template>
  <div class="prm card">
    <section class="head">
      <GroupTag v-if="prospect.group" :group-color="prospect.group.color">
        {{ prospect.group.acronym }}
      </GroupTag>
      <GenericTag>{{ prospect.stakeholder }}</GenericTag>
    </section>

    <section class="body">
      <section class="infos-and-follaw">
        <CardProfilePicture
          :picture-url="prospect.photo"
          class="picture"
          :size="70"
        />
        <div class="prospect-infos">
          <p class="name">{{ prospect.name }}</p>
          <p class="circonspection">{{ prospect.circo_name }}</p>
          <p v-if="prospect.locality" class="locality">
            {{ prospect.locality }}
          </p>
        </div>
        <FollawButton
          :element-id="prospect._id"
          :feature="FOLLAWED_FEATURES.PRM"
          class="follaw"
        />
      </section>

      <RouterLink
        class="stats card-hover"
        :to="`/prm-national/${store.prmZoom._id}/activities`"
      >
        <div class="tweets">
          <span class="number">{{
            SINumberFormatter(prospect.number_of_tweets)
          }}</span>
          <p>
            Tweet<template v-if="prospect.number_of_tweets > 1">s</template>
          </p>
        </div>

        <div class="activity">
          <span class="number">
            {{ SINumberFormatter(prospect.number_of_activities) }}
          </span>
          <p>
            Activité<template v-if="prospect.number_of_activities > 1"
              >s</template
            >
            parlementaire<template v-if="prospect.number_of_activities > 1"
              >s</template
            >
          </p>
        </div>
      </RouterLink>

      <section class="additional-infos">
        <p v-if="prospect.profession">
          <span class="label">Profession&nbsp;:</span>
          <span class="info">{{ prospect.profession }}</span>
        </p>
        <p v-if="prospect.birth_date">
          <span class="label">Naissance&nbsp;:</span>
          <span class="info date">{{
            getDateLongFormat(prospect.birth_date)
          }}</span>
          <span class="info"> ({{ age }})</span>
        </p>
        <div v-if="prospect.membre_de" class="member-of">
          <span class="label">Membre de&nbsp;:</span>
          <ul class="info member-of-list">
            <li v-for="(item, index) in prospect.membre_de" :key="index">
              <a :href="item.url" target="_blank" rel="noopener noreferrer">{{
                item.name
              }}</a>
            </li>
          </ul>
        </div>
      </section>
    </section>
  </div>
</template>

<script setup>
import { computed } from "vue";
import dayjs from "dayjs";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { getDateLongFormat } from "@/utils/formatDate";
import { FOLLAWED_FEATURES } from "@/stores/user";
import { usePrmStore } from "@/stores/prm";

const store = usePrmStore();

const props = defineProps({
  prospect: {
    type: Object,
    required: true,
  },
});

const age = computed(() => {
  return dayjs(props.prospect.birth_date).toNow(true);
});
</script>

<style lang="scss" scoped>
.prm {
  height: fit-content;
  line-height: 21.82px;
}

.head {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--clr-s2);
  padding-bottom: 5px;
  text-transform: capitalize;

  .stakeholder {
    text-transform: capitalize;
  }
}

.body {
  .infos-and-follaw {
    display: flex;
    gap: 10px;

    .prospect-infos {
      display: flex;
      flex-direction: column;

      p {
        margin: 1px;
        line-height: 22px;
      }

      .name {
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    .follaw {
      margin-left: auto;
    }
  }

  .stats {
    display: flex;
    justify-content: space-around;
    margin-top: 11px;
    width: 100%;

    .tweets,
    .activity {
      text-align: center;
      color: var(--clr-s7);
      font-size: 0.938em;

      p {
        margin: 0;
      }
    }

    .number {
      color: var(--clr-n8);
      font-size: 1.25em;
    }
  }

  .additional-infos {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;

    p {
      margin: 0;
    }

    .label {
      white-space: nowrap;
      margin-right: 10px;
      font-size: 0.875em;
      font-weight: 600;
    }

    .info {
      font-size: 0.938em;
    }

    .date {
      text-transform: capitalize;
    }

    .member-of {
      display: flex;

      .member-of-list {
        display: inline-flex;
        flex-direction: column;
        gap: 10px;
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }
  }
}
</style>
