<template>
  <LoaderFullPage v-if="Object.keys(politicalMap).length === 0" />
  <NoData v-else-if="!orderedNonEmptyGroups?.length" />
  <div v-else class="columns-wrapper">
    <StakeholdersMappingColumn
      v-for="groupName in orderedNonEmptyGroups"
      :key="groupName"
      :column-data="politicalMap[groupName]"
      :metric="mapMetric"
      :show-group-tags="false"
    >
      <template #header>
        <GroupTag :group-color="getColorForGroup(groupName)">{{
          groupName
        }}</GroupTag>
      </template>
    </StakeholdersMappingColumn>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";

import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";

import { useTwitterStakeholdersStore } from "@/stores/twitter-stakeholders";
import { getSortedNonEmptyColumns } from "@/utils/stakeholder_mapping";
import StakeholdersMappingColumn from "../molecules/StakeholdersMappingColumn.vue";

const store = useTwitterStakeholdersStore();

const { filters, politicalMap, mapMetric } = storeToRefs(store);

const orderedNonEmptyGroups = computed(() =>
  getSortedNonEmptyColumns(
    politicalMap.value,
    filters.value.group,
    mapMetric.value
  )
);

function getColorForGroup(groupName) {
  if (politicalMap.value[groupName].length === 0) {
    return "gray";
  }
  return politicalMap.value[groupName][0].group.color;
}

store.fetchPoliticalMapping();
</script>

<style scoped lang="scss">
$bodyHeaderHeight: 102px;

.columns-wrapper {
  display: flex;
  gap: 5px;

  width: 100%;
  height: calc(100vh - var(--top-menu-height) - $bodyHeaderHeight - 28px);
  overflow-x: auto;
}
</style>
