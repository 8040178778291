<template>
  <RouterLink :to="routingUrl">
    <div class="card card-hover">
      <div class="header">
        {{ getDayTimeFormat(report.date_depot || report.created_at) }}
      </div>
      <div class="body">
        <div class="title-row">
          <InstitutionBadge :institution="report.institution" />
          <div class="title">{{ report.title }}</div>
        </div>

        <div v-if="hasMatchedWords">
          <span class="matched-title">Mots matchés :</span>
          {{ report.matched_words.join(", ") }}
        </div>
      </div>
    </div>
  </RouterLink>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import InstitutionBadge from "@/components/legi-work/atoms/InstitutionBadge.vue";
import { getDayTimeFormat } from "@/utils/formatDate";

const route = useRoute();

const props = defineProps({
  report: { type: Object, required: true },
});

const hasMatchedWords = computed(
  () => Object.keys(props.report.matched_words || {}).length > 0
);

const routingUrl = computed(() => {
  let baseRoute = "";

  if (route.fullPath.includes("lobbies")) {
    baseRoute = "/lobbies";
    if (route.fullPath.includes("follawed")) {
      baseRoute += "/follawed";
    } else {
      baseRoute += `/${route.params._id}`;
    }
  }

  if (props.report.data_type === "rapport") {
    baseRoute += "/reports";
  } else {
    baseRoute += "/comptes-rendus";
  }

  return `${baseRoute}/${encodeURIComponent(props.report.ref)}`;
});
</script>

<style scoped lang="scss">
.card {
  height: 100%;
  color: var(--clr-s8);
}

.title-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  .title {
    font-weight: 600;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.matched-title {
  color: var(--clr-s7);
  font-weight: 600;
}
</style>
