<template>
  <div class="card">
    <section class="header">
      <span class="date">{{
        getDateShortFormat(props.nomination.created_at)
      }}</span>
    </section>
    <section class="body">
      <!-- eslint-disable vue/no-v-html  -->
      <div class="title">
        <div class="company" v-html="props.nomination.company_affected"></div>
      </div>

      <span class="personnality-title" v-html="props.nomination.title"> </span>
      <div class="author">
        <span v-html="props.nomination.personality"></span>
      </div>
      <div class="description content typo-body-regular">
        <p v-html="props.nomination.text"></p>
      </div>
      <!-- eslint-enable vue/no-v-html  -->
    </section>
    <section class="footer">
      <span class="badge">{{ props.nomination.themes }}</span>
    </section>
  </div>
</template>

<script setup>
import { getDateShortFormat } from "@/utils/formatDate";

const props = defineProps({
  nomination: {
    type: Object,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
.company {
  text-transform: uppercase;
  color: var(--clr-n8);
  font-weight: 600;
}
.personnality-title {
  color: var(--clr-s7);
  font-weight: 600;
  text-transform: capitalize;
}

.header {
  .date {
    color: var(--clr-n8);
    font-size: 16px;
    font-weight: 300;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .description {
    font-size: 16px;
    font-weight: 400;
    color: var(--clr-n8);

    :deep(p) {
      margin: 0;
    }
  }

  .author {
    align-items: center;
    display: flex;
    font-weight: 600;
    margin-top: 5px;
    color: var(--clr-n8);
    font-size: 0.938em;
    text-transform: capitalize;

    img {
      margin-right: 10px;
      margin-left: 0;
      box-shadow: var(--shd-card);
      border-radius: 100%;
      width: 25px;
      min-width: 25px;
      height: 25px;
    }
  }
}

.footer {
  position: relative;
  justify-content: end;
  height: 1.2625em;

  .badge {
    position: absolute;
    top: 0em;
    left: 0em;
    height: 1.25em;
    font-weight: 300;
    padding: 0.1em 0.5em 0.1em 0.5em;
    font-size: 0.9375em;
    background-color: var(--clr-s2);
    color: var(--clr-s8);
    border-radius: 5px;
    text-transform: capitalize;
  }
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body {
  flex-grow: 1;
}

.footer {
  margin-top: 8px;
}
</style>
