<template>
  <LastActivitiesParams />

  <LoaderFullPage v-if="store.loadingActivities" />
  <template v-else>
    <ul class="activities-list">
      <li v-for="elem in store.activities" :key="elem._id">
        <AmendmentCard
          v-if="elem.type === 'amendment'"
          :amendment="elem"
          :to="`/amendments/${elem._id}`"
        />
        <QuestionCard
          v-else-if="elem.type === 'question'"
          :question="elem"
          :to="`/questions/${elem._id}`"
        />
        <LegiWorkCard v-else-if="elem.type === 'legi-doc'" :legi-work="elem" />
        <ReportCard
          v-else-if="
            elem.type === 'report' ||
            elem.type === 'rapport' ||
            elem.type === 'legi-text'
          "
          :report="elem"
        />
        <div v-else>{{ elem.type }}</div>
      </li>
    </ul>

    <PaginationElement
      v-if="store.activitiesCount"
      :key="`${store.activitiesCount}${store.activitiesFilters.from}${store.activitiesFilters.size}`"
      :total-items="store.activitiesCount"
      :current-page="store.activitiesFilters.from"
      :items-limit="store.activitiesFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
</template>

<script setup>
import { useRoute } from "vue-router";

import LastActivitiesParams from "@/components/institu-groups/organisms/LastActivitiesParams.vue";
import AmendmentCard from "@/components/amendments/molecules/AmendmentCard.vue";
import QuestionCard from "@/components/questions/atoms/QuestionCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import LegiWorkCard from "@/components/legi-work/molecules/LegiWorkCard.vue";
import ReportCard from "@/components/legi-reports/molecules/ReportCard.vue";
const store = useInstituGroupsStore();

const route = useRoute();

const changeCurrentPage = (from) => {
  store.activitiesFilters.from = from;
  store.fetchActivities(route.params._id);
};

store.fetchActivities(route.params.missionId || route.params._id);
</script>

<style scoped lang="scss">
.activities-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @container (width > 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (width > 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
