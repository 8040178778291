<template>
  <div class="content-wrapper">
    <div class="top-tweets">
      <OverviewTopTweets
        :is-loading="statsStore.isTotalFieldsLoading"
        :tweets-count="statsStore.totalValues.tweets_count"
        :reweets-count="statsStore.totalValues.retweets_count"
        :impressions-count="statsStore.totalValues.impression_count"
        @click-icon="() => router.push('/twitter-tweets')"
      />
    </div>
    <div class="top-authors">
      <OverviewTopAuthors
        :is-loading="statsStore.isTotalFieldsLoading"
        :authors-count="statsStore.totalValues.authors_count"
        :authors-percent="authorsPercentOfPanel"
        @click-icon="() => router.push('/twitter-stakeholders')"
      />
    </div>
    <div class="top-articles">
      <OverviewTopArticles
        :is-loading="statsStore.isTotalFieldsLoading"
        :articles-count="statsStore.totalValues.articles_count"
        @click-icon="() => router.push('/twitter-articles')"
      />
    </div>

    <div class="timeline-tweets">
      <OverviewTimeCurve :store="statsStore" />
    </div>
    <!-- Stakeholder -->
    <div class="stakeholder-graph">
      <div class="words-ranking">
        <OverviewStakeholdersGraphs
          :is-loading="statsStore.isStakeholderStatsLoading"
          :items="statsStore.stakeHoldersValues"
        />
      </div>
    </div>
    <!-- Levels Ranking -->
    <div class="levels-ranking">
      <OverviewLevelsChart
        :is-loading="statsStore.isLevelsRankingLoading"
        :items="statsStore.levelsValues"
      />
    </div>

    <div class="hemicycle-groups">
      <OverviewGroupsRanking
        :is-loading="statsStore.isGroupsLoading"
        :groups="statsStore.groupsRanking"
      />
    </div>

    <div class="domains-ranking">
      <OverviewGenericRanking
        color="var(--clr-service-article)"
        icon="fa-kit fa-sv-articles"
        :is-loading="statsStore.isRankingStatsLoading"
        :items="statsStore.rankingValues?.top_domains"
        name="Domaines"
        @click-item="goToExternalUrl"
      />
    </div>
    <div class="hashtag-ranking">
      <OverviewGenericRanking
        color="var(--clr-twitter-hashtag)"
        icon="fa-solid fa-hashtag"
        :is-loading="statsStore.isRankingStatsLoading"
        :items="statsStore.rankingValues?.top_hashtags"
        name="Hashtags"
        @click-item="goToTweet"
      />
    </div>
    <div class="words-ranking">
      <OverviewGenericRanking
        color="var(--clr-twitter-words)"
        icon="fa-kit fa-sv-mots"
        :is-loading="statsStore.isRankingStatsLoading"
        :items="statsStore.rankingValues?.top_words"
        name="Mots"
        @click-item="goToTweet"
      />
    </div>
    <div class="mentions-ranking">
      <OverviewGenericRanking
        color="var(--clr-twitter-mentions)"
        icon="fa-solid fa-at"
        :is-loading="statsStore.isRankingStatsLoading"
        :items="statsStore.rankingValues?.top_mentions"
        name="Mentions"
        @click-item="goToTweet"
      />
    </div>

    <div class="wordcloud">
      <OverviewWordcloud
        :is-loading="statsStore.isLevelsRankingLoading"
        :ranking-values="statsStore.rankingValues"
        @click-word="goToTweet"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useTwitterStatsStore } from "@/stores/twitter-stats";
import OverviewGroupsRanking from "../molecules/OverviewGroupsRanking.vue";
import OverviewTopTweets from "../molecules/OverviewTopTweets.vue";
import OverviewTopAuthors from "../molecules/OverviewTopAuthors.vue";
import OverviewTopArticles from "../molecules/OverviewTopArticles.vue";
import OverviewTimeCurve from "../organisms/OverviewTimeCurve.vue";
import OverviewGenericRanking from "../molecules/OverviewGenericRanking.vue";
import OverviewStakeholdersGraphs from "../molecules/OverviewStakeholdersGraphs.vue";
import OverviewLevelsChart from "../molecules/OverviewLevelsChart.vue";
import OverviewWordcloud from "../molecules/OverviewWordcloud.vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useTweetsStore } from "@/stores/tweets";
const statsStore = useTwitterStatsStore();
const router = useRouter();

const userStore = useUserStore();

const tweetsStore = useTweetsStore();

const authorsPercentOfPanel = computed(() =>
  Math.round(
    (100 * statsStore.totalValues.authors_count) /
      statsStore.totalValues.authors_total
  )
);

const goToExternalUrl = (url) => {
  if (!url.includes("//")) {
    url = `//${url}`;
  }
  window.open(url);
};

const goToTweet = (word) => {
  userStore.searchBoxCurrentValue = word;
  userStore.confirmSearchBox();
  tweetsStore.fetchTweetsList().then(() => {
    router.push(`/twitter-tweets/?search=${word}`);
  });
};
</script>

<style scoped lang="scss">
.content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

@container (width < 875px) {
  .content-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.timeline-tweets,
.hemicycle-groups,
.wordcloud {
  grid-column: span 6;
}

.top-tweets,
.stakeholder-graph,
.levels-ranking,
.domains-ranking,
.hashtag-ranking,
.words-ranking,
.mentions-ranking {
  grid-column: span 3;
}

.top-authors {
  grid-column: span 2;
}

.top-articles {
  grid-column: span 1;
}
</style>
