<template>
  <NoData v-if="store.reportsCount === 0" />
  <template v-else>
    <ul class="reports-list">
      <li v-for="report in store.reports" :key="report._id">
        <ReportCard :report="report" />
      </li>
    </ul>

    <!-- Pagination -->
    <PaginationElement
      v-if="store.reportsCount"
      :key="`${store.reportsCount}${store.selectedFilters.from}${store.selectedFilters.size}`"
      :total-items="store.reportsCount"
      :current-page="store.selectedFilters.from"
      :items-limit="store.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
</template>

<script setup>
import ReportCard from "@/components/legi-reports/molecules/ReportCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import { useLegiReportStore } from "@/stores/legi-reports";
const store = useLegiReportStore();

const changeCurrentPage = (from) => {
  store.selectedFilters.from = from;
  store.fetchLegiReportsList();
};
</script>

<style scoped lang="scss">
.reports-list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @container (width > 500px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (width > 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @container (width > 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
