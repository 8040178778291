<template>
  <div class="card">
    <h4 class="header">Ministères les plus interrogés</h4>
    <ul v-if="orderedRanking.length > 0" class="ranking">
      <li v-for="entry in orderedRanking" :key="entry.key">
        <div class="entry-label">{{ entry.key }}</div>
        <div class="entry-value">{{ entry.val }} fois</div>
      </li>
    </ul>
    <p v-else>Pas de questions correspondant à vos critères</p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({ ranking: { type: Object, required: true } });

// Return an array of objects { key: <Ministry name>, val: <Nb of questions> }
const orderedRanking = computed(() =>
  Object.keys(props.ranking)
    .sort((key1, key2) => props.ranking[key2] - props.ranking[key1])
    .map((key) => ({ key, val: props.ranking[key] }))
);
</script>

<style scoped lang="scss">
.card {
  height: 100%;
}

.header {
  padding-top: 10px;
  justify-content: flex-start;
  border-bottom: 2px solid var(--color-questions);
}

ul.ranking {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    padding: 10px 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-p2);
    }

    .entry-value {
      font-size: 1.1rem;
      color: var(--color-questions);
    }
  }
}
</style>
