<template>
  <div class="wrapper">
    <div class="occurrences-table-content">
      <OccurrencesBox
        :occurrences="occurrences || {}"
        :show-filter-text="true"
        :filter-text="filterHighlights"
        @toggle-filter="filterHighlights = !filterHighlights"
      />
      <!-- Pass the entire content if partie is not present -->
      <TableOfContent
        :content="content?.partie || content"
        :occurrences="occurrences"
      />
    </div>

    <div class="card text typo-body-regular">
      <RecursiveTextContent
        v-if="content && Object.keys(content)?.length > 0"
        :content="content"
        :embed-amendments="embedAmendments"
        :only-show-highlight="filterHighlights"
        :levels="LEGIDOC_LEVELS"
      />
      <div v-else>Texte non récupéré</div>
    </div>
  </div>
</template>

<script setup>
import { LEGIDOC_LEVELS } from "@/utils/recursive-texts";
import OccurrencesBox from "../atoms/OccurrencesBox.vue";
import RecursiveTextContent from "@/components/reusable/molecules/RecursiveTextContent.vue";
import TableOfContent from "../atoms/TableOfContent.vue";
import { ref } from "vue";

defineProps({
  content: { type: Object, required: true },
  embedAmendments: { type: Array, default: () => [] },
  occurrences: { type: Object, required: true },
});

const filterHighlights = ref(false);
</script>

<style scoped lang="scss">
.wrapper {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 10px;
  height: 100%;
}

.occurrences-table-content {
  position: sticky;
  top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 98px);
  min-width: 300px;
}

.text {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}
</style>
