<template>
  <SocialsViewWrapper>
    <TwitterArticlesParams />
    <LoaderFullPage v-if="store.loading" />
    <TwitterArticlesDatas v-else />
  </SocialsViewWrapper>
</template>

<script setup>
import SocialsViewWrapper from "@/components/twitter/templates/SocialsViewWrapper.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import TwitterArticlesDatas from "@/components/twitter/organisms/TwitterArticlesDatas.vue";
import TwitterArticlesParams from "@/components/twitter/organisms/TwitterArticlesParams.vue";
import { useTwitterArticlesStore } from "@/stores/twitter-articles";
import { useUserSettingsStore } from "@/stores/user-settings";

const store = useTwitterArticlesStore();
const userSettingsStore = useUserSettingsStore();

if (userSettingsStore.hasPermission("SOCIETAL")) {
  store.selectedFilters.from = 0;
  store.fetchTwitterArticlesList();
}
</script>
