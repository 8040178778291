<template>
  <div class="template">
    <FloatingBrowseOccurrences v-if="hasHighlights" />

    <LoaderFullPage v-if="loadingFeuilleVerte" />
    <div v-else class="feuille-verte">
      <div class="card">
        <div class="header-name">
          <h4>
            Ordre du jour des séances publiques de l'Assemblée Nationale
            (feuille verte)
          </h4>
          <FollawButton
            :feature="FOLLAWED_FEATURES.FEUILLE_VERTE"
            :element-id="feuilleVerteId"
          />
        </div>
        <p>Mise à jour: {{ getDayTimeFormat(feuilleVerteLastUpdate) }}</p>
        <p><BaseLink :href="feuilleVerteUrl">Source</BaseLink></p>
        <OccurrencesCollapse
          v-if="feuilleVerteOccurrences"
          :occurrences="feuilleVerteOccurrences"
        />
      </div>

      <div class="card typo-body-regular">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="feuilleVerte"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
// The "Feuille Verte" is a special document
// It is actually the Compte-Rendu of the Conference des Présidents of the AN
// Because of its significance, it is highlighted in its own section within the Follaw side-bar

// Note: this view's responsibility is limited to showing a single document,
// so we are including the store logic in the component setup code to keep it simple
// (If it gets too complex, we can always split the logic to its own store file)

import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";

import { httpRequest } from "@/utils/request.utils";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { getDayTimeFormat } from "@/utils/formatDate";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import { useUserStore } from "@/stores/user";
import FloatingBrowseOccurrences from "@/components/reusable/atoms/FloatingBrowseOccurrences.vue";
import OccurrencesCollapse from "@/components/reusable/atoms/OccurrencesCollapse.vue";
import { handleNetworkError } from "@/utils/handleNetworkError";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";

const userStore = useUserStore();

const loadingFeuilleVerte = ref(true);
const feuilleVerteId = ref(null);
const feuilleVerte = ref(null);
const feuilleVerteLastUpdate = ref(null);
const feuilleVerteUrl = ref(null);
const feuilleVerteOccurrences = ref({});
const {
  loading: topbarLoading,
  sharedFiltersAsPayload: topbarPayload,
  selectedInterval: topbarInterval,
} = storeToRefs(userStore);

const hasHighlights = computed(() =>
  feuilleVerte.value?.includes("mark class")
);

async function loadFeuilleVerte() {
  loadingFeuilleVerte.value = true;
  try {
    const res = await httpRequest(
      "post",
      "/feuille_verte",
      topbarPayload.value
    );
    feuilleVerteId.value = res.data._id;
    feuilleVerte.value = res.data.content;
    feuilleVerteLastUpdate.value = res.data.last_update;
    feuilleVerteUrl.value = res.data.url;
    feuilleVerteOccurrences.value = res.data.occurrences;
  } catch (err) {
    handleNetworkError(err);
  } finally {
    loadingFeuilleVerte.value = false;
  }
}

loadFeuilleVerte();

watch(
  [topbarLoading, topbarInterval],
  (newTopbarLoading, newTopbarInterval) => {
    if (!newTopbarLoading[0] && newTopbarInterval[0]) {
      loadFeuilleVerte();
    }
  }
);
</script>

<style scoped lang="scss">
.feuille-verte {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.header-name {
  display: flex;
  justify-content: space-between;

  h4 {
    width: 80%;
  }
}
</style>
