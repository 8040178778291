<template>
  <div class="card">
    <section v-if="store.lobbyZoom.leaders">
      <h6 class="title">Dirigeants :</h6>
      <div class="leaders">
        <div class="row heads">
          <div class="bold">Identité :</div>
          <div class="bold">Fonctions exercées :</div>
        </div>
        <ul>
          <li
            v-for="(leader, index) in store.lobbyZoom.leaders"
            :key="index"
            class="row item"
          >
            <div>{{ leader.last_name }} {{ leader.first_name }}</div>
            <div>{{ leader.position }}</div>
          </li>
        </ul>
      </div>
    </section>
    <section v-if="store.lobbyZoom.collaborators">
      <h6 class="title">
        Personnes chargées de la représentation d'intérêts :
      </h6>
      <div class="collaborators">
        <div class="row heads">
          <div class="bold">Identité :</div>
          <div class="bold">Fonctions exercées :</div>
        </div>
        <ul>
          <li
            v-for="(collaborator, index) in store.lobbyZoom.collaborators"
            :key="index"
            class="row item"
          >
            <div>
              {{ collaborator.last_name }} {{ collaborator.first_name }}
            </div>
            <div>{{ collaborator.position }}</div>
          </li>
        </ul>
      </div>
    </section>
    <EmptySection
      v-if="!store.lobbyZoom.leaders && !store.lobbyZoom.collaborators"
      message="Cette organisation n'a pas de perso."
    />
  </div>
</template>

<script setup>
import EmptySection from "@/components/lobbies/molecules/EmptySection.vue";
import { useLobbiesStore } from "@/stores/lobbies";

const store = useLobbiesStore();
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.title {
  font-size: 19.2px;
  font-weight: 400;
  line-height: 24.96px;
  margin: 0 0 8px 0;
}
.bold {
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
}

.leaders,
.collaborators {
  ul {
    margin: 0;
    padding: 0;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 0;

    &.heads {
      margin-bottom: 8px;
    }

    &.item {
      margin: 4px 0;
      list-style: none;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
