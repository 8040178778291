<template>
  <RouterLink :to="`/jorfs/${props.jorf._id}`">
    <div class="card card-hover">
      <section class="header">
        <StatusTag :status="props.jorf.nature" />
        <FollawButton
          :element-id="props.jorf._id"
          :feature="FOLLAWED_FEATURES.JORFS"
        />
      </section>
      <section class="body">
        <div class="title">{{ props.jorf.title }}</div>

        <div class="issuer">{{ props.jorf.issuer }}</div>

        <div v-if="props.jorf.matched_words.length > 0" class="content">
          <span class="matched-title">Mots matchés : </span>
          <span class="matched-words">{{ listOfWords }}</span>
        </div>
      </section>
      <section class="footer">
        <SourceLink
          :href="`https://www.legifrance.gouv.fr/jorf/id/${props.jorf.jorftext_id}`"
          >{{ props.jorf.jorf_title || "Voir JORF" }}</SourceLink
        >
      </section>
    </div>
  </RouterLink>
</template>

<script setup>
import { computed } from "vue";

import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import StatusTag from "@/components/reusable/atoms/StatusTag.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";

const props = defineProps({
  jorf: {
    type: Object,
    required: true,
  },
});

const listOfWords = computed(() =>
  props.jorf.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style scoped lang="scss">
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.matched-words {
  font-weight: 300;
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    display: flex;
    color: var(--clr-n8);
    font-weight: 600;
    font-size: 16px;
  }
}

.issuer {
  font-weight: 600;
  color: var(--clr-s7);
  margin-bottom: 5px;
}

.content {
  color: var(--clr-n8);

  .matched-title {
    font-weight: 500;
    color: var(--clr-s7);
  }
}

.footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
