import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import { httpRequest } from "@/utils/request.utils";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import {
  getInstitutionsNames,
  reverseInstitutionsNames,
} from "@/utils/institutions.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialLegiReportsFilters = {
  size: 20,
  from: 0,
  get_filters: true,
};

// Defining store
export const useLegiReportStore = defineStore("legi-reports", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const toast = useToast();

  const loading = ref(false);

  const reports = ref(null);
  const selectedFilters = ref({ ...initialLegiReportsFilters });
  const filters = ref(null);
  const reportsCount = ref(0);

  const reportZoom = ref(null);
  const reportCompteRendu = ref(null);

  const fetchLegiReportsList = async (forcePayload = {}) => {
    loading.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
      ...forcePayload,
    };

    // We want to bypass the query for the Conseil des Ministres section
    if (payload.bypass_query) {
      delete payload.dashboard_id;
      delete payload.bypass_query;
    }

    // Reverse institution names in filters to backend format
    if (payload.institution) {
      payload.institution = payload.institution.map((institution) =>
        reverseInstitutionsNames(institution)
      );
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest(
        "post",
        "/legi-compte-rendus-and-rapports",
        payload
      );

      if (response.data) {
        reports.value = response.data.items.map((r) => ({
          ...r,
          // Translate instution to human-readable
          institution: getInstitutionsNames(r.institution),
        }));
        reportsCount.value = response.data.item_count;
        filters.value = response.data.filters;
        // Convert institutions to more readable format
        filters.value.institution =
          filters.value.institution.map(getInstitutionsNames);
      }
    } catch (err) {
      reports.value = [];
      reportsCount.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  // Helper function for the Conseil des Ministres section
  const fetchConseilMinistresLegiReportsList = () =>
    fetchLegiReportsList({ institution: ["Gouvernement"], bypass_query: true });

  const fetchOneCompteRendu = async (crRef) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", "/legi-compte-rendus-zoom", {
        ref: [crRef],
        highlight: true,
        ...sharedFiltersAsPayload.value,
      });

      reportCompteRendu.value = response.data;

      if (!reportCompteRendu.value._id) {
        const errMsg = `Compte-rendu avec référence ${crRef} non chargé.`;
        Sentry.captureException(new Error(errMsg));
        toast.error(errMsg);
        return;
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchOneReport = async (reportRef) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", "/legi-rapports-zoom", {
        ref: [reportRef],
        highlight: true,
        ...sharedFiltersAsPayload.value,
      });

      reportZoom.value = response.data;

      if (!response.data._id) {
        Sentry.withScope((scope) => {
          scope.setExtra("reportRef", reportRef);
          Sentry.captureException(
            new Error("API legi-rapports-zoom did not return a report.")
          );
        });

        toast.error(`Rapport avec référence ${reportRef} non chargé.`);
        return;
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialLegiReportsFilters };
  };

  const resetStore = () => {
    reports.value = [];
    selectedFilters.value = { ...initialLegiReportsFilters };
    filters.value = null;
    loading.value = false;

    reportsCount.value = 0;
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      const routePath = router.currentRoute.value?.path;
      const routeName = router.currentRoute.value?.name;
      if (!newTopbarLoading && newTopbarInterval) {
        if (routePath === "/reports") {
          fetchLegiReportsList();
        }

        if (routePath === "/conseil-ministres") {
          fetchConseilMinistresLegiReportsList();
        }

        if (routePath.includes("/reports/") && routeName.includes("Zoom")) {
          fetchOneReport(router.currentRoute.value.params.docRef);
        }

        if (
          routePath.includes("/comptes-rendus/") &&
          routeName.includes("Zoom")
        ) {
          fetchOneCompteRendu(router.currentRoute.value.params.docRef);
        }
      }
    }
  );

  return {
    loading,
    reports,
    reportsCount,
    reportZoom,
    reportCompteRendu,
    selectedFilters,
    filters,
    fetchConseilMinistresLegiReportsList,
    fetchLegiReportsList,
    fetchOneCompteRendu,
    fetchOneReport,
    resetFilters,
    resetStore,
  };
});
