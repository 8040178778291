<template>
  <div class="author" @click="clickAuthor">
    <i class="fa-kit fa-sv-author-pen author-icon"></i>
    Auteur
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

const route = useRoute();

const router = useRouter();

const props = defineProps({
  author: {
    type: Object,
    required: true,
  },
});

const clickAuthor = () => {
  const searchParams = () => {
    return Object.keys(route.query).includes("search")
      ? `(${route.query.search}) AND `
      : "";
  };
  router.push({
    path: "/twitter-stakeholders",
    query: {
      ...router.currentRoute.value.query,
      search: `${searchParams()}author._id:${props.author._id}`,
    },
  });
};
</script>

<style lang="scss" scoped>
.author {
  display: flex;
  flex-direction: row;
  gap: 2px;
  color: #6800d0;
  font-weight: normal;

  i {
    z-index: 1;
    font-size: 19px;
    border-radius: 50%;
  }

  &:hover {
    color: #ae5eff;
    cursor: pointer;
  }
}
</style>
