import { httpRequest } from "@/utils/request.utils";
import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialNewsFilters = {
  from: 0, //* Page number
  size: 20, //* Number of items per page
  sort: { created_at: "desc" }, //* Order of the list
  get_filters: true, //* Retrieve available filters
  highlight: true, //* Retrieve highligted text based on querries/dashboard
  //* Other options include : “filter” string for search, “start” and “end” strings for dates (dd/mm/yyyy)
};

export const useNewsStore = defineStore("news", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const news = ref([]);
  const newsZoom = ref(null);
  const previousNewsID = ref(null);
  const nextNewsID = ref(null);
  const item_count = ref(null);
  const filters = ref(null);
  const selectedFilters = ref({ ...initialNewsFilters });
  const loading = ref(false);

  const fetchNewsList = async () => {
    loading.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    try {
      const response = await httpRequest("post", `/news`, payload);
      //* set news, filters and item count if all went well
      news.value = response.data.items;
      filters.value = response.data.filters;
      item_count.value = response.data.item_count;
    } catch (err) {
      news.value = [];
      filters.value = null;
      item_count.value = null;

      //* set error if error
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchOneNews = async (newsId) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", `/news`, {
        _id: [newsId],
        highlight: true,
        ...sharedFiltersAsPayload.value,
      });

      //* set the zoom and get the previous and next ones ID if all went well
      newsZoom.value = response.data.items[0];
      const current = news.value.indexOf(
        news.value.find((item) => item._id === newsZoom.value._id)
      );
      previousNewsID.value = news.value[current - 1]?._id;
      nextNewsID.value = news.value[current + 1]?._id;
    } catch (err) {
      //* set error if error
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const resetFilters = () => {
    selectedFilters.value = { ...initialNewsFilters };
  };

  const resetStore = () => {
    news.value = [];
    newsZoom.value = null;
    previousNewsID.value = null;
    nextNewsID.value = null;
    filters.value = null;
    selectedFilters.value = { ...initialNewsFilters };
    loading.value = false;

    item_count.value = null;
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        if (router.currentRoute.value.name === "Communiqués") {
          selectedFilters.value.from = 0;
          fetchNewsList();
        }
        if (router.currentRoute.value.name === "Zoom Communiqués") {
          fetchOneNews(newsZoom.value._id);
        }
      }
    }
  );

  return {
    news,
    newsZoom,
    previousNewsID,
    nextNewsID,
    item_count,
    filters,
    selectedFilters,
    loading,

    fetchNewsList,
    fetchOneNews,
    resetFilters,
    resetStore,
  };
});
