<template>
  <div v-if="store.lobbyZoom" class="zoom-datas">
    <LobbyZoomCard
      :key="store.lobbyZoom._id"
      :lobby="store.lobbyZoom"
    ></LobbyZoomCard>
    <RouterView />
  </div>
  <NoData v-else />
</template>

<script setup>
import LobbyZoomCard from "../molecules/LobbyZoomCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";

import { useLobbiesStore } from "@/stores/lobbies";

const store = useLobbiesStore();
</script>

<style lang="scss" scoped>
.zoom-datas {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
}
</style>
