<template>
  <div class="card ranking-wrapper height-400">
    <div class="rank-header">
      <div class="header-title">Niveaux</div>
      <div class="header-tools">
        <RadioButtons
          :options="levelsOptions"
          :active-option="activeLevel"
          @select-option="setActiveLevel"
        />
      </div>
    </div>

    <!-- Loader  -->
    <div v-if="props.isLoading">
      <div class="loading-wrapper">
        <hollow-dots-spinner
          :animation-duration="1000"
          :color="'var(--clr-p6)'"
        />
      </div>
    </div>
    <!-- Custom Legend -->
    <div
      v-else-if="
        barChartData.labels.length > 0 && barChartData.datasets.length > 0
      "
    >
      <BarChart
        :options="barChartOptions"
        :chart-data="barChartData"
        :plugins="[ChartDataLabels]"
      />
    </div>
    <div v-else>
      <div class="no-data-wrapper">
        <NoData />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { HollowDotsSpinner } from "epic-spinners";
import { BarChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";

import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const props = defineProps({
  items: {
    type: Object,
    default: () => ({}),
  },
  isLoading: { type: Boolean, required: true },
});

const activeLevel = ref("authors");

function setActiveLevel(newValue) {
  activeLevel.value = newValue;
}

const barChartData = computed(() => {
  // Pick the data according to the active level.
  const data = props.items[activeLevel.value];
  if (!data) {
    return {
      labels: [],
      datasets: [],
    };
  }
  const labels = Object.keys(data).map((label) => capitalizeFirstLetter(label));

  return {
    labels,
    datasets: [
      {
        backgroundColor: ["#6C0073"],
        data: Object.values(data),
        indexAxis: "y",
        barThickness: 20,
        datalabels: {
          anchor: "end",
          align: "right",
        },
      },
    ],
  };
});

const barChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: "black",
    },
  },
  layout: {
    padding: {
      right: 20,
    },
  },
};

const levelsOptions = [
  {
    label: "Auteurs",
    value: "authors",
  },
  {
    label: "Tweets",
    value: "tweets",
  },
];

defineEmits(["selectLevel"]);
</script>

<style scoped lang="scss">
.ranking-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rank-header {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;

  .header-title {
    font-size: 1.0625em;
    font-weight: 600;
    color: black;
  }
}
.height-400 {
  height: 468px;
}

.loading-wrapper,
.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
</style>
