<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="params-row request-and-check-all">
    <SwitchSlide :is-active="!store.bypassQueryNews" @toggle="toggleUseRequest"
      >Utiliser la requête</SwitchSlide
    >
    <CheckBox
      :checked="isAllSelected"
      @change="
        () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
      "
    >
      Toutes les activités
    </CheckBox>
  </div>
  <div class="params-row">
    <div class="page-settings filters">
      <FilterCheckBox
        v-for="index in indexes"
        :key="index.filter"
        :label="index.label"
        :color="index.color"
        :item-count="store.newsCounts?.[index.count]"
        :is-checked="lobbiesNewsFilters.includes(index.filter)"
        @check="addFilter(index.filter)"
        @uncheck="removeFilter(index.filter)"
      />
    </div>

    <div class="page-settings">
      <SelectItemsLimit
        :items-limit="selectedLobbiesNewsParams.size"
        @change-limit="changeLimit"
      >
        Activités
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { NEWS_FILTERS } from "@/stores/lobbies";
import { useLobbiesStore } from "@/stores/lobbies";

const route = useRoute();
const store = useLobbiesStore();

const props = defineProps({
  isZoomed: {
    type: Boolean,
    default: false,
  },
});

const { selectedLobbiesNewsParams, lobbiesNewsFilters } = storeToRefs(store);

const indexes = [
  {
    label: "Tweets",
    color: "var(--color-tweets)",
    count: "tweetsCount",
    filter: NEWS_FILTERS.TWEETS,
  },
  {
    label: "Articles",
    color: "var(--clr-service-article)",
    count: "articlesCount",
    filter: NEWS_FILTERS.ARTICLES,
  },
  {
    label: "Questions",
    color: "var(--clr-service-question)",
    count: "questionsCount",
    filter: NEWS_FILTERS.QUESTIONS,
  },
  {
    label: "Événements",
    color: "var(--clr-p5)",
    count: "eventsCount",
    filter: NEWS_FILTERS.EVENTS,
  },
  {
    label: "Comptes-rendus",
    color: "var(--clr-service-report)",
    count: "reportsCount",
    filter: NEWS_FILTERS.REPORTS,
  },
];

const addFilter = (filter) => {
  lobbiesNewsFilters.value.push(filter);
  fetchNews();
};

const removeFilter = (filter) => {
  lobbiesNewsFilters.value = lobbiesNewsFilters.value.filter(
    (f) => f !== filter
  );
  fetchNews();
};

const selectAllFilters = () => {
  lobbiesNewsFilters.value = indexes.map(({ filter }) => filter);
  fetchNews();
};

const unselectAllFilters = () => {
  lobbiesNewsFilters.value = [];
  fetchNews();
};

const isAllSelected = computed(
  () => lobbiesNewsFilters.value.length === Object.keys(NEWS_FILTERS)?.length
);

const toggleUseRequest = () => {
  store.bypassQueryNews = !store.bypassQueryNews;
  fetchNews();
};

// Limit
const changeLimit = (size) => {
  selectedLobbiesNewsParams.value.size = size;
  selectedLobbiesNewsParams.value.from = 0;
  fetchNews();
};

const showScrollTop = computed(() => selectedLobbiesNewsParams.value.size > 0);

const fetchNews = async () => {
  selectedLobbiesNewsParams.value.from = 0;
  props.isZoomed
    ? await store.fetchOneLobbyNews(route.params._id)
    : await store.fetchFollawedLobbiesNews();
};

fetchNews();
</script>

<style lang="scss" scoped>
.news-params {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.request-and-check-all {
  justify-content: flex-start;
  gap: 15px;
}
.filters {
  gap: 10px;
}
</style>
