<template>
  <template v-if="newsStore.news.length">
    <div v-if="newsStore.news" class="news-list grid-3-cols">
      <NewsCard v-for="n in newsStore.news" :key="n._id" :news="n" />
    </div>

    <PaginationElement
      v-if="newsStore.item_count"
      :key="`${newsStore.item_count}${newsStore.selectedFilters.from}${newsStore.selectedFilters.size}`"
      :total-items="newsStore.item_count"
      :current-page="newsStore.selectedFilters.from"
      :items-limit="newsStore.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoData v-else />
</template>

<script setup>
import NewsCard from "@/components/news/atoms/NewsCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { useNewsStore } from "@/stores/news";

const newsStore = useNewsStore();

const changeCurrentPage = async (from) => {
  newsStore.selectedFilters.from = from;
  await newsStore.fetchNewsList();
};
</script>

<style scoped>
.news-list {
  :deep(.card) {
    height: 100%;
  }
}
</style>
