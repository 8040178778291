<template>
  <button
    class="export-btn"
    :class="{ disabled: disabled }"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot></slot>
    <i
      v-if="userSettingsStore.hasPermission('BASIC')"
      :class="`fa-solid fa-${iconName} icon`"
    ></i>
    <PermissionLevelIcon
      v-else
      class="permission-indicator"
      permission-level="BASIC"
    />
  </button>
  <FreemiumUpgradePopup
    ref="lockedExportPopup"
    :closable="true"
    title="Export des données"
  >
    <p>
      La fonctionnalité d'export des données de Follaw.SV permet de sauvegarder
      et d'analyser les informations collectées selon vos besoins. Vous pouvez
      exporter les données facilitant ainsi leur intégration dans vos rapports
      et analyses.
    </p>
    <p>
      Configurez les critères d'exportation pour inclure les informations
      pertinentes, comme le nombre de signatures d'une pétition, les amendements
      avec leur signataires par exemple, les questions parlementaires et leur
      statut, etc…
    </p>
    <p>
      Cette flexibilité vous permet de gérer les données efficacement et de les
      utiliser pour une prise de décision informée.
    </p>
    <p>
      Avec l'export des données, Follaw.SV vous aide à structurer et à
      documenter vos analyses, tout en offrant une traçabilité complète des
      informations institutionnelles et sociétales suivies.
    </p>
  </FreemiumUpgradePopup>
</template>

<script setup>
import { ref } from "vue";
import PermissionLevelIcon from "@/components/general/atoms/PermissionLevelIcon.vue";
import FreemiumUpgradePopup from "@/components/reusable/atoms/FreemiumUpgradePopup.vue";
import { useUserSettingsStore } from "@/stores/user-settings";

const props = defineProps({
  iconName: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["click"]);

const userSettingsStore = useUserSettingsStore();

const lockedExportPopup = ref();
const handleClick = () => {
  if (props.disabled) {
    return;
  }
  if (userSettingsStore.hasPermission("BASIC")) {
    emit("click");
  } else {
    lockedExportPopup.value.open();
  }
};
</script>

<style lang="scss" scoped>
.export-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: var(--shd-card);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 3px 10px;
  background: white;
  font-size: 0.938em;

  &:enabled {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--clr-p5);
    }

    &:active {
      background-color: var(--clr-p2);
    }
  }

  &:disabled {
    opacity: 0.65;
  }

  .icon {
    color: var(--clr-s7);
  }

  :deep(.permission-indicator) {
    width: 15px;
  }
}

p {
  margin: 0;
}
</style>
