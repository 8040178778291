<template>
  <SocialsViewWrapper>
    <TwitterOverviewParams />
    <LoaderFullPage v-if="store.loading" />
    <TwitterOverviewDatas v-else />
  </SocialsViewWrapper>
</template>

<script setup>
import SocialsViewWrapper from "@/components/twitter/templates/SocialsViewWrapper.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import TwitterOverviewParams from "@/components/twitter/organisms/TwitterOverviewParams.vue";
import TwitterOverviewDatas from "@/components/twitter/organisms/TwitterOverviewDatas.vue";
import { useTwitterStatsStore } from "@/stores/twitter-stats";
import { useUserSettingsStore } from "@/stores/user-settings";

const store = useTwitterStatsStore();
const userSettingsStore = useUserSettingsStore();

if (userSettingsStore.hasPermission("SOCIETAL")) {
  store.fetchOverview();
}
</script>
