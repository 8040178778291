<template>
  <SelectSearchBar v-model.trim="searchField" mode="topbar" />
  <!-- Option to select -->
  <ul class="options-list thematics-list">
    <li v-for="(item, index) in searchedThematics" :key="index">
      <CollapseSection ref="thematicsCollapse" :initial-active="false">
        <template #title>
          <div class="option">
            <span class="check">
              <i
                :class="[
                  isThematicActive(item._id) ||
                  isThematicPartiallyActive(item._id)
                    ? 'fa-solid'
                    : 'fa-regular fa-square',
                  isThematicActive(item._id)
                    ? 'fa-square-check'
                    : 'fa-square-minus',
                ]"
                @click.stop="() => toggleThematic(item._id)"
              ></i>
            </span>
            <div class="text">
              {{ item.name }}
            </div>
          </div>
        </template>

        <template #content>
          <ul class="options-list subthematics-list">
            <li
              v-for="(subitem, subindex) in item.subthematics"
              :key="subindex"
              class="option"
              @click.stop="() => toggleSubthematic(item._id, subitem._id)"
            >
              <span class="check">
                <i
                  :class="[
                    isSubthematicActive(subitem._id) ||
                    isThematicActive(item._id)
                      ? 'fa-solid fa-square-check'
                      : 'fa-regular fa-square',
                  ]"
                ></i>
              </span>
              <div class="subthematic-name-and-desc">
                <h4>{{ subitem.name }}</h4>
                <p v-if="subitem.description?.length > 0">
                  {{ subitem.description }}
                </p>
              </div>
            </li>
          </ul>
        </template>
      </CollapseSection>
    </li>
    <p v-if="searchField && !searchedThematics.length" class="no-result">
      Aucune thématique ou sous-thématique ne correspond à cette recherche.
    </p>
  </ul>

  <button
    type="button"
    class="btn-secondary btn-reset topbar"
    @click="$emit('reset')"
  >
    <i class="fa-solid fa-arrow-rotate-left"></i>
    <span class="label">Réinitialiser</span>
  </button>
</template>

<script setup>
import { computed, ref, watch } from "vue";

import SelectSearchBar from "@/components/reusable/atoms/SelectSearchBar.vue";
import CollapseSection from "@/components/agenda/atoms/CollapseSection.vue";

const props = defineProps({
  thematics: {
    type: Array,
    default: () => [],
  },
  activeThematics: {
    type: Array,
    default: () => [],
  },
  activeSubthematics: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits([
  "addThematic",
  "addSubthematic",
  "removeThematic",
  "removeSubthematic",
  "reset",
]);

const searchField = ref("");
const thematicsCollapse = ref([]);

const searchedThematics = computed(() => {
  // Construct and return a new array of thematics,
  // where only the items matching the search will be returned
  return props.thematics
    .map((thematic) => {
      const searchedSubthematics = thematic.subthematics.filter(
        (subthematic) =>
          thematic.name
            .toLowerCase()
            .includes(searchField.value.toLowerCase()) ||
          subthematic.name
            .toLowerCase()
            .includes(searchField.value.toLowerCase())
      );
      if (searchedSubthematics.length > 0) {
        return { ...thematic, subthematics: searchedSubthematics };
      }
    })
    .filter((n) => n !== undefined); // Remove undefined items
});

const isThematicActive = (themId) => {
  return props.activeThematics.map(({ _id }) => _id).includes(themId);
};

const isThematicPartiallyActive = (themId) => {
  const them = props.thematics.find(({ _id }) => _id === themId);
  return them.subthematics.some((subT) => isSubthematicActive(subT._id));
};

const isSubthematicActive = (subthemId) => {
  return props.activeSubthematics.map(({ _id }) => _id).includes(subthemId);
};

const toggleThematic = (themId) => {
  const them = props.thematics.find(({ _id }) => _id === themId);
  if (isThematicActive(themId)) {
    emit("removeThematic", { item: themId });
  } else {
    emit("addThematic", { item: them });

    // Special case: if some subthematics were already activated,
    // deactivate them because the thematic will be a catch-all
    const previouslyActiveSubT = them.subthematics.filter((_s) =>
      isSubthematicActive(_s._id)
    );
    for (const subT of previouslyActiveSubT) {
      emit("removeSubthematic", { item: subT._id });
    }
  }
};

const toggleSubthematic = async (themId, subthemId) => {
  const them = props.thematics.find(({ _id }) => _id === themId);
  const subthem = them.subthematics.find(({ _id }) => _id === subthemId);

  if (isThematicActive(themId)) {
    // Special case: if the thematic was activated, deactivating
    // a subthematic will deactivate the thematic and
    // activate all other subthematics

    emit("removeThematic", { item: themId });

    for (const otherSubT of them.subthematics) {
      if (subthemId === otherSubT._id) {
        emit("removeSubthematic", { item: subthemId });
      } else {
        emit("addSubthematic", { item: otherSubT });
      }
    }
  } else {
    if (isSubthematicActive(subthemId)) {
      emit("removeSubthematic", { item: subthemId });
    } else {
      emit("addSubthematic", { item: subthem });

      // Do an await to make sure that the emit has been processed
      await new Promise((resolve) => setTimeout(resolve, 0));

      // Special case: if all subthematics are activated, activate the thematic instead
      const allSubTActive = them.subthematics.every((_s) =>
        isSubthematicActive(_s._id)
      );
      if (allSubTActive) {
        toggleThematic(them._id);
      }
    }
  }
};

// Automatically open thematics when the search matches one of their subthematic
// or close them all when search is empty
watch([searchField], () => {
  const isSearchEmpty = searchField.value.length === 0;

  thematicsCollapse.value.forEach((tc) =>
    isSearchEmpty ? tc.close() : tc.open()
  );
});
</script>

<style lang="scss" scoped>
.subthematic-name-and-desc {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h4 {
    font-size: var(--size-small);
  }

  p {
    margin: 0;
    font-size: var(--size-small);
  }
}

.options-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 5px 8px;
  overflow-x: hidden;

  .option {
    display: flex;
    gap: 8px;
    width: 100%;

    i {
      color: var(--clr-p8);
    }
  }
}

.thematics-list {
  margin: 0;

  .option i:hover {
    background-color: var(--clr-n3);
  }
}

.subthematics-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;

  .option {
    cursor: pointer;
    &:hover {
      i {
        background-color: var(--clr-n4);
      }
    }
  }
}

.no-result {
  margin: 0 0 10px 8px;
  font-style: italic;
}

.btn-reset {
  display: flex;
  align-self: flex-end;
  gap: 8px;
}
</style>
