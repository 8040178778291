<template>
  <div>
    <div class="article" @click="() => goToArticle(content.title)">
      {{ content.title || "Article sans titre" }}
      <!-- Display the dot after checking if it match occurrences -->
      <span v-if="highlightCount > 0" class="occurrences-count"> </span>
    </div>
    <ul v-if="contentSubItems.length > 0" class="nested-articles">
      <li v-for="(subItem, index) in contentSubItems" :key="index">
        <RecursiveLegiTextContent
          :content="subItem"
          :occurrences="occurrences"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { hasHighlight, goToArticle } from "@/utils/browse-articles";

const props = defineProps({
  content: { type: Object, required: true },
  occurrences: { type: Object, required: true, default: () => ({}) },
});

// Handle highlighted presence in the article
const highlightCount = computed(() => {
  if (props.content.content) {
    // Count occurrences of highlighted content in the article
    return hasHighlight(props.content.content);
  }
  return 0;
});

// Getting the sub-items that have a "title"
const contentSubItems = computed(() => {
  const subItems = [];
  for (const key in props?.content) {
    if (Array.isArray(props.content[key])) {
      props.content[key].forEach((item) => {
        if (item.title) subItems.push(item);
      });
    } else if (
      props.content[key] instanceof Object &&
      props.content[key].title
    ) {
      subItems.push(props.content[key]);
    }
  }
  return subItems;
});
</script>

<style scoped lang="scss">
.occurrences-count {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 8px;
  background-color: var(--clr-highlight);
}
.nested-articles {
  padding-left: 20px;
  li {
    margin-top: 10px;
  }
}

.article {
  position: relative;
  cursor: pointer;
  &:hover {
    color: var(--clr-s7);
    width: fit-content;
    text-decoration: underline;
  }
}
</style>
