<template>
  <div class="filter" @click="isChecked ? emit('uncheck') : emit('check')">
    <div class="label-and-count">
      <label>{{ label }}</label>
      <span v-if="itemCount" class="item-count">{{ itemCount }}</span>
    </div>
    <i
      v-if="isChecked"
      class="fa-solid fa-square-check"
      :style="`color: ${color}`"
    ></i>
    <i v-else class="fa-regular fa-square" :style="`color: ${color}`"></i>
  </div>
</template>

<script setup>
defineProps({
  label: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
  },
  itemCount: {
    type: Number,
    default: null,
  },
  isChecked: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["check", "uncheck"]);
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 50rem;
  width: 215px;
  height: 30px;
  padding: 0 15px;
  background-color: white;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 5px 1px var(--clr-p9);
    font-weight: 400;
  }
  &:active {
    background-color: var(--clr-p2);
    font-weight: 400;
  }

  .label-and-count {
    display: flex;
    align-items: center;
    gap: 5px;

    .item-count {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50rem;
      min-width: 20px;
      height: 20px;
      padding: 3px;
      font-size: 0.875em;
      color: white;
      background-color: var(--clr-p5);
    }
  }
}
</style>
