<template>
  <RouterLink :to="`/news-releases/${props.news._id}`">
    <div class="card card-hover">
      <section class="header">
        <span class="date">{{
          getDateShortFormat(props.news.created_at)
        }}</span>
      </section>
      <section class="body">
        <div class="title">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <h3 v-html="props.news.title"></h3>
        </div>
        <div v-show="showDescription()" class="content typo-body-regular">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="props.news.description"></div>
        </div>
        <div class="author">
          <img src="@/assets/img/legislation/gouv_fr.png" alt />
          <span>{{ props.news.author }}</span>
        </div>
        <div v-if="(props.news.matched_words || []).length > 0" class="content">
          <span class="matched-title">Mots matchés : </span>
          <span class="matched-words">{{ listOfWords }}</span>
        </div>
      </section>
      <section class="footer">
        <SourceLink :href="props.news.source">{{
          props.news.domain
        }}</SourceLink>
      </section>
    </div>
  </RouterLink>
</template>

<script setup>
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import { computed } from "vue";

const props = defineProps({
  news: {
    type: Object,
    required: true,
  },
});

function showDescription() {
  // Condition: description exists and is not too long
  return props.news.description?.length <= 1000;
}

const listOfWords = computed(() =>
  props.news.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style lang="scss" scoped>
.card {
  color: var(--clr-n8);
  display: flex;
  flex-direction: column;
}

.head {
  .date {
    color: var(--clr-n8);
    font-weight: 300;
  }
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    display: flex;

    h3 {
      margin: 0;
      color: var(--clr-s7);
      font-size: 1em;
    }
  }

  .content {
    font-size: 16px;
    color: var(--clr-n8);

    :deep(p) {
      padding: 0;
      margin: 0;
    }

    .matched-title {
      font-weight: 500;
      color: var(--clr-s7);
    }
  }

  .author {
    align-items: center;
    display: flex;
    color: var(--clr-n8);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    img {
      margin-right: 10px;
      margin-left: 0;
      box-shadow: var(--shd-card);
      border-radius: 100%;
      width: 25px;
      min-width: 25px;
      height: 25px;
    }
  }
}

.footer {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: 8px;
}
</style>
