<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="englobe-params">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SearchAsYouTypeFilter
          v-if="!store.isFollawedActive"
          filter="author"
          :items="store.authors"
          item-label="name"
          item-value="_id"
          :active-filters="selectedFilters.author"
          :is-loading="store.isAuthorFilterLoading"
          mode="purple"
          @search="fetchAuthors"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetAuthorFilter"
        >
          Auteurs
        </SearchAsYouTypeFilter>
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>
    </div>

    <div class="params-row">
      <TotalMatched :item-count="store.item_count" />

      <div class="page-settings">
        <SortBy
          :items="sortersNames"
          :selected-sort-item="currentSortLabel"
          @change-sort="handleSortChange"
        />
        <SelectItemsLimit
          :items-limit="selectedFilters.size"
          @change-limit="changeLimit"
        >
          Tweets
        </SelectItemsLimit>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useTweetsStore } from "@/stores/tweets";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const store = useTweetsStore();
const route = useRoute();

const { selectedFilters } = storeToRefs(store);

// All filters in wanted display order with their labels
const filtersNames = {
  group: "Groupe",
  major_stakeholder: "Grande Partie Prenante",
  stakeholder: "Partie Prenante",
  level: "Niveau",
  circo_name: "Localité",
};

// All sorters in wanted display order with their labels
const sortersNames = {
  retweet: "Retweets",
  impression: "Impressions",
  created_at: "Date",
  last_update: "Dernière mise à jour",
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });
  selectedFilters.value.author?.forEach((filter) => {
    arr = [
      ...arr,
      {
        filter: "author",
        item: filter,
      },
    ];
  });
  return arr;
});

const currentSortField = ref(
  Object.keys(selectedFilters.value.sort)[0] ?? "retweet"
);

const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentSortField.value = selectedSortField;
      selectedFilters.value.sort = { [currentSortField.value]: "desc" };
      fetchTweets();
    }
  }
};

const addFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];
  targetFilters = [...targetFilters, event.item];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: targetFilters,
  };

  fetchTweets();
};

const showScrollTop = computed(() => selectedFilters.value.size > 20);

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] ?? [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }

  fetchTweets();
};

const fetchAuthors = (nameSearch) => {
  if (nameSearch.length > 0) {
    store.getAuthorFilters(nameSearch);
  } else {
    store.authors = [];
  }
};

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchTweets();
};

const resetAuthorFilter = () => {
  store.authors = [];
  delete selectedFilters.value.author;
  fetchTweets();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchTweets();
};

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  fetchTweets();
};

const fetchTweets = async () => {
  selectedFilters.value.from = 0;
  await store.fetchTweetsList(route.query.author_id ?? null);
};
</script>
