<template>
  <div class="template">
    <div class="englobe-params">
      <div class="back">
        <i class="fa-solid fa-chevron-left"></i>
        <RouterLink to="/organes">
          Liste des commissions et autres organes
        </RouterLink>
        <i class="fa-solid fa-chevron-left"></i>
        <RouterLink :to="`/organes/${route.params._id}/_missions`">
          Commission
        </RouterLink>
        <i class="fa-solid fa-chevron-left"></i>Mission
      </div>
    </div>

    <LoaderFullPage v-if="store.loading" />
    <template v-else>
      <div class="commission-zoom">
        <InstituOrganeZoomHeader :organe="store.groupZoom" />

        <RouterRadioButtons :options="routerOptions" />

        <RouterView />
      </div>
    </template>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import { computed } from "vue";
import InstituOrganeZoomHeader from "@/components/institu-groups/atoms/InstituOrganeZoomHeader.vue";

const store = useInstituGroupsStore();
const route = useRoute();

const routerOptions = computed(() => [
  {
    label: "Composition",
    value: "composition",
  },
  {
    label: "Dernières activités",
    value: "activites",
  },
  {
    label: "Tweets",
    value: "tweets",
  },
  {
    label: "Agenda",
    value: "agenda",
  },
]);

if (route.params.missionId !== store.groupZoom?._id) {
  store.fetchOneGroup(route.params.missionId);
}
</script>

<style scoped lang="scss">
.commission-zoom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.back {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
