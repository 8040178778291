<template>
  <BarChart
    :chart-data="chartData"
    :options="options"
    :plugins="[ChartDataLabels]"
    :height="200"
  />
</template>

<script setup>
import { computed } from "vue";
import { BarChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getCSSVariable } from "@/utils/helper.utils.js";

const props = defineProps({
  thematics: {
    type: Object,
    required: true,
  },
});

const chartData = computed(() => {
  return {
    labels: Object.keys(props.thematics),
    datasets: [
      {
        label: "Tweets",
        data: Object.values(props.thematics).map((thematic) => thematic.tweet),
        borderColor: getCSSVariable("--clr-s6"),
        backgroundColor: getCSSVariable("--clr-s6"),
        barThickness: 18,
        borderRadius: 2,
        borderSkipped: false,
      },
    ],
  };
});

const options = {
  indexAxis: "y",
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      color: "black",
      anchor: "end",
      align: "right",
      offset: 4,
    },
    legend: {
      display: false,
    },
  },
  layout: {
    padding: {
      right: 50,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: "black",
        font: {
          size: 16,
        },
      },
    },
  },
};
</script>
