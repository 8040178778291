import { httpRequest } from "@/utils/request.utils";
import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import router from "@/router";
import { handleNetworkError } from "@/utils/handleNetworkError";

const ASC = "asc";
const DESC = "desc";

// Translate the sort property into the format expected by the back-end
function getSortInPayloadFormat(sortData) {
  return [{ [sortData.property]: sortData.order }];
}

const initialPetitionsFilters = {
  size: 20,
  from: 0,
  filters: true,
  sort: { property: "signatures_number", order: DESC },
};

const emptyPetition = {
  tweet_authors: [],
  signatures_history: [],
};

// Defining store
export const usePetitionsStore = defineStore("petitions", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const items = ref([]);
  const filters = ref(null);
  const item_count = ref(null);
  const loading = ref(false);

  const currentPetition = ref(emptyPetition);
  const nextPetition = ref(null);
  const previousPetition = ref(null);
  const selectedFilters = ref({ ...initialPetitionsFilters });
  const isFollawedActive = ref(false);
  const bypassQuery = ref(false);

  const fetchPetitionsList = async () => {
    items.value = [];
    loading.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
      sort: getSortInPayloadFormat(selectedFilters.value.sort),
    };

    if (isFollawedActive.value) {
      //* Add the list of follawed ids
      payload._id = userStore.follawed[FOLLAWED_FEATURES.PETITIONS];
    }

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    try {
      const response = await httpRequest("post", "/read-petitions", payload);
      loading.value = false;
      if (response.data) {
        items.value = response.data.items;
        filters.value = response.data.filters;
        item_count.value = response.data.item_count;
      }
    } catch (err) {
      items.value = null;
      filters.value = {};
      item_count.value = null;

      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  const fetchOnePetition = async (id) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", "/petition-zoom", {
        from: 0,
        size: 1,
        _id: [id],
        highlight: true,
        ...sharedFiltersAsPayload.value,
      });

      // If the zoom is called with parameters which do not match the petition, the back-end will return a null value
      // This is not the expected behavior but is hard to fix, so we display a specific error in the meantime

      if (!response.data.items?.length) {
        currentPetition.value = emptyPetition;
        throw new Error(
          "Impossible d'afficher cette pétition avec les filtres actifs. Veuillez réessayer en enlevant des filtres."
        );
      }

      currentPetition.value = response.data.items[0];
      const indexZoomedPetition = items.value?.indexOf(
        items.value?.find((item) => item._id === currentPetition.value?._id)
      );
      const previousPetitionOnItems = items.value[indexZoomedPetition - 1];
      const nextPetitionOnItems = items.value[indexZoomedPetition + 1];

      // Set Next Petition on the store and set null if there is no next
      nextPetitionOnItems
        ? (nextPetition.value = nextPetitionOnItems)
        : (nextPetition.value = null);
      // Set Previous Petition on the store and set null if there is no next
      previousPetitionOnItems
        ? (previousPetition.value = previousPetitionOnItems)
        : (previousPetition.value = null);
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  };

  // Create a new Petition
  const addPetition = async (url) => {
    loading.value = true;

    try {
      const response = await httpRequest("post", "/petitions", { url });

      // Add the new petitions to the user's follawed items
      const newPetitionId = response.data._id;
      await userStore.toggleFollawed(
        FOLLAWED_FEATURES.PETITIONS,
        newPetitionId
      );

      return response;
    } finally {
      loading.value = false;
    }
  };

  function toggleSortByProperty(sortProperty) {
    const sortData = selectedFilters.value.sort;
    const isSortedByProperty = sortData.property === sortProperty;
    if (isSortedByProperty) {
      sortData.order = sortData.order === ASC ? DESC : ASC;
    } else {
      sortData.property = sortProperty;
      sortData.order = DESC;
    }

    selectedFilters.value = {
      ...selectedFilters.value,
      sort: sortData,
      // Also reset the pagination
      from: 0,
    };
  }

  const toggleFollawed = () => {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    fetchPetitionsList();
  };

  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  // reset Filters
  const resetFilters = () => {
    selectedFilters.value = { ...initialPetitionsFilters };
  };

  // Emptying the store
  const resetStore = () => {
    items.value = [];
    filters.value = null;
    item_count.value = null;
    currentPetition.value = emptyPetition;
    nextPetition.value = null;
    previousPetition.value = null;
    selectedFilters.value = { ...initialPetitionsFilters };
    loading.value = false;
    filters.value = null;
  };

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        if (router.currentRoute.value.name === "Pétitions") {
          selectedFilters.value.from = 0;
          fetchPetitionsList();
        }
        if (router.currentRoute.value.name === "Zoom petitions") {
          fetchOnePetition(router.currentRoute.value.params.id);
        }
      }
    }
  );

  return {
    ASC,
    DESC,
    bypassQuery,
    items,
    filters,
    item_count,
    loading,
    currentPetition,
    nextPetition,
    previousPetition,
    selectedFilters,
    isFollawedActive,
    hasActiveFilters,
    fetchPetitionsList,
    fetchOnePetition,
    addPetition,
    toggleSortByProperty,
    toggleFollawed,
    resetFilters,
    resetStore,
  };
});
