<template>
  <table v-if="store.alerts.length" class="alerts-table">
    <thead>
      <tr>
        <th class="name">Nom</th>
        <th>Veille</th>
        <th>Corpus</th>
        <th class="center">Canal</th>
        <th class="center state">Active</th>
        <th class="center actions">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="alert in store.alerts" :key="alert._id" class="alert-row">
        <td>
          {{ alert.name }}
        </td>
        <td>
          {{ alert.dashboard_name }}
        </td>
        <td>
          <div class="services">
            <ServiceTooltip
              v-for="(service, index) in services"
              :key="index"
              :text="service.displayName"
            >
              <span
                class="service-icon"
                :class="
                  alert.alert_services.includes(service.name) ? 'active' : ''
                "
              >
                <i class="fa-lg" :class="service.icon"></i>
              </span>
            </ServiceTooltip>
          </div>
        </td>
        <td>
          <div class="center notification-types">
            <i
              v-if="alert.notification_type === 'email'"
              class="fa-solid fa-lg fa-envelope"
            ></i>
            <i
              v-if="alert.notification_type === 'telegram'"
              class="fa-brands fa-lg fa-telegram"
            ></i>
            <i
              v-if="alert.notification_type === 'slack'"
              class="fa-brands fa-lg fa-slack"
            ></i>
            <i
              v-if="alert.notification_type === 'msteams'"
              class="fa-kit fa-lg fa-ms-teams"
            ></i>
          </div>
        </td>
        <td>
          <div class="center">
            <SwitchSlide
              :is-active="alert.active"
              @toggle="store.toggleAlert(alert._id)"
            />
          </div>
        </td>
        <td>
          <div class="center actions">
            <button class="edit" @click="openUdpateDialog(alert)">
              <i class="fa-solid fa-pen"></i>
            </button>
            <button class="delete" @click="openDeleteDialog(alert)">
              <i class="fa-solid fa-trash-can"></i>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <NoAlert
    v-else
    title="Aucune alerte configurée"
    message="Configurez une alerte pour être averti en temps réel des nouveaux
        éléments pertinents disponibles."
  />

  <PopupDialog ref="updateModal" title="Éditer une alerte" @close="closeDialog">
    <template #body>
      <AddEditAlertForm
        v-if="showForm"
        ref="updateForm"
        :targeted-alert="targetedAlert"
      />
    </template>
    <template #footer>
      <button v-if="showForm" class="btn-primary" @click="updateAlert">
        Éditer
      </button>
    </template>
  </PopupDialog>
  <PopupDialog
    ref="deleteModal"
    title="Supprimer une alerte"
    @close="targetedAlert = null"
  >
    <template #body>
      <p class="modal-text">Êtes-vous sûr de vouloir supprimer l'alerte</p>
      <p class="modal-text">"{{ targetedAlert?.name }}" ?</p>
    </template>
    <template #footer>
      <button class="btn-delete" @click="deleteAlert">Supprimer</button>
    </template>
  </PopupDialog>
</template>

<script setup>
import { ref } from "vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import PopupDialog from "@/components/reusable/atoms/PopupDialog.vue";
import ServiceTooltip from "@/components/alerting/atoms/ServiceTooltip.vue";
import NoAlert from "@/components/alerting/atoms/NoAlert.vue";
import AddEditAlertForm from "@/components/alerting/molecules/AddEditAlertForm.vue";
import { useAlertsStore } from "@/stores/alerts";

const store = useAlertsStore();

const services = ref([
  {
    name: "event",
    displayName: "Événements",
    icon: "fa-solid fa-calendar-lines",
  },
  { name: "jorf", displayName: "Jorf", icon: "fa-kit fa-jorf" },
  // {
  //   name: "petition",
  //   displayName: "Pétitions",
  //   icon: "fa-solid fa-file-signature",
  // },
  { name: "news", displayName: "Actualités", icon: "fa-kit fa-communiques" },
  {
    name: "legislation",
    displayName: "Dossiers législatifs",
    icon: "fa-kit fa-dossierlegi",
  },
  { name: "amendment", displayName: "Amendements", icon: "fa-kit fa-am" },
  { name: "question", displayName: "Questions", icon: "fa-kit fa-questions" },
  { name: "compte-rendu", displayName: "Comptes-rendus", icon: "fa-kit fa-cr" },
  { name: "tweet", displayName: "Twitter", icon: "fa-brands fa-twitter" },
  // {
  //   name: "articles",
  //   displayName: "Articles",
  //   icon: "fa-kit fa-sv-articles",
  // },
]);

const targetedAlert = ref(null);

const deleteModal = ref();
const openDeleteDialog = (alert) => {
  targetedAlert.value = alert;
  deleteModal.value?.open();
};
const deleteAlert = () => {
  store.deleteAlert(targetedAlert.value._id);
  deleteModal.value?.close();
};

const updateModal = ref();
const showForm = ref(false);
const openUdpateDialog = (alert) => {
  targetedAlert.value = alert;
  showForm.value = true;
  updateModal.value?.open();
};

const updateForm = ref();
const updateAlert = () => {
  if (updateForm.value?.validate()) {
    showForm.value = false;
    updateForm.value?.form.requestSubmit();
  }
};

const closeDialog = () => {
  targetedAlert.value = null;
  showForm.value = false;
};
</script>

<style lang="scss" scoped>
.alerts-table {
  thead {
    color: var(--clr-n8);
    background-color: var(--clr-p2);

    th {
      &.center {
        text-align: center;
      }

      &.state {
        width: 75px;
      }

      &.actions {
        width: 85px;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: var(--clr-n1);
      }

      &:hover {
        background-color: var(--clr-p1);
      }

      td {
        .center {
          display: flex;
          justify-content: space-around;
        }

        .services {
          display: flex;
          gap: 3px;
          font-weight: 400;

          .service-icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: var(--clr-p2);

            &.active {
              background-color: var(--clr-p5);
            }
          }
        }

        .actions {
          gap: 5px;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: var(--shd-card);
            border-radius: 50%;
            background-color: white;

            &:hover,
            &:focus {
              background: var(--clr-p2);
              box-shadow: var(--shd-card);
            }
            &:active {
              background-color: var(--clr-p1);
            }
          }

          .edit {
            padding: 6px;
            color: var(--clr-s7);
          }

          .delete {
            padding: 6px 7px;
            color: var(--clr-e300);
          }
        }
      }
    }
  }

  .fa-envelope {
    color: var(--clr-p5);
  }
  .fa-telegram {
    color: var(--clr-telegram);
  }
  .fa-slack {
    color: var(--clr-slack);
  }
  .fa-ms-teams {
    color: var(--clr-ms-teams);
  }
}

// Modal contents
.modal-text {
  margin: 0;
  text-align: center;
}
</style>
