<template>
  <form
    ref="addOrEditForm"
    method="dialog"
    class="add-or-edit-digest"
    @submit="submit()"
  >
    <div class="field">
      <label for="name">Nom : *</label>
      <input
        id="name"
        v-model="formData.name"
        data-1p-ignore
        type="text"
        autofocus
        required
      />
      <span v-if="!isFormValid && !formData.name" class="error"
        >Un nom est requis</span
      >
    </div>
    <div class="field">
      <label for="dashboard">Veille : *</label>
      <SelectFilter
        v-model="formData.dashboard_id"
        name="Choisir une veille"
        :items="userStore.dashboards"
        item-label="name"
        item-value="_id"
        mode="form-input"
      >
      </SelectFilter>
      <span v-if="!isFormValid && !formData.dashboard_id" class="error"
        >Une veille est requise</span
      >
    </div>
    <div class="field">
      <label for="additional-request"
        >Requête supplémentaire : <span class="tip">(optionnel)</span></label
      >
      <textarea
        id="additional-request"
        v-model="formData.additional_query"
      ></textarea>
    </div>
    <div class="field">
      <label for="service"
        >Corpus Concernés : <span class="tip">(au moins un)</span></label
      >
      <div class="services-wrapper">
        <CheckBox
          v-for="(service, index) in selectableServices"
          :key="index"
          :checked="formData.alert_services.includes(service.name)"
          @change.stop="
            () =>
              formData.alert_services.includes(service.name)
                ? removeCorpus(service.name)
                : addCorpus(service.name)
          "
        >
          {{ service.displayName }}
        </CheckBox>
      </div>
      <span
        v-if="!isFormValid && !formData.alert_services.length > 0"
        class="error"
        >Un corpus est requis</span
      >
    </div>

    <div class="field">
      <label for="frequency_days"
        >Envoyer tous les
        <span class="tip">(au moins un, hebdomadaire)</span>
      </label>
      <div class="days-wrapper">
        <ServiceTooltip
          v-for="(day, index) in selectableDays"
          :key="index"
          :text="day.displayName"
        >
          <button
            class="day-icon"
            :class="{ active: formData.frequency_days.includes(day.name) }"
            @click.prevent="() => toggleDay(day.name)"
          >
            {{ day.displayName[0] }}
          </button>
        </ServiceTooltip>
      </div>

      <div>
        À
        <select v-model="formData.frequency_hour">
          <option v-for="hh in selectableHours" :key="hh" :value="Number(hh)">
            {{ hh }}
          </option>
        </select>
        h
        <select v-model.number="formData.frequency_minutes">
          <option :value="0">00</option>
          <option :value="30">30</option>
        </select>
      </div>
      <span
        v-if="!isFormValid && !formData.frequency_days.length > 0"
        class="error"
        >Au moins un jour est requis</span
      >
    </div>

    <div class="field">
      <label for="active">Active :</label>
      <SwitchSlide
        :is-active="formData.active"
        @toggle="formData.active = !formData.active"
      />
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import SelectFilter from "@/components/reusable/atoms/SelectFilter.vue";
import { useDigestsStore } from "@/stores/digests";
import { useUserStore } from "@/stores/user";
import ServiceTooltip from "../atoms/ServiceTooltip.vue";

const store = useDigestsStore();
const userStore = useUserStore();

const props = defineProps({
  targetedDigest: {
    type: Object,
    default: null,
  },
});

const services = ref([
  { name: "event", displayName: "Événements" },
  { name: "jorf", displayName: "Jorf" },
  { name: "petition", displayName: "Pétitions" },
  { name: "news", displayName: "Actualités" },
  { name: "legislation", displayName: "Dossiers législatifs" },
  { name: "amendment", displayName: "Amendements" },
  { name: "question", displayName: "Questions" },
  { name: "compte-rendu", displayName: "Comptes-rendus" },
  { name: "tweet", displayName: "Twitter" },
  { name: "articles", displayName: "Articles" },
]);

const selectableServices = computed(() =>
  services.value.filter((service) =>
    store.filters.alert_services?.includes(service.name)
  )
);

const selectableDays = [
  {
    name: "monday",
    displayName: "Lundi",
  },
  {
    name: "tuesday",
    displayName: "Mardi",
  },
  {
    name: "wednesday",
    displayName: "Mercredi",
  },
  {
    name: "thursday",
    displayName: "Jeudi",
  },
  {
    name: "friday",
    displayName: "Vendredi",
  },
  {
    name: "saturday",
    displayName: "Samedi",
  },
  {
    name: "sunday",
    displayName: "Dimanche",
  },
];

const selectableHours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const formData = ref({
  name: "",
  dashboard_id: "",
  additional_query: "",
  alert_services: [],
  frequency_days: [],
  frequency_hour: 0,
  frequency_minutes: 0,
  active: false,
});

const isFormValid = ref(true);

const validate = () => {
  isFormValid.value =
    formData.value.name &&
    formData.value.dashboard_id &&
    formData.value.alert_services.length > 0;
  return isFormValid.value;
};

onMounted(() => {
  if (props.targetedDigest) {
    formData.value.name = props.targetedDigest.name;
    formData.value.dashboard_id = props.targetedDigest.dashboard_id;
    formData.value.additional_query = props.targetedDigest.additional_query;
    formData.value.alert_services = [...props.targetedDigest.alert_services];
    formData.value.frequency_days = [...props.targetedDigest.frequency_days];
    formData.value.frequency_hour = props.targetedDigest.frequency_hour;
    formData.value.frequency_minutes = props.targetedDigest.frequency_minutes;
    formData.value.active = props.targetedDigest.active;
  }
});

const addCorpus = (name) => {
  formData.value.alert_services.push(name);
};
const removeCorpus = (name) => {
  formData.value.alert_services = formData.value.alert_services.filter(
    (service) => service !== name
  );
};

const toggleDay = (name) => {
  if (formData.value.frequency_days.includes(name)) {
    formData.value.frequency_days = formData.value.frequency_days.filter(
      (service) => service !== name
    );
  } else {
    formData.value.frequency_days.push(name);
  }
};

const submit = () => {
  if (isFormValid.value) {
    if (props.targetedDigest) {
      store.updateDigest(props.targetedDigest._id, formData.value);
    } else {
      store.createDigest(formData.value);
    }
  }
};

const addOrEditForm = ref(null);

defineExpose({
  form: addOrEditForm,
  validate,
});
</script>

<style lang="scss" scoped>
.add-or-edit-digest {
  .field {
    display: flex;
    flex-direction: column;
    gap: 5px;

    margin-top: 1rem;

    .tip {
      color: var(--clr-n8);
      font-style: italic;
      font-size: 0.938em;
    }

    input[type="text"],
    textarea {
      border: 1px solid var(--clr-p2);
      padding: 4px 10px;
      border-radius: 5px;
      background-color: var(--clr-n2);
    }

    textarea {
      height: 90px;
      resize: vertical;
    }

    .services-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 5px;
      max-height: 125px;
    }

    .days-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      max-height: 125px;
    }
  }

  .error {
    margin-left: 10px;
    color: var(--clr-e300);
    font-style: italic;
    font-size: 0.875em;
  }
}

.day-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: var(--clr-p2);

  &.active {
    background-color: var(--clr-p5);
    color: white;
  }
}
</style>
