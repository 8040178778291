<template>
  <div class="card">
    <CollapseSection :initial-active="false">
      <template #title>
        <div class="title">
          <div class="name">
            {{ groupData.group.name }}
            <GroupTag :group-color="groupData.group.color">{{
              groupData.group.acronym
            }}</GroupTag>
          </div>

          <div class="position-stats">
            <p class="color-for">Pour : {{ groupData.voted_for }}</p>
            <p class="color-against">Contre : {{ groupData.voted_against }}</p>
            <p class="color-abstention">
              Abstention : {{ groupData.voted_abstention }}
            </p>
          </div>
        </div>
      </template>
      <template #content>
        <ul v-if="membersList.length > 0" class="members-list">
          <li v-for="m in membersList" :key="m.name">
            <PersonVote :name="m.name" :position="m.position" />
          </li>
        </ul>
        <p v-else>Aucun membre de ce groupe n'a voté.</p>
      </template>
    </CollapseSection>
  </div>
</template>

<script setup>
import { computed } from "vue";

import CollapseSection from "@/components/agenda/atoms/CollapseSection.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import PersonVote from "../atoms/PersonVote.vue";

const props = defineProps({
  groupData: { type: Object, required: true },
});

function alphabeticalSort(elem1, elem2) {
  if (elem1 < elem2) {
    return -1;
  }
  if (elem1 > elem2) {
    return 1;
  }
  return 0;
}

const membersList = computed(() =>
  [
    ...props.groupData.members_for.map((m) => ({
      name: m.name,
      position: "for",
    })),
    ...props.groupData.members_against.map((m) => ({
      name: m.name,
      position: "against",
    })),
    ...props.groupData.members_abstentions.map((m) => ({
      name: m.name,
      position: "abstention",
    })),
  ].sort((m1, m2) => alphabeticalSort(m1.name, m2.name))
);
</script>

<style scoped lang="scss">
.card {
  container-type: inline-size;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;

  .name {
    display: flex;
    gap: 10px;
  }
}

.position-stats {
  display: flex;
  gap: 30px;
}

.color-for {
  color: var(--color-for);
}

.color-against {
  color: var(--color-against);
}

.color-abstention {
  color: var(--color-abstention);
}

.members-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 1fr;

  @container (width > 300px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (width > 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @container (width > 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
