<template>
  <ScrollTop v-if="store.tweets.length > 0" />
  <LoaderFullPage v-if="store.loadingTweets" />
  <template v-else-if="store.tweets.length > 0">
    <ul class="tweets-list">
      <li v-for="elem in store.tweets" :key="elem._id">
        <TweetsCard :tweet="elem" />
      </li>
    </ul>

    <PaginationElement
      v-if="store.tweetsCount"
      :key="`${store.tweetsCount}${store.tweetsFilters.from}${store.tweetsFilters.size}`"
      :total-items="store.tweetsCount"
      :current-page="store.tweetsFilters.from"
      :items-limit="store.tweetsFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <div v-else class="empty">
    <img
      src="@/assets/img/institutional-groups/empty-states/commissions-tweets-nodatas.svg"
      alt="aria-hidden=true"
    />
    <p>
      Aucun membre de cet organe n’a publié de tweet correspondant à la veille
      sélectionnée.
    </p>
  </div>
</template>

<script setup>
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import TweetsCard from "@/components/twitter/molecules/TweetsCard.vue";
import { useInstituGroupsStore } from "@/stores/institutional-groups";
import { useRoute } from "vue-router";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";
const store = useInstituGroupsStore();

const route = useRoute();

const changeCurrentPage = (from) => {
  store.tweetsFilters.from = from;
  store.fetchTweets(route.params.missionId || route.params._id);
};

store.fetchTweets(route.params.missionId || route.params._id);
</script>

<style scoped lang="scss">
.empty > img {
  max-width: 314px;
}
.tweets-list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @container (width > 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (width > 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  :deep(.card) {
    height: 100%;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  font-size: 1.125em;
  flex-direction: column;
  p {
    max-width: 31.3125em;
    text-align: center;
    font-weight: 700;
    font-size: 1.125em;
    color: var(--clr-n8);
  }
  small {
    font-size: 1em;
  }
}
</style>
