<template>
  <AbstractSelectMultiple
    :filter="filter"
    :items="items"
    :item-label="itemLabel"
    :item-value="itemValue"
    :active-filters="activeFilters"
    :disabled="!isAgendaActive"
    mode="purple"
    :has-search="items.length > 5"
    @reset-filter="handleResetFilter"
    @add-filter-item="handleAddFilterItem"
    @remove-filter-item="handleRemoveFilterItem"
  >
    <template #label>
      <slot> </slot>
    </template>

    <template #badge>
      <span
        class="badge-tag"
        :style="{ backgroundColor: getBadgeColor(filter) }"
      >
        <template v-if="isAgendaActive">
          {{ activeFilters.length ? activeFilters.length : "Tout" }}
        </template>
        <template v-else> / </template>
      </span>
      <SwitchToggle
        :checked="isAgendaActive"
        :color="getBadgeColor(filter)"
        @change="toggleActiveValue"
      />
    </template>
  </AbstractSelectMultiple>
</template>

<script setup>
import AbstractSelectMultiple from "./AbstractSelectMultiple.vue";
import SwitchToggle from "@/components/reusable/atoms/SwitchToggle.vue";

const props = defineProps({
  filter: {
    type: String,
    required: true,
  },
  isAgendaActive: {
    type: Boolean,
    default: true,
  },
  items: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
  itemLabel: {
    type: String,
    default: null,
  },
  itemValue: {
    type: String,
    default: null,
  },
  activeFilters: {
    type: Array,
    default() {
      return [];
    },
  },
  mode: {
    type: String,
    validator(value) {
      // The value must match one of these strings
      return ["purple", "blue", "topbar"].includes(value);
    },
    default: "purple",
  },
  hasSearch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "resetFilter",
  "addFilterItem",
  "removeFilterItem",
  "activateAgenda",
  "deactivateAgenda",
]);

// Method to assign color based on filter name
const getBadgeColor = (filter) => {
  const colorMapping = {
    agenda_guests: "#FF941E",
    agenda_government: "#67b7ff",
    agenda_sn: "#00D1A2",
    agenda_an: "#FF1047",
    agenda_others: "#E3008C",
    ministres: "#E3008C",
  };

  const defaultColor = "var(--clr-p2)";

  return colorMapping[filter.toLowerCase()] || defaultColor;
};

const handleResetFilter = (filter) => {
  emit("resetFilter", filter);
};

const handleAddFilterItem = (item) => {
  emit("addFilterItem", item);
};

const handleRemoveFilterItem = (item) => {
  emit("removeFilterItem", item);
};

function toggleActiveValue() {
  if (props.isAgendaActive) {
    emit("deactivateAgenda");
  } else {
    emit("activateAgenda");
  }
}
</script>

<style scoped>
.badge-tag {
  display: flex;
  min-width: 20px;
  height: 20px;
  padding: 0.1em 0.3em;

  border-radius: 12px;

  justify-content: center;
  align-items: center;
  line-height: initial;
  color: white;
  font-size: 14px;
}
</style>
