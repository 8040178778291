<template>
  <ScrollTop />
  <div class="content-wrapper">
    <div class="top-tweets">
      <OverviewTopTweets
        :is-loading="store.isTotalFieldsLoading"
        :tweets-count="store.totalValues.tweets_count"
        :reweets-count="store.totalValues.retweets_count"
        :impressions-count="store.totalValues.impression_count"
        @click-icon="() => router.push({ hash: '#tweets-content' })"
      />
    </div>
    <div class="top-authors">
      <OverviewTopAuthors
        :is-loading="store.isTotalFieldsLoading"
        :authors-count="store.totalValues.authors_count"
        :authors-percent="authorsPercentOfPanel"
        @click-icon="() => router.push({ hash: '#tweets-content' })"
      />
    </div>
    <div class="top-articles">
      <OverviewTopArticles
        :is-loading="store.isTotalFieldsLoading"
        :articles-count="store.totalValues.articles_count"
        @click-icon="() => router.push({ hash: '#articles-content' })"
      />
    </div>

    <div v-if="isTweetsOrArticlesAvailable" class="timeline-tweets">
      <OverviewTimeCurve :store="store" />
    </div>
    <!-- Stakeholder -->
    <div v-if="isTweetsOrArticlesAvailable" class="stakeholder-graph">
      <div class="words-ranking">
        <OverviewStakeholdersGraphs
          :is-loading="store.isStakeholderStatsLoading"
          :items="store.stakeHoldersValues"
        />
      </div>
    </div>
    <!-- Levels Ranking -->
    <div v-if="isTweetsOrArticlesAvailable" class="levels-ranking">
      <OverviewLevelsChart
        :is-loading="store.isLevelsRankingLoading"
        :items="store.levelsValues"
      />
    </div>

    <div v-if="isTweetsOrArticlesAvailable" class="hemicycle-groups">
      <OverviewGroupsRanking
        :is-loading="store.isGroupsLoading"
        :groups="store.groupsRanking"
      />
    </div>

    <div
      v-if="isTweetsOrArticlesAvailable"
      id="tweets-content"
      class="tweets-list card"
    >
      <div class="header-title">Derniers tweets</div>
      <ul v-if="store.tweetsList?.length > 0">
        <li v-for="tweet in store.tweetsList" :key="tweet._id">
          <TweetsCard :tweet="tweet" :is-overview="true" />
        </li>
      </ul>
      <NoData v-else />
    </div>
    <div
      v-if="isTweetsOrArticlesAvailable"
      id="articles-content"
      class="card articles-list"
    >
      <div class="header-title">Derniers articles</div>
      <ul v-if="store.articlesList?.length > 0">
        <li v-for="article in store.articlesList" :key="article._id">
          <TwitterArticleCard :article="article" :is-overview="true" />
        </li>
      </ul>
      <NoData v-else />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import OverviewGroupsRanking from "@/components/twitter/molecules/OverviewGroupsRanking.vue";
import OverviewLevelsChart from "@/components/twitter/molecules/OverviewLevelsChart.vue";
import OverviewStakeholdersGraphs from "@/components/twitter/molecules/OverviewStakeholdersGraphs.vue";
import OverviewTopArticles from "@/components/twitter/molecules/OverviewTopArticles.vue";
import OverviewTopAuthors from "@/components/twitter/molecules/OverviewTopAuthors.vue";
import OverviewTopTweets from "@/components/twitter/molecules/OverviewTopTweets.vue";
import TweetsCard from "@/components/twitter/molecules/TweetsCard.vue";
import OverviewTimeCurve from "@/components/twitter/organisms/OverviewTimeCurve.vue";
import { useLegiSocialMediaStore } from "@/stores/legi-social-media";
import { useLegiWorkStore } from "@/stores/legi-work";
import TwitterArticleCard from "@/components/twitter/molecules/TwitterArticleCard.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const legiWorkStore = useLegiWorkStore();
const store = useLegiSocialMediaStore();
store.doc_ref = legiWorkStore.legiWorkZoom.ref;

const router = useRouter();

const authorsPercentOfPanel = computed(() =>
  Math.round(
    (100 * store.totalValues.authors_count) / store.totalValues.authors_total
  )
);
const isTweetsOrArticlesAvailable = computed(() => {
  return (
    store.totalValues.tweets_count > 0 || store.totalValues.articles_count > 0
  );
});

store.fetchOverview();
</script>

<style scoped lang="scss">
.content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

@container (width < 875px) {
  .content-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.timeline-tweets,
.hemicycle-groups,
.wordcloud {
  grid-column: span 6;
}

.top-tweets,
.stakeholder-graph,
.levels-ranking,
.domains-ranking,
.hashtag-ranking,
.words-ranking,
.mentions-ranking,
.tweets-list,
.articles-list {
  grid-column: span 3;
}

.top-authors {
  grid-column: span 2;
}

.top-articles {
  grid-column: span 1;
}

.header-title {
  font-size: 1.0625rem;
  font-weight: 600;
  color: black;
}

.tweets-list,
.articles-list {
  height: fit-content;
}

.tweets-list ul,
.articles-list ul {
  list-style: none;
  padding: 0;
  padding-top: 5px;
  margin: 0;

  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
