import { defineStore, storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";

import { httpRequest } from "@/utils/request.utils";
import { useUserStore, FOLLAWED_FEATURES } from "@/stores/user";
import router from "@/router";
import { handleNetworkError } from "@/utils/handleNetworkError";

const initialLegiWorkFilters = {
  size: 20,
  from: 0,
  get_filters: true,
};

// Defining store
export const useLegiWorkStore = defineStore("legi-work", () => {
  const userStore = useUserStore();
  const {
    loading: topbarLoading,
    sharedFiltersAsPayload: sharedFiltersAsPayload,
    selectedInterval: topbarInterval,
  } = storeToRefs(userStore);

  const loading = ref(false);

  // Listing
  const items = ref([]);
  const item_count = ref(null);
  const bypassQuery = ref(false);

  // Zoom
  const legiWorkZoom = ref(null);
  const zoomedText = ref(null);
  const zoomedTextAmendments = ref([]);
  const loadingText = ref(false);
  const zoomAdditionalInfo = ref([]);
  const loadingAdditionalInfo = ref(false);
  const zoomGlobalInfo = ref({});
  const loadingGlobalInfo = ref(false);
  const zoomScrutin = ref(null);
  const loadingScrutin = ref(false);
  const zoomReunions = ref([]);
  const loadingReunions = ref(false);
  const zoomAmendmentsStats = ref({});
  const loadingAmendmentsStats = ref(false);
  const zoomDecrets = ref([]);
  const loadingDecrets = ref(false);
  const prevZoomUrlQuery = ref(null);

  // filters
  const filters = ref(null);
  const selectedFilters = ref({ ...initialLegiWorkFilters });
  const isFollawedActive = ref(false);
  const isAuthorFilterLoading = ref(false);
  const authors = ref([]);

  async function fetchLegiWorkList() {
    loading.value = true;

    //* Make the payload using the filters from the feature
    const payload = {
      ...selectedFilters.value,
      ...sharedFiltersAsPayload.value,
    };

    if (bypassQuery.value) {
      delete payload.dashboard_id;
    }

    //* If there is author filters selected map them to only their ids
    if (payload.author?.length > 0) {
      payload.author = payload.author.map((author) => author._id);
    }

    //* Remove empty filters from the payload
    Object.keys(payload).forEach((element) => {
      if (Array.isArray(payload[element]) && payload[element].length === 0) {
        delete payload[element];
      }
    });

    if (isFollawedActive.value) {
      // Add the list of follawed items
      payload._id = userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS];
    }

    try {
      const response = await httpRequest("post", "/legi-docs", payload);

      if (response.data) {
        // Support multiple names for some of the properties
        if (response.data.themes && !response.data.thematics) {
          response.data.thematics = response.data.themes;
          delete response.data.themes;
        }
        if (response.data.textes && !response.data.texts) {
          response.data.texts = response.data.textes;
          delete response.data.textes;
        }

        items.value = response.data.items;
        item_count.value = response.data.item_count;
        filters.value = response.data.filters;
      }
    } catch (err) {
      items.value = [];
      item_count.value = null;
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  }

  async function fetchOneLegiWork(legiWorkRef) {
    loading.value = true;

    // This ref can be used to check if the query has changed in the zoom views
    prevZoomUrlQuery.value = JSON.stringify(router.currentRoute.value.query);

    const payload = {
      ref: [legiWorkRef],
      highlight: true,
      ...sharedFiltersAsPayload.value,
    };

    try {
      const response = await httpRequest("post", `/legi-docs/zoom`, payload);

      legiWorkZoom.value = response.data;
    } catch (err) {
      //* set error if error
      handleNetworkError(err);
    } finally {
      loading.value = false;
    }
  }

  async function loadZoomedText(textRef, isCommission) {
    loadingText.value = true;
    zoomedText.value = null;

    const payload = {
      ref: [textRef],
      highlight: true,
      ...sharedFiltersAsPayload.value,
    };

    const payloadAmendments = {
      text_ref: [textRef],
      type: isCommission ? "commissions" : "session",
      ...sharedFiltersAsPayload.value,
    };

    // removes the time filters from sharedFiltersAsPayload
    delete payloadAmendments.start;
    delete payloadAmendments.end;

    try {
      const response = await httpRequest("post", "/legi-texts", payload);
      zoomedText.value = response.data.items[0];

      const responseAmendments = await httpRequest(
        "post",
        "/amendments-legi-text",
        payloadAmendments
      );

      zoomedTextAmendments.value = responseAmendments.data.items;
    } catch (err) {
      zoomedText.value = null;
      zoomedTextAmendments.value = [];
      handleNetworkError(err);
    } finally {
      loadingText.value = false;
    }
  }

  async function loadAdditionalInfo() {
    loadingAdditionalInfo.value = true;

    const payload = {
      ref: [legiWorkZoom.value.ref],
    };

    try {
      const response = await httpRequest(
        "post",
        "/legi-docs/additional-information",
        payload
      );

      zoomAdditionalInfo.value = response.data.items;
    } catch (err) {
      zoomAdditionalInfo.value = [];
      handleNetworkError(err);
    } finally {
      loadingAdditionalInfo.value = false;
    }
  }

  async function loadGlobalInfo() {
    loadingGlobalInfo.value = true;

    const payload = {
      doc_ref: legiWorkZoom.value.ref,
    };

    try {
      const response = await httpRequest("post", "/legi-stats", payload);

      zoomGlobalInfo.value = response.data;
    } catch (err) {
      zoomGlobalInfo.value = [];
      handleNetworkError(err);
    } finally {
      loadingGlobalInfo.value = false;
    }
  }

  async function loadReunions() {
    loadReunions.value = true;

    const payload = {
      doc_ref: [legiWorkZoom.value.ref],
      size: 100,
    };

    try {
      const response = await httpRequest("post", "/legi-reunions", payload);

      zoomReunions.value = response.data.items;
    } catch (err) {
      zoomReunions.value = [];
      handleNetworkError(err);
    } finally {
      loadReunions.value = false;
    }
  }

  async function loadDecrets(norRef) {
    loadingDecrets.value = true;

    const payload = {
      nor: [norRef],
    };

    try {
      const response = await httpRequest("post", "/jorfs", payload);
      zoomDecrets.value = response.data.items;
    } catch (err) {
      zoomDecrets.value = [];
      handleNetworkError(err);
    } finally {
      loadingDecrets.value = false;
    }
  }

  async function loadScrutin(scrutinRef) {
    loadingScrutin.value = true;

    const payload = {
      ref: [scrutinRef],
    };

    try {
      const response = await httpRequest("post", "/legi-scrutins", payload);

      zoomScrutin.value = response.data.items[0];
    } catch (err) {
      zoomScrutin.value = [];
      handleNetworkError(err);
    } finally {
      loadingScrutin.value = false;
    }
  }

  async function loadAmendmentsStats(textRef, amType) {
    loadingAmendmentsStats.value = true;

    const payload = {
      text_ref: [textRef],
      type: amType,
    };

    try {
      const response = await httpRequest(
        "post",
        "/amendments-legi-stats",
        payload
      );

      zoomAmendmentsStats.value = response.data;
    } catch (err) {
      zoomAmendmentsStats.value = {};
      handleNetworkError(err);
    } finally {
      loadingAmendmentsStats.value = false;
    }
  }

  function getPersonalityData(paId) {
    if (!legiWorkZoom.value) {
      throw new Error("Call to getPersonalityData with no zoom data");
    }

    const pData = legiWorkZoom.value.all_rapporteurs[paId];

    if (!pData) {
      // Malformed data, log to the console and return empty value
      // eslint-disable-next-line no-console
      console.error(
        `getPersonalityData: Unable to find personality data for ${paId}`
      );

      return {};
    }

    return pData;
  }

  const getAuthorFilters = async (name) => {
    isAuthorFilterLoading.value = true;

    try {
      const response = await httpRequest("post", "/legi-doc/search_authors", {
        name: name,
      });
      authors.value = response.data.personalities;
    } catch (err) {
      handleNetworkError(err);
    }
    isAuthorFilterLoading.value = false;
  };

  function resetFilters() {
    selectedFilters.value = { ...initialLegiWorkFilters };
  }

  const hasActiveFilters = computed(() => {
    return Object.values(selectedFilters.value).some((filter) => {
      return Array.isArray(filter) && filter.length > 0;
    });
  });

  function resetStore() {
    items.value = [];
    selectedFilters.value = { ...initialLegiWorkFilters };
    filters.value = null;
    loading.value = false;

    item_count.value = null;
  }

  function toggleFollawed() {
    isFollawedActive.value = !isFollawedActive.value;
    selectedFilters.value.from = 0;
    fetchLegiWorkList();
  }

  //* Trigger the fetch when the topbar timerange is changed
  watch(
    [topbarLoading, topbarInterval],
    ([newTopbarLoading, newTopbarInterval]) => {
      if (!newTopbarLoading && newTopbarInterval) {
        if (router.currentRoute.value.name === "Dossiers législatifs") {
          fetchLegiWorkList();
        }

        if (
          router.currentRoute.value.path.includes("/legislatives/") &&
          legiWorkZoom.value
        ) {
          fetchOneLegiWork(legiWorkZoom.value.ref);
        }
      }
    }
  );

  return {
    authors,
    bypassQuery,

    prevZoomUrlQuery,
    fetchLegiWorkList,
    fetchOneLegiWork,
    filters,
    getAuthorFilters,
    getPersonalityData,
    hasActiveFilters,
    isAuthorFilterLoading,
    isFollawedActive,
    item_count,
    items,
    legiWorkZoom,
    loadAdditionalInfo,
    loadAmendmentsStats,
    loadDecrets,
    loadGlobalInfo,
    loading,
    loadingAdditionalInfo,
    loadingAmendmentsStats,
    loadingDecrets,
    loadingGlobalInfo,
    loadingReunions,
    loadingScrutin,
    loadingText,
    loadReunions,
    loadScrutin,
    loadZoomedText,
    resetFilters,
    resetStore,
    selectedFilters,
    toggleFollawed,
    zoomAdditionalInfo,
    zoomAmendmentsStats,
    zoomDecrets,
    zoomedText,
    zoomedTextAmendments,
    zoomGlobalInfo,
    zoomReunions,
    zoomScrutin,
  };
});
