<template>
  <div
    class="card"
    :class="[isSelected ? 'selected' : '', getAgendaTypeClassName(event)]"
    @mouseover="showCheckbox = true"
    @mouseleave="showCheckbox = false"
  >
    <div class="card-head header">
      {{ dayjs(event.start).format("dddd DD/MM/YYYY HH:mm") }}-{{
        dayjs(event.start).format("YYYYMMDD") ===
        dayjs(event.end).format("YYYYMMDD")
          ? dayjs(event.end).format("HH:mm")
          : dayjs(event.end).format("dddd DD/MM/YYYY HH:mm")
      }}
      <div v-if="!noSelect" class="checkbox-and-close">
        <CheckBox
          :class="{
            'checkbox-hidden': !(showCheckbox || isSelected || $props.isDialog),
          }"
          :checked="isSelected"
          @change.stop="
            () =>
              isSelected
                ? $emit('unselectEvent', event)
                : $emit('selectEvent', event)
          "
        />
        <!-- Close button if the Dialog box is on -->
        <CloseButton v-if="isDialog" @click="$emit('closeDialog')" />
      </div>
    </div>
    <div class="card-body">
      <div class="card-icon">
        <i :class="eventTypeToIconName(event.type_)"></i>
      </div>
      <div class="card-content">
        <h5 class="title">{{ event?.title }}</h5>
        <p>{{ event.description }}</p>

        <p class="participants-list">
          <i class="fa-solid fa-user-group"></i>
          {{ participantsStringFormatter(event.participants) }}
        </p>
        <p v-if="event.location" class="location">
          <i class="fa-solid fa-location-dot"></i>
          {{ event.location }}
        </p>
        <p class="type">
          <i class="fa-solid fa-circle"></i>
          {{ event.agenda.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import { ref } from "vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import CloseButton from "@/components/reusable/atoms/CloseButton.vue";

defineProps({
  event: {
    type: Object,
    required: true,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
    required: true,
  },
  noSelect: {
    type: Boolean,
    default: false,
  },
});

const showCheckbox = ref(false);

defineEmits(["selectEvent", "unselectEvent", "closeDialog"]);

const getAgendaTypeClassName = (event) => {
  return event.agenda.type_?.replace(/\s+/g, "-");
};

const eventTypeToIconName = (type) => {
  const map = {
    accueil: "fa-kit fa-sv-accueil",
    audioconférence: "fa-solid fa-phone",
    audition: "fa-kit fa-sv-audition",
    cérémonie: "fa-kit fa-sv-ceremonie",
    colloque: "fa-kit fa-sv-colloque",
    comité: "fa-kit fa-sv-comite",
    "conférence de presse": "fa-kit fa-sv-conferencepresse",
    "conseil (restreint) de défense et de sécurité nationale":
      "fa-solid fa-shield-halved",
    "conseil des ministres": "fa-kit fa-sv-conseilministres",
    déplacement: "fa-kit fa-sv-deplacement",
    interview: "fa-kit fa-sv-interview",
    "question au gouvernement": "fa-kit fa-sv-questiongou",
    "question d'actualité": "fa-solid fa-comments-question",
    rencontre: "fa-solid fa-handshake-simple",
    repas: "fa-solid fa-fork-knife",
    réunion: "fa-kit fa-sv-reunion",
    sommet: "fa-kit fa-sv-sommet",
    visioconférence: "fa-kit fa-sv-visioconference",
    autre: "fa-solid fa-calendar-day",
  };

  return map[type] || map.autre;
};

const participantsStringFormatter = (participants) => {
  return participants
    .map((p) => p.name)
    .join(", ")
    .toString();
};
</script>

<style lang="scss" scoped>
.card {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;

  &.selected {
    border-color: var(--clr-p7);
  }

  .card-head {
    text-transform: capitalize;
    margin-bottom: 8px;

    .checkbox-hidden {
      opacity: 0;
    }
    .date {
      padding-bottom: 1px;
      font-size: 0.938em;
      font-weight: 300;
      text-transform: capitalize;
    }

    input[type="checkbox"] {
      display: none;
    }
  }

  .card-body {
    display: flex;

    .card-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      border-radius: 50%;
      border: 2px solid white;
      box-shadow: var(--shd-card);
      max-width: 36px;
      min-width: 36px;
      height: 36px;
      color: white;
      background-color: var(--clr-agenda-event-type);
    }

    .card-content {
      .participants-list,
      .location,
      .type {
        display: flex;
        gap: 8px;
      }

      .participants-list {
        padding: 0;
        text-transform: capitalize;
      }

      i {
        display: flex;
        justify-content: center;
        width: 15px;
      }

      .type i {
        color: var(--clr-agenda-event-type);
      }
    }
  }
}

.checkbox-and-close {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
</style>
