<template>
  <ScrollTop v-if="showScrollTop" />
  <div class="params-row request-and-check-all">
    <SwitchSlide
      :is-active="!store.bypassQueryActivities"
      @toggle="toggleUseRequest"
      >Utiliser la requête</SwitchSlide
    >
    <CheckBox
      :checked="isAllSelected"
      @change="
        () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
      "
    >
      Toutes les activités
    </CheckBox>
  </div>
  <div class="params-row">
    <div class="page-settings filters">
      <FilterCheckBox
        label="Tweets"
        color="var(--color-tweets)"
        :item-count="store.indexCount?.tweet"
        :is-checked="
          store.prmActivitiesFilters.includes(ACTIVITIES_FILTERS.TWEETS)
        "
        @check="addFilter(ACTIVITIES_FILTERS.TWEETS)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.TWEETS)"
      />
      <FilterCheckBox
        label="Dossiers Législatifs"
        color="var(--color-legidocs)"
        :item-count="store.indexCount?.['legi-doc']"
        :is-checked="
          store.prmActivitiesFilters.includes(ACTIVITIES_FILTERS.LEGIDOCS)
        "
        @check="addFilter(ACTIVITIES_FILTERS.LEGIDOCS)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.LEGIDOCS)"
      />
      <FilterCheckBox
        label="Amendements"
        color="var(--color-amendments)"
        :item-count="store.indexCount?.amendment"
        :is-checked="
          store.prmActivitiesFilters.includes(ACTIVITIES_FILTERS.AMENDMENTS)
        "
        @check="addFilter(ACTIVITIES_FILTERS.AMENDMENTS)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.AMENDMENTS)"
      />
      <FilterCheckBox
        label="Questions"
        color="var(--color-questions)"
        :item-count="store.indexCount?.question"
        :is-checked="
          store.prmActivitiesFilters.includes(ACTIVITIES_FILTERS.QUESTIONS)
        "
        @check="addFilter(ACTIVITIES_FILTERS.QUESTIONS)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.QUESTIONS)"
      />
      <FilterCheckBox
        label="Compte-rendus"
        color="var(--color-reports)"
        :item-count="store.indexCount?.['compte-rendu']"
        :is-checked="store.prmActivitiesFilters.includes(ACTIVITIES_FILTERS.CR)"
        @check="addFilter(ACTIVITIES_FILTERS.CR)"
        @uncheck="removeFilter(ACTIVITIES_FILTERS.CR)"
      />
    </div>

    <div class="page-settings">
      <SelectItemsLimit
        :items-limit="selectedPrmActivitiesParams.size"
        @change-limit="changeLimit"
      >
        Activités
      </SelectItemsLimit>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import { usePrmStore } from "@/stores/prm";
import { ACTIVITIES_FILTERS } from "@/stores/prm";
import ScrollTop from "@/components/reusable/atoms/ScrollTop.vue";

const route = useRoute();
const store = usePrmStore();

const props = defineProps({
  isZoomed: {
    type: Boolean,
    default: false,
  },
});

const { selectedPrmActivitiesParams } = storeToRefs(store);

const addFilter = (filter) => {
  store.prmActivitiesFilters.push(filter);
  fetchActivities();
};

const removeFilter = (filter) => {
  store.prmActivitiesFilters = store.prmActivitiesFilters.filter(
    (f) => f !== filter
  );
  fetchActivities();
};

const selectAllFilters = () => {
  store.prmActivitiesFilters = [
    ACTIVITIES_FILTERS.TWEETS,
    ACTIVITIES_FILTERS.LEGIDOCS,
    ACTIVITIES_FILTERS.AMENDMENTS,
    ACTIVITIES_FILTERS.QUESTIONS,
  ];
  fetchActivities();
};

const unselectAllFilters = () => {
  store.prmActivitiesFilters = [];
  fetchActivities();
};

const isAllSelected = computed(
  () =>
    store.prmActivitiesFilters.length ===
    Object.keys(ACTIVITIES_FILTERS)?.length
);

const toggleUseRequest = () => {
  store.bypassQueryActivities = !store.bypassQueryActivities;
  fetchActivities();
};

// Limit
const changeLimit = (size) => {
  selectedPrmActivitiesParams.value.size = size;
  selectedPrmActivitiesParams.value.from = 0;
  fetchActivities();
};

const showScrollTop = computed(
  () => selectedPrmActivitiesParams.value.size > 0
);

const fetchActivities = async () => {
  selectedPrmActivitiesParams.value.from = 0;
  props.isZoomed
    ? await store.fetchOnePersonnalityActivities(route.params._id)
    : await store.fetchFollawedPrmActivities();
};

fetchActivities();
</script>

<style lang="scss" scoped>
.request-and-check-all {
  justify-content: flex-start;
  gap: 15px;
}
.filters {
  gap: 10px;
}
</style>
